import React, { useEffect, useState } from 'react';
import { Mode } from 'common/interfaces/business';
import { IconButton, MenuItem, TextField, Typography } from '@mui/material';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { Add, Delete } from '@mui/icons-material';
import ImportalDatePicker from '@/shared-components/ImportalDatePicker/ImportalDatePicker';
import { formatHumanReadableDate } from 'common/utilities/shipmentUtility';

export enum FieldType {
  TEXT = 'TEXT',
  CHECKBOX = 'CHECKBOX',
}

export enum ArrayOfStringViewMode {
  Individual = 'Individual',
  AllTogether = 'AllTogether',
}
export interface ImportalArrayOfStringProps {
  editLabel: string;
  mode: Mode;
  values: Array<string>;
  onValueChanged: (arg0: Array<string>) => void;
  viewSettings: {
    viewMode: ArrayOfStringViewMode;
    viewIndividuallyComponent?: (value: string) => React.JSX.Element;
    viewAllComponent?: (values: Array<string>) => React.JSX.Element;
  };
}
export function ImportalArrayOfString({
  editLabel,
  mode,
  values,
  onValueChanged,
  viewSettings,
}: ImportalArrayOfStringProps) {
  useEffect(() => {
    if (Array.isArray(values) && values.length === 0) {
      onValueChanged(['']);
    }
  }, [values]);

  const handleAddValue = () => {
    onValueChanged([...values, '']);
  };

  const handleDeleteValue = (index) => {
    const newArray = [...values.slice(0, index), ...values.slice(index + 1)];
    onValueChanged(newArray);
  };

  const handleTextChanged = (index, newValue) => {
    const newArray = [...values];
    newArray[index] = newValue;
    onValueChanged(newArray);
  };

  return (
    <>
      {mode === Mode.VIEW && (
        <>
          {viewSettings.viewMode === ArrayOfStringViewMode.Individual &&
            values.map((value, index) => {
              return viewSettings.viewIndividuallyComponent!(value);
            })}
          {viewSettings.viewMode === ArrayOfStringViewMode.AllTogether && viewSettings.viewAllComponent!(values)}
        </>
      )}
      {mode === Mode.EDIT &&
        values?.map((value, index) => (
          <div key={index} className="entry-business-info-row-container">
            <div style={{ display: 'flex', alignContent: 'center' }} className="entry-row-header">
              {editLabel}
              <IconButton
                sx={{ marginLeft: '4px', width: '22px', height: '22px' }}
                onClick={() => handleDeleteValue(index)}
              >
                <Delete sx={{ fontSize: '20px' }} />
              </IconButton>
              {index === values.length - 1 && (
                <IconButton sx={{ marginLeft: '4px', width: '22px', height: '22px' }} onClick={handleAddValue}>
                  <Add sx={{ fontSize: '18px' }} />
                </IconButton>
              )}
            </div>
            <div className="entry-row-text">
              <TextField
                fullWidth
                size="small"
                variant="standard"
                value={value}
                onChange={(e) => handleTextChanged(index, e.target.value)}
              />
            </div>
          </div>
        ))}
    </>
  );
}

/**
 * @param mode
 * @param value
 * @param options -- should be a Record<Display Value, Stored Value>
 * @param onNewValue
 */
export const renderImportalSelect = (
  mode: Mode,
  value: string,
  options: Record<string, any>,
  onNewValue: (arg0: any) => void
) => {
  if (!options) {
    return null;
  }

  if (mode === Mode.EDIT) {
    return (
      <TextField
        select
        fullWidth
        variant="standard"
        value={value}
        onChange={(e) => {
          onNewValue(e.target.value);
        }}
      >
        {Object.keys(options).map((key, index) => {
          return (
            <MenuItem key={index} value={key}>
              {options[key]}
            </MenuItem>
          );
        })}
      </TextField>
    );
  } else if (mode === Mode.VIEW) {
    return <div className="entry-row-text-value">{options[value]}</div>;
  }
};

export interface ImportalDateSelectProps {
  mode: Mode;
  label: string;
  value: Date | string;
  onValueChanged: (arg0: Date) => void;
}
export function ImportalDateSelect({ mode, label, value, onValueChanged }: ImportalDateSelectProps) {
  const [localDate, setLocalDate] = useState<Date | null>(null);

  const onDateSelected = (newDate: Date | null) => {
    if (newDate) onValueChanged(newDate);
  };

  useEffect(() => {
    if (typeof value === 'string') {
      setLocalDate(new Date(value));
    }
  }, [value]);

  return (
    <div>
      {mode === Mode.VIEW && <>{value ? formatHumanReadableDate(value) : <></>}</>}
      {mode === Mode.EDIT && <ImportalDatePicker date={localDate} setDate={onDateSelected} label={label} />}
    </div>
  );
}

export const renderImportalField = (mode: Mode, type: FieldType, value: any, onNewValue: (arg0: any) => void) => {
  if (mode === Mode.EDIT) {
    switch (type) {
      case FieldType.TEXT:
        return (
          <TextField
            fullWidth
            variant="standard"
            onChange={(e) => {
              onNewValue(e.target.value);
            }}
            value={value || ''}
          />
        );
      case FieldType.CHECKBOX:
        return (
          <TextField
            select
            fullWidth
            variant="standard"
            value={value ? 'Yes' : 'No'}
            onChange={(e) => {
              onNewValue(e.target.value === 'Yes');
            }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
        );
    }
  } else if (mode === Mode.VIEW) {
    switch (type) {
      case FieldType.TEXT:
        return <div className="entry-row-text-value">{value}</div>;
      case FieldType.CHECKBOX:
        return (
          <Typography>
            {value === true ? <Check style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />}
          </Typography>
        );
    }
  }
};

const renderImportalFieldHover = (mode: Mode, type: FieldType, value: any, onNewValue: (arg0: any) => void) => {};

const renderBusinessFields = (cardMode: Mode, value: any, name: string, onValueChanged: (arg0: any) => void) => {
  if (cardMode === Mode.EDIT) {
    switch (name) {
      case 'address':
      case 'additionalState':
      case 'businessStructure':
      case 'ein':
      case 'partners':
      case 'paymentMethodCustoms':
      case 'pointOfContact':
      case 'bondInfo':
      case 'bonds':
      case 'isf':
      case 'customsClearance':
      case 'classification':
      case 'disbursement':
        return (
          <TextField
            fullWidth
            variant="standard"
            onChange={(e) => {
              onValueChanged(e.target.value);
            }}
            value={value || ''}
          />
        );
      case 'productLibrary':
      case 'standardOperatingProceduresComplete':
        return (
          <TextField
            select
            fullWidth
            variant="standard"
            value={value ? 'Yes' : 'No'}
            onChange={(e) => {
              onValueChanged(e.target.value === 'Yes');
            }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
        );
      default:
        return null;
    }
  } else {
    switch (name) {
      case 'productLibrary':
      case 'standardOperatingProceduresComplete':
        return (
          <Typography>
            {value === true ? <Check style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />}
          </Typography>
        );
      default:
        return <div className="entry-row-text-value">{value}</div>;
    }
  }
};

export default renderBusinessFields;
