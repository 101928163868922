import { useMemo } from 'react';
import * as d3 from 'd3';

interface Props {
  axisLabel: string;
  height: number;
  range: number[];
  /**
   * If present, these are the *only* tick values we will display
   * (i.e. no automatically generated ticks).
   */
  customTickValues?: number[];
}

export const NumericYAxis = ({ axisLabel, height = 1, range = [10, 290], customTickValues }: Props) => {
  const ticks = useMemo(() => {
    const yScale = d3.scaleLinear().domain(range).range([0, height]);
    if (customTickValues && customTickValues.length > 0) {
      // Use custom tick values only
      return customTickValues.map((tick) => ({
        value: tick,
        yOffset: yScale(tick),
      }));
    } else {
      // Otherwise, auto-generate
      const pixelsPerTick = 40;
      const numberOfTicksTarget = Math.max(1, Math.floor(height / pixelsPerTick));
      return yScale.ticks(numberOfTicksTarget).map((value) => ({
        value,
        yOffset: yScale(value),
      }));
    }
  }, [range.join('-'), height, customTickValues]);

  return (
    <>
      {ticks.map(({ value, yOffset }) => (
        <g key={value} transform={`translate(0, ${height - yOffset})`}>
          <text
            fill="#525256"
            style={{
              fontSize: '14px',
              textAnchor: 'end',
              transform: 'translateX(-8px) translateY(4px)',
            }}
          >
            {value}
          </text>
        </g>
      ))}
      <text
        fill="#525256"
        style={{ fontSize: '14px', fontWeight: '600' }}
        transform={`translate(-65, ${height / 2}) rotate(-90)`}
        textAnchor="middle"
      >
        {axisLabel}
      </text>
    </>
  );
};
