import LightTooltip from '@/components/tooltip-component/TooltipComponent';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';

function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export const renderDMSubtext = (currentSummary) => {
  let exclusionCount = 0;
  let textToReturn: string = '';

  if (!isObjEmpty(currentSummary)) {
    if (currentSummary.freeTradeCode) {
      exclusionCount++;
    }
    if (currentSummary.section301ExclusionType !== 'No Exemption') {
      exclusionCount++;
    }
  }
  textToReturn = renderDMTextBasedOnCount(exclusionCount);
  return textToReturn;
};

export const renderDMTextBasedOnCount = (count) => {
  let text: string = '';
  if (count > 1) {
    text = 'We found ' + count + ' exclusions associated with your product.';
  } else if (count === 1) {
    text = 'We found ' + count + ' exclusion associated with your product.';
  } else {
    text = "Our experts didn't find any exclusions associated with your product.";
  }
  return text;
};

export const renderHDFSTextBasedOnCount = (count) => {
  let text: string = '';
  if (count > 1) {
    text = 'We found ' + count + ' hidden fees associated with your product.';
  } else if (count === 1) {
    text = 'We found ' + count + ' hidden fee associated with your product.';
  } else {
    text = "Great news, our experts didn't find any hidden fees associated with your product.";
  }
  return text;
};

export const renderHDFSSubtext = (currentSummary) => {
  let hiddenDutyCount = 0;
  let textToReturn: string = '';

  if (!isObjEmpty(currentSummary)) {
    if (currentSummary.additionalDuties) {
      hiddenDutyCount++;
    }
    if (currentSummary.punitiveDuties) {
      hiddenDutyCount++;
    }
    if (currentSummary.section301Duties) {
      hiddenDutyCount++;
    }
    if (currentSummary.antiDumpingDuties) {
      hiddenDutyCount++;
    }
    if (currentSummary.countervailingDuties) {
      hiddenDutyCount++;
    }
  }
  textToReturn = renderHDFSTextBasedOnCount(hiddenDutyCount);
  return textToReturn;
};

export const renderAdditionalDutyUnits = (units) => {
  let unitsToReturn = '';
  if (units === '%') {
    unitsToReturn = units;
  } else {
    unitsToReturn = ' ' + units;
  }
  return unitsToReturn;
};

export const renderDutyRate = (rate, currentComplianceOneSummary) => {
  if (currentComplianceOneSummary.additionalDutyUnits === '%') {
    rate = rate * 100;
  }
  return rate;
};

export const renderDutyMitigationRows = (currentSummary, handleSection301ModalOpen) => {
  let dutyMitigationRowsToReturn: any = [];
  dutyMitigationRowsToReturn.push(
    <div className="breakdown-container-header" key="headre-duty-title-hdfs-four">
      <div className="exclusion-name">Exclusion</div>
      <div className="impact-on-duty">Impact on Duty</div>
    </div>
  );
  if (!isObjEmpty(currentSummary)) {
    if (currentSummary.freeTradeCode) {
      dutyMitigationRowsToReturn.push(
        <div className="breakdown-container-row" key="headre-duty-title-hdfs-five">
          <div className="exclusion-name">Free Trade Agreement</div>
          <div className="impact-on-duty">Free General Duty</div>
        </div>
      );
    }
    if (currentSummary.section301ExclusionType !== 'No Exemption') {
      dutyMitigationRowsToReturn.push(
        <div className="breakdown-container-row" key="headre-duty-title-hdfs-three">
          <div className="hdfs-fee-type-text">
            <span className="variable-text">Section 301 (China) Exclusion</span>
            {currentSummary.section301ExclusionType !== 'Exempted without Qualification' && (
              <LightTooltip title="Click here for a detailed breakdown of the Section 301 Exclusions">
                <InfoIcon className="pga-info-icon" onClick={handleSection301ModalOpen} sx={{ fontSize: '14px' }} />
              </LightTooltip>
            )}
          </div>
          <div className="hdfs-duty-rate-text">
            <span className="variable-text">
              {currentSummary.section301ExclusionType === 'Exempted without Qualification'
                ? 'Free Section 301 Duty'
                : 'Free Section 301 Duty for specific products'}
            </span>
          </div>
        </div>
      );
    }
  }
  if (dutyMitigationRowsToReturn.length > 1) {
    return dutyMitigationRowsToReturn;
  } else {
    return <></>;
  }
};

export const renderHDFSRows = (currentSummary, handleADDModalOpen, handleCVDModalOpen) => {
  let hdfsRowsToReturn: any = [];
  hdfsRowsToReturn.push(
    <div className="breakdown-container-header" key="headre-duty-title-hdfs-one">
      <div className="hdfs-fee-type-text">Fee Type</div>
      <div className="hdfs-duty-rate-text">Duty Rate</div>
    </div>
  );
  if (currentSummary.punitiveDuties) {
    hdfsRowsToReturn.push(
      <div className="breakdown-container-row" key="headre-duty-title-hdfs-two">
        <div className="hdfs-fee-type-text">Punitive Duty</div>
        <div className="hdfs-duty-rate-text">{currentSummary.punitiveDuties.punitiveDuty}</div>
      </div>
    );
  }
  if (currentSummary.additionalDuties) {
    hdfsRowsToReturn.push(
      <div className="breakdown-container-row" key="headre-duty-title-hdfs-three">
        <div className="hdfs-fee-type-text">
          Additional Duty ({currentSummary.additionalDuties.additionalDutyDescription})
        </div>
        <div className="hdfs-duty-rate-text">
          {renderDutyRate(currentSummary.additionalDuties.additionalDutyRate, currentSummary)}
          {renderAdditionalDutyUnits(currentSummary.additionalDuties.additionalDutyUnits)}
        </div>
      </div>
    );
  }
  if (currentSummary.section301Duties) {
    hdfsRowsToReturn.push(
      <div className="breakdown-container-row" key="headre-duty-title-hdfs-three">
        <div className="hdfs-fee-type-text">Section 301 (China) Duty</div>
        <div className="hdfs-duty-rate-text">{currentSummary.section301Duties}</div>
      </div>
    );
  }
  if (currentSummary.antiDumpingDuties) {
    hdfsRowsToReturn.push(
      <div className="breakdown-container-row" key="headre-duty-title-hdfs-three">
        <div className="hdfs-fee-type-text">Anti-Dumping Duty</div>
        <div className="hdfs-duty-rate-text">
          <span className="variable-text">Variable</span>
          <LightTooltip title="Click here for a detailed breakdown of the anti dumping duties">
            <InfoIcon className="pga-info-icon" onClick={handleADDModalOpen} sx={{ fontSize: '14px' }} />
          </LightTooltip>
        </div>
      </div>
    );
  }
  if (currentSummary.countervailingDuties) {
    hdfsRowsToReturn.push(
      <div className="breakdown-container-row" key="headre-duty-title-hdfs-three">
        <div className="hdfs-fee-type-text">Countervailing Duty</div>
        <div className="hdfs-duty-rate-text">
          <span className="variable-text">Variable</span>
          <LightTooltip title="Click here for a detailed breakdown of the countervailing duties">
            <InfoIcon className="pga-info-icon" onClick={handleCVDModalOpen} sx={{ fontSize: '14px' }} />
          </LightTooltip>
        </div>
      </div>
    );
  }
  if (hdfsRowsToReturn.length > 1) {
    return hdfsRowsToReturn;
  } else {
    return <></>;
  }
};
