import React from 'react';
import Button from '@mui/material/Button';

interface ImportalPrimaryButtonProps {
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  text: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium' | 'large';
  endIcon?: React.ReactNode;
  style?: React.CSSProperties; // Optional style prop to override default styles
}

const ImportalPrimaryButton: React.FC<ImportalPrimaryButtonProps> = ({
  onClick,
  text,
  type = 'button',
  size = 'medium',
  endIcon = <></>,
  disabled = false,
  style = {}, // Default empty object for the style prop
}) => {
  const defaultStyles: React.CSSProperties = {
    textTransform: 'none',
    backgroundColor: disabled ? '#d3d3d3' : '#388E3C',
    color: disabled ? '#a9a9a9' : '#FDFDFD',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    display: 'flex',
    padding: '8px 20px',
    alignItems: 'center',
    gap: '8px',
  };

  return (
    <Button
      type={type}
      color="info"
      size={size}
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      sx={{
        '&:hover': {
          backgroundColor: '#c0dcc2',
          color: '#525256',
        },
        ...defaultStyles,
        ...style,
      }}
      className="importal-primary-button"
      endIcon={endIcon}
    >
      {text}
    </Button>
  );
};

export default ImportalPrimaryButton;
