import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import UploadHtsInfoModal from '@/components/upload-hts-info-modal/UploadHtsInfoModal';
import ImportalPage from '@/shared-components/ImportalPage/ImportalPage';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import ImportalTable from '@/shared-components/ImportalTable/ImportalTable';
import { ColumnConfig, TableConfig } from 'common/interfaces/importalTable';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import { useEventBus } from '@/custom-hooks/event-bus/EventBus';
import { EventType, StagingCodeSummaryChangedEvent } from 'common/eventbus/eventBus';
import { StagingCodeSummary } from 'common/interfaces/stagingCodes';
import { stagingCodeSummaryTableConfig } from '@/utils/uploadHtsInfoUtils';

const HtsInfoPage: React.FC = () => {
  const api = useAPI();
  const eventBus = useEventBus();
  const [openModal, setOpenModal] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const [stagingCodeSummary, setStagingCodeSummary] = useState<StagingCodeSummary[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [htsDataImportTableConfig, setHtsDataImportTableConfig] = useState<TableConfig>(stagingCodeSummaryTableConfig);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleStagingCodeSummaryChanged = useCallback(
    async (event: StagingCodeSummaryChangedEvent) => {
      // Check if the summary with the given id already exists in the current list
      const alreadyExists = stagingCodeSummary.some((summary) => summary._id!.toString() === event.data.id);

      if (alreadyExists) return; // If the summary already exists, no need to proceed
      try {
        // Fetch the new staging codes summary data from the API
        const newSummary = await api.getStagingCodeSummaryById(event.data.id);
        // Add the new summary to the state
        setStagingCodeSummary((prevSummaries) => [...prevSummaries, newSummary.data]);

        setLoadingStatus(LoadingStatus.SUCCESS);
        setSuccessText(`Staging Codes Summary with ID ${newSummary.data.id} was added.`);
      } catch (error) {
        console.error('Error fetching new staging codes summary:', error);
      }
    },
    [stagingCodeSummary, api]
  );

  useEffect(() => {
    // Subscribe to the event
    // return the result for automatic cleanup the subscription when the component unmounts
    return eventBus.on(EventType.STAGING_CODES_SUMMARY_CHANGED, handleStagingCodeSummaryChanged);
  }, [eventBus, handleStagingCodeSummaryChanged]);

  const handleFetchData = useCallback(async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const response = await api.getStagingCodeSummary();
      setStagingCodeSummary(response.data);
    } catch (error) {
      console.error('Error fetching StagingCodeSummary data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  // Fetch data on component mount
  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div>
      <ImportalPage
        header="HTS Info"
        subheader="Upload and review HTS information such as Section 301, PGAs and more."
        loadingStatus={loadingStatus}
        loadingText={loadingText}
        successText={successText}
        errorText={errorText}
      >
        <ImportalTable
          data={stagingCodeSummary}
          tableConfig={htsDataImportTableConfig}
          setTableConfig={setHtsDataImportTableConfig}
          topRightButton={
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <ImportalPrimaryButton
                style={{
                  width: '170px',
                  height: '40px',
                  borderRadius: '8px',
                  fontSize: '12px',
                }}
                onClick={handleOpenModal}
                text="Upload HTS Info"
                endIcon={<FileUploadIcon sx={{ fontSize: '12px' }} />}
              />
            </div>
          }
        />
      </ImportalPage>
      {openModal && <UploadHtsInfoModal open={openModal} handleClose={handleCloseModal} />}
    </div>
  );
};

export default HtsInfoPage;
