import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Card, CircularProgress, IconButton, InputAdornment, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isEmailValid } from 'common/utilities/emailValidator';
import { formatPhoneNumber, formatPhoneNumberOnChange } from '@/utils/formatPhoneNumber';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '@/api/APIContext';
import './SignupForm.css';
import { Check, Visibility, VisibilityOff } from '@mui/icons-material';
import WelcomeHeader from '@/shared-components/WelcomeHeader/WelcomeHeader';
import SignUpFormFields from './SignUpFormFields';
import {
  handleFormChange,
  handlePhoneNumberChange,
  handleTermsConditionsChange,
  registerUser,
  User,
} from './formHandlers';

export default function SignupForm() {
  const navigate = useNavigate();
  const api = useAPI();
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [randomIndex, setRandomIndex] = useState(0);
  const [termsConditions, setTermsConditions] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailError, setEmailError] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userToSubmit = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      phone: formatPhoneNumber(data.get('phone')),
      password: data.get('password'),
    };

    registerUser(api, userToSubmit, setIsLoading, setErrorText, navigate);
  };

  const testimonials = [
    {
      quote: 'Very intuitive and straightforward',
      source: 'Head of Operations, Growing apparel group',
    },
    {
      quote: 'I wish I had this tool my whole career.',
      source: 'Head of Production, Large apparel brand',
    },
    {
      quote:
        'Importal helps our team quickly and easily compare vendors for new product lines and the customs costs associated so we always know what our landed cost is before we buy.',
      source: 'VP of Supply Chain, Growing apparel company',
    },
    {
      quote: "I can't do my job without this tool.",
      source: 'Operations Director, Freight forwarding company',
    },
    {
      quote:
        'Importal helps our team quickly and easily compare vendors for new product lines and the customs costs associated so we always know what our landed cost is before we buy.',
      source: 'VP of Supply Chain, Growing apparel company',
    },
    {
      quote: 'Great tool for helping us accurately project our duty liability for outstanding purchase orders.',
      source: 'Operations Manager, Growing apparel company',
    },
  ];

  useEffect(() => {
    setRandomIndex(getRandomIndex(testimonials.length));
  }, []);

  function getRandomIndex(arrayLength) {
    return Math.floor(Math.random() * arrayLength);
  }

  const handleLoading = (value) => {
    setIsLoading(value);
  };

  const renderTestimonialQuote = (testimonial) => {
    return (
      <>
        <Typography>{testimonial.quote}</Typography>
        <div className="quote">
          <div>- {testimonial.source} </div>
        </div>
      </>
    );
  };

  return (
    <Card>
      <Box>
        <div className="sign-up-wrapper">
          <div className="left-part">
            <WelcomeHeader>
              <Typography variant="h4">Sign up for a free trial</Typography>
            </WelcomeHeader>
            <SignUpFormFields
              handleFormChange={(event) => handleFormChange(event, setEmailError)}
              handleSubmit={handleSubmit}
              handlePhoneNumberChange={(event) => handlePhoneNumberChange(event, setPhoneNumber)}
              handleTermsConditionsChange={(event) => handleTermsConditionsChange(event, setTermsConditions)}
              setHidePassword={setHidePassword}
              hidePassword={hidePassword}
              phoneNumber={phoneNumber}
              emailError={emailError}
              isLoading={isLoading}
              termsConditions={termsConditions}
              errorText={errorText}
            />
          </div>
          <div className="features-container">
            <Card className="testimonials-card">{renderTestimonialQuote(testimonials[randomIndex])}</Card>
            <div className="key-features-header">Key Features</div>
            <div className="features-row-container">
              <div>
                <div className="feature-row">
                  <div className="feature-row-text">General Duty Rates</div>
                  <div className="feature-row-check">
                    <Check sx={{ color: 'green' }} />
                  </div>
                </div>
                <div className="feature-row">
                  <div className="feature-row-text">Merchandise Processing Fee</div>
                  <div className="feature-row-check">
                    <Check sx={{ color: 'green' }} />
                  </div>
                </div>
                <div className="feature-row">
                  <div className="feature-row-text">Harbor Maintenance Fee</div>
                  <div className="feature-row-check">
                    <Check sx={{ color: 'green' }} />
                  </div>
                </div>
                <div className="feature-row">
                  <div className="feature-row-text">China 301 Rates</div>
                  <div className="feature-row-check">
                    <Check sx={{ color: 'green' }} />
                  </div>
                </div>
                <div className="feature-row">
                  <div className="feature-row-text">China 301 Exclusions</div>
                  <div className="feature-row-check">
                    <Check sx={{ color: 'green' }} />
                  </div>
                </div>
                <div className="feature-row">
                  <div className="feature-row-text">Cotton Fee</div>
                  <div className="feature-row-check">
                    <Check sx={{ color: 'green' }} />
                  </div>
                </div>
                <div className="feature-row">
                  <div className="feature-row-text">Import Licenses</div>
                  <div className="feature-row-check">
                    <Check sx={{ color: 'green' }} />
                  </div>
                </div>
                <div className="feature-row">
                  <div className="feature-row-text">...and much more!</div>
                  <div className="feature-row-check">
                    <Check sx={{ color: 'green' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Card>
  );
}
