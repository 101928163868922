import { Card, CardContent, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export interface ImportalCardProps {
  title: string;
  subtitle?: string | ReactNode;
  topRightActionButton?: React.JSX.Element;
  style?: React.CSSProperties;
}

export default function ImportalCard({
  title,
  subtitle,
  topRightActionButton,
  children,
  style,
}: React.PropsWithChildren<ImportalCardProps>) {
  const defaultCardStyle: React.CSSProperties = {
    borderRadius: '16px!important',
  };

  return (
    <Card style={{ ...defaultCardStyle, ...style }}>
      <CardContent>
        <div className="header-question" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography>{title}</Typography>
            {subtitle && (
              <div style={{ paddingTop: '4px' }} className="my-shipments-subheader">
                {subtitle}
              </div>
            )}
          </div>
          {topRightActionButton && topRightActionButton}
        </div>
        {children}
      </CardContent>
    </Card>
  );
}
