import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Add, Check, Close } from '@mui/icons-material';
import './LinksTable.css';

const LinksTable = ({ links, renderRow, columnNames }) => (
  <div className="table-scroll-container">
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columnNames.map((name, index) => (
              <TableCell key={index} sx={{ fontWeight: '700', color: '#525256' }}>
                {name}
              </TableCell>
            ))}
            <TableCell className="action-icons"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="user-row">{links.map((link, index) => renderRow(link, index))}</TableBody>
      </Table>
    </TableContainer>
  </div>
);

export default LinksTable;
