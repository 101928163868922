import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { useAPI } from '@/api/APIContext';
import { useDropzone } from 'react-dropzone';
import { ColumnMapping } from 'common/interfaces/stagingCodes';
import ImportalSecondaryButton from '@/shared-components/ImportalSecondaryButton/ImportalSecondaryButton';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ImportalFileUploadedCard from '@/shared-components/ImportalFileUploadedCard/ImportalFileUploadedCard';
import ImportalColumnMapping from '@/shared-components/ImportalColumnMapping/ImportalColumnMapping';

export default function UploadHtsInfo({ handleClose }) {
  const theme = useTheme();
  const api = useAPI();
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [header, setHeader] = useState([]);
  const [filePath, setFilePath] = useState('');
  const [progressValue, setProgressValue] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [columnMapping, setColumnMapping] = useState<ColumnMapping[]>([]);

  const handleColumnMappings = async (header: string[], fileName: string) => {
    setLoading(true);
    try {
      const columnResponse = await api.getColumnMapping(header, fileName);
      setColumnMapping(columnResponse.data.mappings);
    } catch (error) {
      console.error('Error in handleColumnMappings', error);
    } finally {
      setLoading(false);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      const formData: FormData = new FormData();
      formData.append('file', file);
      setUploadFile(file);
      try {
        const response = await api.uploadFileWithProgress(formData, (progressData) => {
          setProgressValue(progressData.progress);
          setTimeLeft(`${progressData.timeLeft} seconds left`);
        });
        setFilePath(response.data.filePath);
        if (Boolean(response.data.filePath)) {
          try {
            const headerResponse = await api.getCsvHeaders(response.data.filePath);
            setHeader(headerResponse.data.header);
            await handleColumnMappings(headerResponse.data.header, response.data.filePath);
          } catch (error) {
            console.error('Error fetching CSV headers:', error);
          }
        }
      } catch (error) {
        console.error('Error uploading file', error);
      }
    } else {
      console.error('Please drop only one file.');
      alert('Please drop only one file.');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
  });

  const handleRemoveUpload = () => {
    setUploadFile(null);
    setFilePath('');
    setHeader([]);
    setColumnMapping([]);
  };

  async function handleSubmit(): Promise<void> {
    const response = await api.submitTabularData(columnMapping, filePath);
    handleClose();
  }

  // Step 1: uploading file
  const renderUploadStep = (): React.JSX.Element => (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border: '1px solid #D8D8D8',
          borderRadius: '4px',
          padding: '32px',
          textAlign: 'center',
          cursor: 'pointer',
          marginBottom: '16px',
          height: '125px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#525256',
        }}
      >
        <input {...getInputProps()} />
        <UploadFileOutlinedIcon />
        <div style={{ marginTop: '4px' }}>
          Drag & Drop or <span style={{ color: '#4880FF' }}>Choose file</span> to upload
        </div>
      </Box>
      {uploadFile && (
        <ImportalFileUploadedCard
          uploadFile={uploadFile}
          onRemove={handleRemoveUpload}
          progressValue={progressValue}
          timeLeft={timeLeft}
        />
      )}
    </>
  );

  return (
    <>
      <div
        style={{
          marginBottom: '24px',
          color: '#525256',
          lineHeight: '28px',
          fontWeight: '700',
          fontSize: '24px',
        }}
      >
        Upload HTS Info
      </div>
      <Box>
        {renderUploadStep()}
        <div style={{ marginTop: '16px' }}>
          <div style={{ color: '#525256', lineHeight: '28px', fontWeight: '700', fontSize: '20px' }}>Preview</div>
          {uploadFile ? (
            <ImportalColumnMapping
              columnMapping={columnMapping}
              setColumnMapping={setColumnMapping}
              loading={loading}
              header={header}
              filename={filePath}
              handleColumnMappings={handleColumnMappings}
            />
          ) : (
            <div>Upload a file to see Cal's preview of the column mappings.</div>
          )}
        </div>
        <div className="next-button-container">
          <ImportalSecondaryButton text="Cancel" onClick={handleClose} />
          <ImportalPrimaryButton
            style={{
              paddingLeft: '32px',
              paddingRight: '32px',
            }}
            text="Upload"
            disabled={!uploadFile}
            onClick={handleSubmit}
          />
        </div>
      </Box>
    </>
  );
}
