import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router';
import { Button, IconButton, useTheme } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HomeComponent from '@/pages/home/HomeComponent';
import Drawer from '@mui/material/Drawer';
import Settings from '@/pages/settings/Settings';
import NavMenu, { NavItem } from '@/shared-components/navMenu';
import './Dashboard.css';
import Shipments from '@/pages/shipments/Shipments';
import DashboardContext from './DashboardProvider';
import ConsumerClassifications from '@/pages/hts-classifications/ConsumerClassifications';
import ComplianceOne from '@/pages/compliance-one/ComplianceOne';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CalculateIcon from '@mui/icons-material/Calculate';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import ChatIcon from '@mui/icons-material/Chat';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useAPI } from '@/api/APIContext';
import { Route, Routes } from 'react-router-dom';
import { User } from 'common/interfaces/user';
import { ArrowRight, Assessment, Dataset, Inventory, Money, SavedSearch } from '@mui/icons-material';
import ProductLibrary from '@/pages/product-library/ProductLibrary';
import DutyCalculator from '@/pages/duty-calculator/DutyCalculator';
import { AppContext } from '@/App';
import BetaFeatureRoute from '@/custom-hooks/beta-feature/BetaFeatureRoute';
import { useBetaFeatures } from '@/custom-hooks/beta-feature/BetaFeatureContext';
import NotFoundPage from '@/pages/not-found-page/NotFoundPage';
import LinearProgressBar from '@/components/search-count-progress-bar/SearchCountProgressBar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PlanDetails from '@/components/plan-details/PlanDetails';
import CalChat from '../cal-chat/CalChat';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import { activePlanUtils } from '@/utils/activePlanUtils';
import ReportsPage from '@/pages/reports/ReportsPage';
import { useActiveUser } from '@/custom-hooks/user/UserProvider';
import TermsAndConditionsModal from '@/shared-components/TermsAndConditionsModal';
import { Biotech, Mouse, SoupKitchen, ToggleOff, ToggleOn } from '@mui/icons-material';
import useIsMobile from '@/custom-hooks/mobile-device/isMobileDevice';
import VesselSnackbar from '@/components/call-to-action-snackbar/VesselSnackbar';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import SideMenu from '@/shared-components/SideMenu/SideMenu';
import MenuIcon from '@mui/icons-material/Menu';
import Fab from '@mui/material/Fab';
import MobileMenuToggleButton from '@/shared-components/MobileMenuToggleButton/MobileMenuToggleButton';
import { FeatureName } from 'common/interfaces/plan';
import SearchCountProgressBar from '@/components/search-count-progress-bar/SearchCountProgressBar';

export default function Dashboard() {
  const api = useAPI();
  const navigate = useNavigate();
  const location = useLocation();

  const match = useMatch({
    path: '/dashboard/:subRoute',
  });

  const betaFeatures = useBetaFeatures();
  const activePlan = useActivePlan();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showSideMenu, setShowSideMenu] = useState<boolean>(false);
  const [navSelection, setNavSelection] = useState<NavItem | undefined>();
  const [vesselSnackbarOpen, setVesselSnackbarOpen] = useState(true);
  const [manifestModalOpen, setManifestModalOpen] = useState(false);
  const isMobileDevice = useIsMobile(() => {});
  const activeBusiness = useActiveBusiness();
  const [showUsageSection, setShowUsageSection] = useState(false);
  const [mobileSideMenu, setMobileSideMenu] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    clearSession,
    handleOpenPlansModal,
    handleClosePlansModal,
    openPlansModal,
    setOpenPlansModal,
    setOpenContactUs,
  } = useContext(AppContext);

  const [showTermsConditionsModal, setShowTermsConditionsModal] = useState(false);
  const { user, refreshUser } = useActiveUser();

  const handleManifestModalClose = () => {
    setManifestModalOpen(false);
  };

  const handleManifestModalOpen = () => {
    setManifestModalOpen(true);
  };

  const handleVesselSnackbarClose = () => {
    setVesselSnackbarOpen(false);
  };

  const handleVesselSnackbarOpen = () => {
    setVesselSnackbarOpen(true);
  };

  useEffect(() => {
    if (!activeBusiness.business) {
      setVesselSnackbarOpen(false);
    }
  }, [activeBusiness.business]);

  const handleTermsConditionsSubmit = async () => {
    try {
      await api.updateUserTermsAcceptance(true);
      await refreshUser();
      setShowTermsConditionsModal(false);
    } catch (error) {
      console.error('ERROR accepting terms and conditions', error);
    }
  };

  useEffect(() => {
    const currentUser = user as User & {
      hasAcceptedTermsAndConditions: boolean | undefined;
    };

    if (
      currentUser &&
      currentUser.hasAcceptedTermsAndConditions !== undefined &&
      currentUser.hasAcceptedTermsAndConditions === false
    ) {
      setShowTermsConditionsModal(true);
    }
  }, [user]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const freeTrialEnding = searchParams.get('freeTrialEnding');
    if (freeTrialEnding === 'true') {
      setOpenPlansModal(true);
      setIsLoading(true);
    }
  }, [location]);

  const handleDataLoaded = () => {
    setIsLoading(false);
  };

  const homeMenuItem: NavItem[] = [
    {
      displayName: 'Home',
      routerPath: '',
      icon: <HomeIcon />,
      element: <HomeComponent />,
    },
  ];

  // few mobile menu items
  const mobileMenuItems: NavItem[] = [
    {
      displayName: 'Compliance One',
      routerPath: 'compliance-one',
      icon: <FactCheckIcon />,
      element: <ComplianceOne />,
    },
    {
      displayName: 'Duty Calculator',
      routerPath: 'duty-calculator',
      icon: <CalculateIcon />,
      element: <DutyCalculator />,
    },
  ];

  const desktopMenuItems: NavItem[] = [
    {
      displayName: 'Compliance Tools',
      routerPath: 'compliance-tools',
      icon: <ConstructionIcon />,
      element: undefined,
      children: [
        {
          displayName: 'Compliance One',
          routerPath: 'compliance-one',
          icon: <FactCheckIcon />,
          element: <ComplianceOne />,
          planRules: {
            featureName: FeatureName.SEARCHES,
            disabledMessage: 'Upgrade for more searches with Compliance One',
            canUseCustomsOnboarding: false,
            onClickDisabled: handleOpenPlansModal,
          },
        },
        {
          displayName: 'Duty Calculator',
          routerPath: 'duty-calculator',
          icon: <CalculateIcon />,
          element: <DutyCalculator />,
          planRules: {
            featureName: FeatureName.SEARCHES,
            disabledMessage: 'Upgrade for more searches with Duty Calculator',
            canUseCustomsOnboarding: false,
            onClickDisabled: handleOpenPlansModal,
          },
        },
      ],
    },
  ];

  // import services
  const importServicesItem: NavItem = {
    displayName: 'Import Services',
    routerPath: 'import-services',
    icon: <DirectionsBoatIcon />,
    element: undefined,
    children: [
      {
        displayName: 'Shipments',
        routerPath: 'shipments',
        icon: <LocalShippingIcon />,
        element: <Shipments />,
      },
      {
        displayName: 'HTS Classifications',
        routerPath: 'hts-classifications/*',
        icon: <SummarizeIcon />,
        element: <ConsumerClassifications />,
      },
    ],
  };

  // bottom menu items
  const otherMenuItems: NavItem[] = [
    {
      displayName: 'Product Library',
      routerPath: 'product-library',
      icon: <Inventory />,
      element: <ProductLibrary />,
      planRules: {
        featureName: FeatureName.PRODUCT_LIBRARY,
        disabledMessage:
          "A repository of various imported products, providing essential details on each item's import compliance and duty obligations. ",
        canUseCustomsOnboarding: true,
        onClickDisabled: handleOpenPlansModal,
      },
    },
    {
      displayName: 'Chat with Cal',
      routerPath: 'cal',
      icon: <ChatIcon />,
      element: <CalChat />,
      betaFeature: 'cal',
    },
    {
      displayName: 'Reporting & Analytics',
      routerPath: 'reports',
      icon: <Assessment />,
      element: <ReportsPage />,
      betaFeature: 'reporting',
    },
    {
      displayName: 'Settings',
      routerPath: 'settings',
      icon: <SettingsIcon />,
      element: <Settings setSelectedModule={setNavSelection} />,
    },
    {
      displayName: 'Broker App',
      routerPath: 'broker',
      icon: <Money />,
      betaFeature: 'broker-dashboard',
      onClick: () => {
        navigate('/broker/dashboard');
      },
    },
    {
      displayName: 'Jobs Admin',
      routerPath: '/admin/queues',
      icon: <Dataset />,
      betaFeature: 'jobs-admin',
      onClick: () => {
        const url = api.getJobsAdminPanelURL();
        let element = document.createElement('a');
        element.href = url;
        element.click();
      },
    },
    {
      displayName: 'Help',
      routerPath: 'help',
      icon: <HelpIcon />,
      element: undefined,
      onClick: () => {
        setOpenContactUs(true);
      },
    },
    {
      displayName: 'Logout',
      routerPath: 'logout',
      icon: <LogoutIcon />,
      element: undefined,
      onClick: () => handleLogout(),
    },
  ];

  const menuItems: NavItem[] = [...homeMenuItem, ...desktopMenuItems, importServicesItem, ...otherMenuItems];
  const mobileMenu: NavItem[] = [...homeMenuItem, ...mobileMenuItems, ...otherMenuItems];
  const navItems = isMobileDevice ? mobileMenu : menuItems;

  useEffect(() => {
    const selectedRoute = match?.params.subRoute;

    function findSelectedNavItem(
      localNavItems: NavItem[] | undefined,
      localSelectedRoute?: string
    ): NavItem | undefined {
      if (!localNavItems || localNavItems.length === 0) {
        return undefined;
      }
      for (const navItem of localNavItems) {
        if (!navItem.routerPath && !localSelectedRoute) {
          // this is for home component which has no route beyond dashboard
          return navItem;
        }

        const routerPathSansWildCard = navItem.routerPath.endsWith('/*')
          ? navItem.routerPath.slice(0, -2)
          : navItem.routerPath;
        if (routerPathSansWildCard === localSelectedRoute) {
          return navItem;
        }
        const matchingChild = findSelectedNavItem(navItem.children, localSelectedRoute);
        if (matchingChild) {
          return matchingChild;
        }
      }
    }

    const preSelectedNavItem = findSelectedNavItem(navItems, selectedRoute);

    setNavSelection(preSelectedNavItem!);
  }, [location]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    clearSession();

    navigate('/login');
  };

  const shouldShowNavItem = (navItem: NavItem): boolean => {
    if (navItem.betaFeature) {
      return betaFeatures.includes(navItem.betaFeature);
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (isExpanded) {
      const delay = setTimeout(() => {
        setShowUsageSection(true);
      }, 200);

      return () => clearTimeout(delay);
    } else {
      setShowUsageSection(false);
    }
  }, [isExpanded]);

  const renderUsageSection = () => {
    if (activePlan.plan.title === 'Free') {
      return (
        <div key="free-plan" className="free-plan">
          <div className="plan-text-container">
            Free plan ({activePlanUtils(activePlan.metadata)} of 2 searches used)
          </div>
          <div style={{ marginLeft: '16px', marginRight: '16px' }}>
            <SearchCountProgressBar />
          </div>
          <div className="upgrade-button-container">
            <Button
              sx={{
                textTransform: 'none',
                color: 'white',
                width: '100%',
                background: 'rgba(255, 255, 255, 0.08)',
                padding: '12px 16px',
                fontWeight: '600',
                marginTop: '20px',
              }}
              onClick={handleOpenPlansModal}
              endIcon={<ArrowRight sx={{ marginLeft: '12px' }} />}
            >
              Upgrade to Pro
            </Button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderNavItemAsRoute = (navItem: NavItem, index: number) => {
    if (navItem.children) {
      return navItem.children?.map(renderNavItemAsRoute);
    } else if (navItem.betaFeature) {
      return (
        <Route
          key={index}
          path={navItem.routerPath}
          element={
            <BetaFeatureRoute betaFeature={navItem.betaFeature} fallback={<NotFoundPage />}>
              {navItem.element}
            </BetaFeatureRoute>
          }
        />
      );
    } else {
      return <Route key={index} path={navItem.routerPath} element={navItem.element} />;
    }
  };

  const toggleSideMenu = () => {
    setMobileSideMenu((prev) => !prev);
  };

  return (
    <div className="dashboard-container">
      <Drawer anchor={open ? 'left' : undefined} open={showSideMenu} onClose={() => setShowSideMenu(false)}>
        <div className="left-menu">
          <div className="importal-logo-dashboard">
            <img src="/IMPORTAL.png" width="185" height="120" alt="logo" />
          </div>
          <NavMenu
            navItems={navItems.filter(shouldShowNavItem)}
            open={open}
            isMenuOpen={isExpanded}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
            onNavItemSelected={(navItem) => {
              const routerPathSansWildCard = navItem.routerPath.endsWith('/*')
                ? navItem.routerPath.slice(0, -2)
                : navItem.routerPath;
              setNavSelection(navItem);
              navigate(routerPathSansWildCard);
            }}
            isBrokerApp={false}
            // TODO: best way to sync state of pre-selected
            selectedNavItem={navSelection?.displayName}
            setShowSideMenu={setShowSideMenu}
            underNavItemElement={
              vesselSnackbarOpen && isExpanded ? (
                <VesselSnackbar
                  handleManifestModalOpen={handleManifestModalOpen}
                  handleClose={handleVesselSnackbarClose}
                />
              ) : undefined
            }
          />
        </div>
      </Drawer>
      <SideMenu
        isExpanded={isExpanded}
        navItems={navItems}
        shouldShowNavItem={shouldShowNavItem}
        handleOpenPlansModal={handleOpenPlansModal}
        open={open}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        setNavSelection={setNavSelection}
        navigate={navigate}
        navSelection={navSelection}
        vesselSnackbarOpen={vesselSnackbarOpen}
        handleManifestModalOpen={handleManifestModalOpen}
        handleVesselSnackbarClose={handleVesselSnackbarClose}
        isBrokerApp={false}
        renderUsageSection={renderUsageSection}
        mobileSideMenu={mobileSideMenu}
      />
      <MobileMenuToggleButton toggleSideMenu={toggleSideMenu} />
      <div className="dashboard-content">
        <DashboardContext.Provider
          value={{
            selectedModule: navSelection,
            setSelectedModule: setNavSelection,
          }}
        >
          <Routes>{navItems.map((item, index) => renderNavItemAsRoute(item, index))}</Routes>
        </DashboardContext.Provider>
        <PlanDetails
          openModal={openPlansModal}
          closeHandler={handleClosePlansModal}
          setOpenModal={setOpenPlansModal}
          onLoaded={handleDataLoaded}
        />
      </div>
      <TermsAndConditionsModal
        open={showTermsConditionsModal}
        onClose={() => setShowTermsConditionsModal(false)}
        onSubmit={handleTermsConditionsSubmit}
      />
    </div>
  );
}
