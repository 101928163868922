import { IconButton, Tooltip } from '@mui/material';
import { Add, DocumentScanner } from '@mui/icons-material';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import EntryPrepDocumentsView, { DocumentReviewMode } from '@/broker-app/pages/shipments/EntryPrepDocumentsView';
import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import { ShipmentPageContext } from '@/broker-app/pages/shipments/ShipmentPage';
import { EntryPrepDocForDisplay, EntryPrepDocWithDocument } from 'common/interfaces/documentParsing';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { Entry, EntryFilingStatus } from 'common/interfaces/entry';
import { Mode } from 'common/interfaces/business';
import { DocumentType } from 'common/interfaces/document';
import { useEventBus } from '@/custom-hooks/event-bus/EventBus';
import { DataImportChangedEvent, EventType } from 'common/eventbus/eventBus';
import PreviewEntryDataChangeModal from '@/broker-app/pages/shipments/PreviewEntryDataChangeModal';

interface Props {
  entry: Entry;
  onEntryChanged: (arg0: Entry) => void;
  popAddOrEditScreen: () => void;
}

export default function AddOrEditDocumentsForEntry({ entry, onEntryChanged, popAddOrEditScreen }: Props) {
  const { shipment } = useContext(ShipmentPageContext);
  const api = useAPI();

  const [mode, setMode] = useState(Mode.VIEW);

  const [entryPrepDocs, setEntryPrepDocs] = useState<Array<EntryPrepDocForDisplay>>([]);

  // because of edit functionality, we must have a local copy of the docs to hold under edit
  const [localEntryPrepDocs, setLocalEntryPrepDocs] = useState<Array<EntryPrepDocForDisplay>>([]);

  const [entryPrepDocSelections, setEntryPrepDocSelections] = useState<Array<boolean>>([]);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const [showEntryDataWillChangeModal, setShowEntryDataWillChangeModal] = useState(false);
  const [potentialChangesToEntry, setPotentialChangesToEntry] = useState<any | undefined>();

  const eventBus = useEventBus();
  const handleEntryPrepDocChanged = useCallback(
    async (event: DataImportChangedEvent) => {
      // Check if the shipment already exists in the current list
      const entryPrepDocWithUpdate = entryPrepDocs.find(
        (entryPrepDoc) => entryPrepDoc.dataImport?._id.toString() === event.data.dataImportId
      );

      if (!entryPrepDocWithUpdate) return; // the data import changed event doesn't apply to any of the entry prep docs that we are looking at

      try {
        // Fetch the updated shipment data from the API
        const { data: updatedEntryPrepDoc } = await api.getEntryPrepDocById(entryPrepDocWithUpdate._id.toString());

        // Update the specific shipment in the state
        setEntryPrepDocs((prevEntryPrepDocs) => {
          return prevEntryPrepDocs.map((entryPrepDoc) =>
            entryPrepDoc._id.toString() === entryPrepDocWithUpdate._id.toString() ? updatedEntryPrepDoc : entryPrepDoc
          );
        });

        setLoadingStatus(LoadingStatus.SUCCESS);
        setSuccessText(`Parse for ${updatedEntryPrepDoc.document.fileName} was Updated`);
      } catch (error) {
        console.error('Error fetching updated entryPrepDoc:', error);
      }
    },
    [entryPrepDocs, api]
  );

  useEffect(() => {
    // Subscribe to the event
    // return the result for automatic cleanup the subscription when the component unmounts
    return eventBus.on(EventType.DATA_IMPORT_CHANGED, handleEntryPrepDocChanged);
  }, [eventBus, handleEntryPrepDocChanged]);

  const onEntryPrepDocUpdated = async (updatedDocument: EntryPrepDocForDisplay): Promise<any> => {
    try {
      setLoadingStatus(LoadingStatus.LOADING);
      const { data } = await api.updateEntryPrepDoc(updatedDocument._id.toString(), updatedDocument);
      setLoadingStatus(LoadingStatus.SUCCESS);
      const index = entryPrepDocs.findIndex(
        (entryPrepDoc) => updatedDocument._id.toString() === entryPrepDoc._id.toString()
      );
      const updatedEntryPrepDocs = [...entryPrepDocs];
      updatedEntryPrepDocs[index] = data;
      setEntryPrepDocs(updatedEntryPrepDocs);
      setLoadingStatus(LoadingStatus.NOT_LOADING);
    } catch (err) {
      console.error('error updating entry prep doc');
      console.error(err);
      setLoadingStatus(LoadingStatus.ERROR);
    }
  };

  const refreshEntryPrepDocs = () => {
    setLoadingText('Loading entry prep docs...');
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getEntryPrepDocsForShipment(shipment!._id!.toString())
      .then(({ data }) => {
        setSuccessText('Successfully loaded entry prep docs');
        setLoadingStatus(LoadingStatus.SUCCESS);
        setEntryPrepDocs(data);
        setEntryPrepDocSelections(data?.map((_) => false));
        setLoadingStatus(LoadingStatus.NOT_LOADING);
      })
      .catch((err) => {
        setLoadingStatus(LoadingStatus.ERROR);
        setErrorText('Error getting entry prep docs');
      });
  };

  useEffect(() => {
    refreshEntryPrepDocs();
  }, [shipment, api]);

  const getPreviewOfChangedEntryPrepDocs = () => {
    api
      .previewModifyDocumentsOfEntry(entry!._id!.toString(), localEntryPrepDocs)
      .then(({ data: preview }) => {
        setPotentialChangesToEntry(preview);
      })
      .catch((err) => {
        console.error('error getting a preview of what will change when the entry prep docs are changed', err);
      });
  };

  const modifyDocumentsOnEntry = useCallback(async () => {
    // Extract selected documents
    const selectedEntryPrepDocs: EntryPrepDocWithDocument[] = entryPrepDocs.filter(
      (_, index) => entryPrepDocSelections[index]
    );

    try {
      setLoadingStatus(LoadingStatus.LOADING);
      const { data } = await api.modifyDocumentsOfEntry(entry!._id!.toString(), selectedEntryPrepDocs);
      setLoadingStatus(LoadingStatus.SUCCESS);

      // TODO: hmm, what to do about this?
      onEntryChanged(data);
      setLoadingStatus(LoadingStatus.NOT_LOADING);
    } catch (err) {
      console.error('Error creating entry invoice from prep docs', err);
      setLoadingStatus(LoadingStatus.ERROR);
    }
  }, [api, entryPrepDocs, entryPrepDocSelections]);

  useEffect(() => {
    // initial render should be based on entry filingStatus

    if (entry.entryFilingStatus === EntryFilingStatus.PREPARING_DOCUMENTS) {
      setMode(Mode.ADD);

      // in this case, we are ADDING Documents, and they should all be selected by default
      setLocalEntryPrepDocs(entryPrepDocs);
      setEntryPrepDocSelections(entryPrepDocs.map((_) => true));
    } else if (entry.entryFilingStatus === EntryFilingStatus.PREPARING_ENTRY) {
      setMode(Mode.EDIT);
      // in this case, we are potentially CHANGING Documents, and the selected ones should be what is in
      // the entry.includedEntryPrepDocs...
      setLocalEntryPrepDocs(entry.includedEntryPrepDocs);

      // compare the 'entryPrepDocs' Variable with the included to get which are checked

      const selected = entryPrepDocs.map((doc, index) => {
        return entry.includedEntryPrepDocs.some((includedDoc) => includedDoc._id.toString() === doc._id.toString());
      });
      setEntryPrepDocSelections(selected);
    }
  }, [entry, entryPrepDocs]);

  const validEntryPrepDocsAreSelected = (): boolean => {
    const selectedEntryPrepDocs: EntryPrepDocWithDocument[] = entryPrepDocs.filter(
      (_, index) => entryPrepDocSelections[index]
    );
    return selectedEntryPrepDocs.some((doc) =>
      [DocumentType.COMMERCIAL_INVOICE_AND_PACKING_LIST, DocumentType.COMMERCIAL_INVOICE].includes(doc.documentType)
    );
  };

  const onActionButtonClicked = () => {
    if (mode === Mode.ADD) {
      modifyDocumentsOnEntry();
      popAddOrEditScreen();
    } else if (mode === Mode.EDIT) {
      getPreviewOfChangedEntryPrepDocs();
      setShowEntryDataWillChangeModal(true);
    }
  };

  const buttonIsEnabled = () => {
    // hmm if entryPrepDocSelections changes -- then true
    return true;
  };

  const onClickParseAll = () => {
    if (!shipment || !shipment._id) {
      console.error('somehow, shipment is not available via context');
      return;
    }
    api
      .parseAllShipmentDocuments(shipment!._id!.toString())
      .then(refreshEntryPrepDocs)
      .catch((err) => {
        console.error('error sending all documents to be parsed', err);
      });
  };

  const actionButtonText = () => {
    if (mode === Mode.ADD) {
      return `Add ${entryPrepDocSelections.length} Docs`;
    } else if (mode === Mode.EDIT) {
      return `Change ${entryPrepDocSelections.length} Docs`;
    } else {
      return `Remove Documents`;
    }
  };

  return (
    <>
      <div className="next-button-container">
        <Tooltip title={'Parse All Documents'}>
          <IconButton onClick={onClickParseAll}>
            <DocumentScanner />
          </IconButton>
        </Tooltip>
        <ImportalPrimaryButton
          disabled={!buttonIsEnabled()}
          style={{ width: '165px', borderRadius: '16px', fontSize: '14px' }}
          endIcon={<Add />}
          onClick={onActionButtonClicked}
          text={actionButtonText()}
        />
      </div>
      <EntryPrepDocumentsView
        entryPrepDocs={entryPrepDocs}
        onEntryPrepDocUpdated={onEntryPrepDocUpdated}
        entryPrepDocsSelections={entryPrepDocSelections}
        setEntryPrepDocsSelections={setEntryPrepDocSelections}
        documentReviewMode={DocumentReviewMode.WITH_SELECTION}
      />
      <PreviewEntryDataChangeModal
        open={showEntryDataWillChangeModal}
        onClose={() => {
          setShowEntryDataWillChangeModal(false);
        }}
        loadingStatus={loadingStatus}
        potentialChangesToEntry={potentialChangesToEntry}
        onConfirmChanges={() => {
          modifyDocumentsOnEntry().then(() => {
            setShowEntryDataWillChangeModal(false);
            popAddOrEditScreen();
          });
        }}
      />
    </>
  );
}
