import React, { useContext, useMemo, useState } from 'react';
import { Card, Checkbox, IconButton } from '@mui/material';
import ImportalVerticalTabs from '@/shared-components/ImportalTabs/ImportalVerticalTabs';
import CommercialInvoiceParsedView from './CommercialInvoiceParsedView';
import {
  CommercialInvoiceWithDocument,
  EntryPrepDocForDisplay,
  PackingList,
  ParseProfile,
  ParseProfileType,
} from 'common/interfaces/documentParsing';
import { DocumentType, DocumentTypeToText } from 'common/interfaces/document';
import moment from 'moment';
import PackingListParsedView from '@/broker-app/pages/shipments/PackingListParsedView';
import { useAPI } from '@/api/APIContext';
import ImportalCalFeedbackModal from '@/shared-components/ImportalCalFeedbackModal/ImportalCalFeedbackModal';
import ReplayIcon from '@mui/icons-material/Replay';
import FloatingTooltip from '@/shared-components/FloatingTooltip/FloatingTooltip';
import { ShipmentPageContext } from '@/broker-app/pages/shipments/ShipmentPage';
import { DataImportStatus } from 'common/interfaces/dataimport';
import Box from '@mui/material/Box';
import { EntryPrepTabContext } from '@/broker-app/pages/shipments/EntryPrepTab';
import EntryPrepDocContainer from '@/broker-app/pages/shipments/EntryPrepDocContainer';

const styles = {
  parsedViewContainer: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
};

export enum DocumentReviewMode {
  WITH_SELECTION = 'WITH_SELECTION',
  NO_SELECTION = 'NO_SELECTION',
}

interface EntryPrepDocumentsViewProps {
  entryPrepDocs: Array<EntryPrepDocForDisplay>;
  onEntryPrepDocUpdated: (arg0: EntryPrepDocForDisplay) => void;
  documentReviewMode: DocumentReviewMode;
  entryPrepDocsSelections?: Array<boolean>;
  setEntryPrepDocsSelections?: (arg0: Array<boolean>) => void;
}

export default function EntryPrepDocumentsView({
  entryPrepDocs,
  onEntryPrepDocUpdated,
  documentReviewMode = DocumentReviewMode.NO_SELECTION,
  entryPrepDocsSelections,
  setEntryPrepDocsSelections,
}: EntryPrepDocumentsViewProps) {
  const { shipment } = useContext(ShipmentPageContext);
  const {} = useContext(EntryPrepTabContext);

  const api = useAPI();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [reparseModalOpen, setReparseModalOpen] = useState(false);
  const [entryPrepDocForReparseModal, setEntryPrepDocForReparseModal] = useState<EntryPrepDocForDisplay | undefined>();

  const reparseEntryPrepDoc = (reparseArgs: { feedback: string; parseType: ParseProfileType }) => {
    if (!entryPrepDocForReparseModal) {
      return;
    }

    const parseProfile: ParseProfile = {
      parseType: reparseArgs.parseType,
      feedback: reparseArgs.feedback,
    };
    api
      .reparseEntryPrepDoc(entryPrepDocForReparseModal._id.toString(), parseProfile, shipment?.business?.toString())
      .catch((err) => {
        console.error('error submitting re-parse for entryPrepDoc', err);
      })
      .finally(handleCloseFeedbackModal);
  };

  const renderViewBasedOnDocumentType = (entryPrepDoc: EntryPrepDocForDisplay) => {
    if (!entryPrepDoc.dataImport || DataImportStatus.COMPLETED === entryPrepDoc.dataImport?.status) {
      switch (entryPrepDoc.documentType) {
        case DocumentType.COMMERCIAL_INVOICE_AND_PACKING_LIST:
        case DocumentType.COMMERCIAL_INVOICE:
          return (
            <EntryPrepDocContainer entryPrepDoc={entryPrepDoc} onEntryPrepDocChanged={onEntryPrepDocUpdated}>
              <CommercialInvoiceParsedView
                commercialInvoice={entryPrepDoc.properties as CommercialInvoiceWithDocument}
                onCommercialInvoiceChanged={(commercialInvoice) => {
                  onEntryPrepDocUpdated({ ...entryPrepDoc, properties: commercialInvoice });
                }}
              />
            </EntryPrepDocContainer>
          );
        case DocumentType.PACKING_LIST:
          return (
            <EntryPrepDocContainer entryPrepDoc={entryPrepDoc} onEntryPrepDocChanged={onEntryPrepDocUpdated}>
              <PackingListParsedView
                packingList={entryPrepDoc.properties as PackingList}
                onPackingListChanged={(packingList) => {
                  onEntryPrepDocUpdated({ ...entryPrepDoc, properties: packingList });
                }}
              />
            </EntryPrepDocContainer>
          );

        default:
          return <div>Document type not supported.</div>;
      }
    } else if (
      [DataImportStatus.SUBMITTED_PENDING, DataImportStatus.PROCESSING].includes(entryPrepDoc.dataImport?.status)
    ) {
      return (
        <Card>
          <Box style={styles.parsedViewContainer}>
            <div
              style={{
                marginTop: '36px',
                height: '500px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              Parsing In Progress!
            </div>
          </Box>
        </Card>
      );
    } else if (DataImportStatus.ERROR === entryPrepDoc.dataImport?.status) {
      return (
        <Card>
          <Box style={styles.parsedViewContainer}>
            <div
              style={{
                marginTop: '36px',
                height: '500px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              Error Parsing
            </div>
          </Box>
        </Card>
      );
    } else {
      return <div>Unknown Status</div>;
    }
  };

  const handleCloseFeedbackModal = () => {
    setEntryPrepDocForReparseModal(undefined);
    setReparseModalOpen(false);
  };

  const tabsConfig = useMemo(() => {
    return entryPrepDocs.map((entryPrepDoc, index) => ({
      title: entryPrepDoc.documentType,
      label: (
        <div style={{ display: 'flex', justifyContent: 'center' }} onClick={() => setSelectedTabIndex(index)}>
          <div
            style={{
              textAlign: 'left',
              whiteSpace: 'nowrap',
              width: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <div style={{ fontSize: '11px', textWrap: 'wrap' }}>
              <b>{entryPrepDoc.document.fileName}</b>
            </div>
            <div style={{ fontSize: '10px' }}>
              {DocumentTypeToText[entryPrepDoc.document.type]} (
              {moment((entryPrepDoc.document as any).createdAt)
                .tz('America/Chicago')
                .format('MM/DD/YY')}
              )
            </div>
            <div style={{ fontSize: '10px' }}>
              {entryPrepDoc.properties.invoiceNumber ? entryPrepDoc.properties.invoiceNumber : 'No Invoice Number'}
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center' }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`/documents/${entryPrepDoc.document._id}`, '_blank', 'width=800,height=600');
                  }}
                >
                  View Document
                </a>
              </div>
              <div style={{ marginLeft: '5px' }}>
                <FloatingTooltip styles={{ fontSize: '11px' }} title="Have Cal reparse the document">
                  <IconButton
                    aria-label="thumbs up"
                    onClick={() => {
                      setEntryPrepDocForReparseModal(entryPrepDoc);
                      setReparseModalOpen(true);
                    }}
                    sx={{ fontSize: 13, padding: '0px', marginBottom: '2px' }}
                  >
                    <ReplayIcon sx={{ stroke: 'currentColor', strokeWidth: 1 }} fontSize="inherit" />
                  </IconButton>
                </FloatingTooltip>
              </div>
            </div>
          </div>
          {documentReviewMode === DocumentReviewMode.WITH_SELECTION && (
            <div style={{ display: 'flex', marginLeft: '5px' }}>
              <Checkbox
                size="small"
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '16px', // Adjust the icon size to make it smaller
                  },
                  padding: '2px', // Reduce padding around the checkbox
                }}
                checked={entryPrepDocsSelections![index] || false}
                onChange={(e) => {
                  e.preventDefault(); // Prevent the onClick from the label
                  const newSelections = [...entryPrepDocsSelections!];
                  newSelections[index] = e.target.checked;
                  setEntryPrepDocsSelections!(newSelections);
                }}
              />
            </div>
          )}
        </div>
      ),
      element: renderViewBasedOnDocumentType(entryPrepDoc),
    }));
  }, [entryPrepDocs, entryPrepDocsSelections, setEntryPrepDocsSelections, onEntryPrepDocUpdated]);

  return (
    <>
      <ImportalVerticalTabs tabsConfig={tabsConfig} inCard={true} selectedTabIndex={selectedTabIndex} />

      <ImportalCalFeedbackModal
        open={reparseModalOpen}
        onClose={handleCloseFeedbackModal}
        onSubmit={reparseEntryPrepDoc}
      />
    </>
  );
}
