import React, { useContext, useState } from 'react';
import ImportalStickySidebarMenu, {
  ImportalStickySidebarStep,
} from '@/shared-components/ImportalStickySidebarMenu/ImportalStickySidebarMenu';
import { Entry, EntryFilingStatus } from 'common/interfaces/entry';
import EntryHeaderInfoCard from '../entries/EntryHeaderInfo';
import EntryManifestBolInfoCard from '../entries/EntryManifestBolInfo';
import EntryInvoiceView from '@/broker-app/pages/shipments/EntryInvoiceView';
import { useAPI } from '@/api/APIContext';
import ImportalMenuButton, { ImportalMenuButtonItem } from '@/shared-components/ImportalMenuButton';
import IncludedEntryPrepDocsPreview from '@/broker-app/pages/shipments/IncludedEntryPrepDocsPreview';
import { EntryPrepTabContext } from '@/broker-app/pages/shipments/EntryPrepTab';
import EntryPrepSendToNETCHB from '@/broker-app/pages/shipments/EntryPrepSendToNETCHB';

import './EntryPrepApplicationFiling.css';
import ImportalCard from '@/shared-components/ImportalCard';
import ImportalSecondaryButton from '@/shared-components/ImportalSecondaryButton/ImportalSecondaryButton';
import { IconButton, Tooltip } from '@mui/material';
import { Info, Notes, Storm, Sync } from '@mui/icons-material';
import EntryNotesModal from '@/broker-app/pages/shipments/EntryNotesModal';

interface EntryPrepApplicationFilingProps {
  entry: Entry;
  setEntry: (arg0: Entry) => void;
  onClickChangeDocuments: () => void;
}

export default function EntryPrepApplicationFiling({
  entry,
  setEntry,
  onClickChangeDocuments,
}: EntryPrepApplicationFilingProps) {
  const api = useAPI();

  const { refreshEntriesForShipment } = useContext(EntryPrepTabContext);
  const [showSendToNETCHBModal, setShowSendToNETCHBModal] = useState(false);
  const [showEntryNotesModal, setShowEntryNotesModal] = useState(false);

  // hmm, should this step store a local version of the Entry? -- to hold under edit?
  const onEntryChanged = (entry: Entry) => {
    const entryId = entry._id?.toString();
    if (!entryId) {
      console.error('no entry id');
    }
    api
      .updateEntry(entryId!, entry)
      .then(({ data }) => {
        setEntry(data);
        refreshEntriesForShipment();
      })
      .catch((err) => {
        console.error('error updating entry');
      });
  };

  const onClickSyncShipmentDatapoints = () => {
    const entryId = entry._id?.toString();
    if (!entryId) {
      console.error('no entry id');
    }
    api
      .syncShipmentDataToEntry(entryId!)
      .then(({ data: entry }) => {
        console.log('synced shipment datapoints to entry', entry);
        setEntry(entry);
        refreshEntriesForShipment();
      })
      .catch((err) => {
        console.error('error syncing shipment datapoints', err);
      });
  };

  const onClickContinueWithoutDocuments = () => {
    const entryId = entry._id?.toString();
    if (!entryId) {
      console.error('no entry id');
    }
    api
      .createEmptyEntryInvoice(entryId!)
      .then(({ data: entry }) => {
        console.log('synced shipment datapoints to entry', entry);
        setEntry(entry);
        refreshEntriesForShipment();
      })
      .catch((err) => {
        console.error('error syncing shipment datapoints', err);
      });
  };

  const [entryPrepApplicationFilingSteps, setEntryPrepApplicationFilingSteps] = useState<ImportalStickySidebarStep[]>([
    { id: 1, name: 'Header Info', completed: false, selected: false },
    { id: 2, name: 'Manifest/BOL Info', completed: false, selected: false },
    { id: 3, name: 'Supplier/Manufacturer Info', completed: false, selected: false },
    { id: 4, name: 'Invoice/Entry Lines', completed: false, selected: false },
  ]);

  const entryActions: ImportalMenuButtonItem[] = [
    {
      text: 'Send Entry to NETCHB',
      onClick: () => setShowSendToNETCHBModal(true),
      disabled: entry.entryFilingStatus === EntryFilingStatus.SENT_TO_NETCHB,
    },
    {
      text: 'Add / Edit Documents',
      onClick: onClickChangeDocuments,
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
        <Tooltip title={'Validations'}>
          <IconButton onClick={onClickSyncShipmentDatapoints}>
            <Info />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Sync Shipment Datapoints'}>
          <IconButton onClick={onClickSyncShipmentDatapoints}>
            <Sync />
          </IconButton>
        </Tooltip>
        <Tooltip title={'View Notes'}>
          <IconButton
            onClick={() => {
              setShowEntryNotesModal(true);
            }}
          >
            <Notes />
          </IconButton>
        </Tooltip>
        <ImportalMenuButton buttonText={'Actions'} items={entryActions} />
      </div>
      <div style={{ display: 'grid', columnGap: '8px', gridTemplateColumns: '250px 1fr' }}>
        <div style={{ gridColumn: '1', display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <ImportalStickySidebarMenu
            steps={entryPrepApplicationFilingSteps}
            onStepsChanged={setEntryPrepApplicationFilingSteps}
          />
          <IncludedEntryPrepDocsPreview
            entryPrepDocs={entry.includedEntryPrepDocs}
            onClickChangeDocuments={onClickChangeDocuments}
          />
        </div>
        <div
          className={'entry-prep-main-section-container'}
          style={{ height: '900px', gridColumn: '2', overflowY: 'scroll' }}
        >
          <EntryHeaderInfoCard
            entryHeaderInfo={entry.headerInfo}
            onEntryHeaderInfoChanged={(entryHeaderInfo) => {
              onEntryChanged({ ...entry, headerInfo: entryHeaderInfo });
            }}
          />

          <EntryManifestBolInfoCard
            entryManifestBolInfo={entry.manifestBolInfo}
            onEntryManifestBOLInfoChanged={(manifestBolInfo) => {
              onEntryChanged({ ...entry, manifestBolInfo });
            }}
          />

          {entry.entryFilingStatus === EntryFilingStatus.PREPARING_DOCUMENTS ? (
            <ImportalCard title={'Entry Invoice'}>
              <div
                style={{
                  height: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    verticalAlign: 'middle',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <ImportalSecondaryButton
                    style={{ width: '200px' }}
                    onClick={onClickChangeDocuments}
                    text={'Add Documents'}
                  />
                  <div style={{ alignSelf: 'center' }}>or</div>
                  <ImportalSecondaryButton
                    style={{ width: '200px' }}
                    onClick={onClickContinueWithoutDocuments}
                    text={'Continue without'}
                  />
                </div>
              </div>
            </ImportalCard>
          ) : (
            <EntryInvoiceView
              entryInvoice={entry.invoice}
              onEntryInvoiceChanged={(invoice) => {
                onEntryChanged({ ...entry, invoice });
              }}
            />
          )}
        </div>
      </div>
      <EntryPrepSendToNETCHB
        open={showSendToNETCHBModal}
        onClose={() => {
          setShowSendToNETCHBModal(false);
        }}
        entry={entry}
        onEntryAccepted={(entry) => {
          setEntry(entry);
          refreshEntriesForShipment();
        }}
        onEntryRejected={() => {}}
      ></EntryPrepSendToNETCHB>
      <EntryNotesModal
        open={showEntryNotesModal}
        onClose={() => {
          setShowEntryNotesModal(false);
        }}
        entryNotes={entry.brokerNotes}
        onEntryNotesChanged={(brokerNotes) => {
          onEntryChanged({ ...entry, brokerNotes });
        }}
      ></EntryNotesModal>
    </>
  );
}
