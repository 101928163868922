import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { Card } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import WelcomeHeader from '@/shared-components/WelcomeHeader/WelcomeHeader';
import Typography from '@mui/material/Typography';
import SignUpFormFields from './SignUpFormFields';
import { formatPhoneNumber } from '@/utils/formatPhoneNumber';
import { formatBusinessSignUpWelcomeString } from '@/utils/formatBusinessSignUpWelcomeString';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '@/api/APIContext';
import { useActiveBusiness } from '@/custom-hooks/business/BusinessProvider';
import { handleFormChange, handlePhoneNumberChange, handleTermsConditionsChange, registerUser } from './formHandlers';

export default function BusinessSignUp() {
  const { business, users, refreshBusiness, refreshUsers } = useActiveBusiness();
  const api = useAPI();
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [termsConditions, setTermsConditions] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailError, setEmailError] = useState('');
  const [hidePassword, setHidePassword] = useState(true);

  const navigate = useNavigate();

  const [businessSignupWelcomeMessage, setBusinessSignupWelcomeMessage] = useState<string>('');

  useEffect(() => {
    if (!business) {
      console.error('on BusinessSignUp page with no business');
      return;
    }
    setBusinessSignupWelcomeMessage(formatBusinessSignUpWelcomeString(users || [], business?.name));
  }, [business, users]);

  useEffect(() => {
    if (!business) {
      refreshBusiness();
    } else if (!users) {
      refreshUsers();
    } else {
      setIsLoading(false);
    }
  }, [business, users, refreshBusiness, refreshUsers]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userToSubmit = {
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      phone: formatPhoneNumber(data.get('phone')),
      password: data.get('password'),
    };

    registerUser(api, userToSubmit, setIsLoading, setErrorText, navigate);
  };

  const handleLoading = (value) => {
    setIsLoading(value);
  };

  return (
    <div>
      <>
        <Container sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <img src="/IMPORTAL-2.png" height="90" width="140" alt="logo" />
        </Container>
        <div className="card-container-signup">
          <Card sx={{ padding: '32px', margin: '0 auto' }}>
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <WelcomeHeader className="middle">
                <Typography variant="h4">{businessSignupWelcomeMessage}</Typography>
              </WelcomeHeader>

              <SignUpFormFields
                handleFormChange={(event) => handleFormChange(event, setEmailError)}
                handleSubmit={handleSubmit}
                handlePhoneNumberChange={(event) => handlePhoneNumberChange(event, setPhoneNumber)}
                handleTermsConditionsChange={(event) => handleTermsConditionsChange(event, setTermsConditions)}
                setHidePassword={setHidePassword}
                hidePassword={hidePassword}
                phoneNumber={phoneNumber}
                emailError={emailError}
                isLoading={isLoading}
                termsConditions={termsConditions}
                errorText={errorText}
              />
            </Box>
          </Card>
        </div>
      </>
    </div>
  );
}
