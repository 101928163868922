import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import { FeatureName } from 'common/interfaces/plan';
import ImportalLinearProgress from '@/shared-components/ImportalLinearProgress/ImportalLinearProgress';

export default function SearchCountProgress() {
  const activePlan = useActivePlan();
  const [usage, setUsage] = useState<number>(0);

  useEffect(() => {
    if (activePlan.metadata) {
      const searchesFeatureUsage = activePlan.metadata.planUsage.find(
        (feature) => feature.featureName === FeatureName.SEARCHES
      );
      setUsage(searchesFeatureUsage?.usage ?? 0);
    } else {
      setUsage(0);
    }
  }, [activePlan.metadata]);

  const renderLinearValue = (searchCount: number) => {
    if (searchCount <= 2) {
      return searchCount * 50; // 0, 50, 100
    }
    return 100;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ImportalLinearProgress value={renderLinearValue(usage)} />
    </Box>
  );
}
