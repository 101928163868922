import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { RoundedTextfield } from '@/components/common-components/RoundedTextfield';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import StripeInput from './StripeInput';
import * as ls from 'local-storage';
import { useAPI } from '@/api/APIContext';
import CountryDropdown from '@/shared-components/CountryDropDown/CountryDropDown';

type AddNewCardProps = {
  getCardDetails: any;
  cardDetails: any;
  setNewCard: any;
  handleLoading: any;
  isLoading: any;
  handleClose: any;
  couponCode?: string;
  setCouponCode?: (value: string) => void;
  setDiscount?: (value: number) => void;
};

const AddNewCard = ({
  getCardDetails,
  cardDetails,
  setNewCard,
  handleLoading,
  isLoading,
  handleClose,
  couponCode,
  setCouponCode,
  setDiscount,
}: AddNewCardProps): React.JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const api = useAPI();

  const zipRegex = /^[0-9]{5,6}(?:-[0-9]{4})?$/;

  const [errorMessage, setErrorMessage] = useState({
    cardExpiry: '',
    cardCvc: '',
    cardNumber: '',
    country: '',
    zip: '',
  });
  const [address, setAddress] = useState({
    country: '',
    zip: '',
  });
  const [couponError, setCouponError] = useState('');

  const handleElementChange = ({ elementType, error }: any) => {
    if (error) {
      setErrorMessage((prev) => {
        return { ...prev, [elementType]: error.message };
      });
    } else {
      setErrorMessage((prev) => {
        return { ...prev, [elementType]: '' };
      });
    }
  };

  const handleCouponCodeChange = (event: any) => {
    if (setCouponCode) {
      setCouponCode(event.target.value);
    }
    setCouponError('');
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // form submission until Stripe.js has loaded.
      return;
      // Stripe.js has not loaded yet. Make sure to disable
    }
    if (!address.zip.length) {
      setErrorMessage((prev) => {
        return { ...prev, zip: 'Zip is required' };
      });
    }
    if (!address.country || address.country.length !== 2)
      setErrorMessage((prev) => {
        return { ...prev, country: 'Country is required' };
      });
    // coupon handling
    if (couponCode) {
      try {
        const response = await api.validateCoupon(couponCode);

        if (response.data && response.data.discount !== undefined) {
          setCouponError('');
          if (setDiscount) {
            setDiscount(response.data.discount);

            localStorage.setItem('discount', JSON.stringify(response.data.discount));
          }
        }
      } catch (error) {
        const axiosError = error as any;
        if (axiosError.response && axiosError.response.status === 400) {
          setCouponError(`Invalid coupon code ${couponCode}`);
        }
        console.error('Coupon validation failed:', error);
        return;
      }
    }

    const users: any = ls.get('user');

    const cardNumber = elements.getElement(CardNumberElement);

    if (!cardNumber) {
      console.error('CardNumberElement is not available.');
      return;
    }

    // Attempt to create a payment method
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        name: `${users.firstName} ${users.lastName}`,
        address: {
          country: address.country,
          postal_code: address.zip,
        },
      },
    });

    // Check for success or failure in the payload
    if (payload.error) {
      console.error('Error creating payment method:', payload.error.message);
      return; // Stop further execution if there's an error
    }
    const data = {
      paymentMethodId: payload?.paymentMethod?.id,
      isDefault: cardDetails.length ? false : true,
    };
    payload && handleLoading(true);
    payload &&
      api
        .saveNewCardToPlan(data)
        .then(function (response) {
          getCardDetails();
          handleLoading(false);
          setNewCard(false);
          handleClose();
        })
        .catch(function (error) {
          console.error('error saving new card to plan');
          console.error(error);
          handleLoading(false);
        });
  };

  const handleAddressChange = (event: any) => {
    if (event.target.name === 'zip' && !zipRegex.test(event.target.value)) {
      setErrorMessage((prev) => {
        return { ...prev, [event.target.name]: `Zip code is invalid format!` };
      });
    } else if (!Boolean(event.target.value))
      setErrorMessage((prev) => {
        return {
          ...prev,
          [event.target.name]: `${[event.target.name]} is required`,
        };
      });
    else {
      setErrorMessage((prev) => {
        return { ...prev, [event.target.name]: '' };
      });
      setAddress((prev) => {
        return { ...prev, [event.target.name]: event.target.value };
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            height: '200px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" size={20} />
        </div>
      ) : (
        <>
          <div className="content-wrapper">
            <div className="card-content">
              <div className="input-custom">
                <RoundedTextfield
                  id="card"
                  required
                  label="Card Number"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      component: CardNumberElement,
                      handleElementChange: handleElementChange,
                    },
                    inputComponent: StripeInput,
                  }}
                  error={!!errorMessage.cardNumber}
                  helperText={!!errorMessage.cardNumber ? errorMessage.cardNumber || 'Invalid' : ''}
                />
              </div>
              <div className="input-custom">
                <RoundedTextfield
                  id="expire"
                  required
                  label="Expiration"
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      component: CardExpiryElement,
                      handleElementChange: handleElementChange,
                    },
                    inputComponent: StripeInput,
                  }}
                  error={!!errorMessage.cardExpiry}
                  helperText={!!errorMessage.cardExpiry ? errorMessage.cardExpiry || 'Invalid' : ''}
                />
              </div>
              <div className="input-custom">
                <RoundedTextfield
                  id="cvc"
                  label="CVC"
                  required
                  variant="filled"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      component: CardCvcElement,
                      handleElementChange: handleElementChange,
                    },
                    inputComponent: StripeInput,
                  }}
                  error={!!errorMessage.cardCvc}
                  helperText={!!errorMessage.cardCvc ? errorMessage.cardCvc || 'Invalid' : ''}
                />
              </div>
            </div>
            <div className="card-content">
              <CountryDropdown countryError={errorMessage.country} handleAddressChange={handleAddressChange} />
              <RoundedTextfield
                id="zip"
                label="ZIP"
                type="text"
                name="zip"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleAddressChange}
                error={!!errorMessage.zip}
                helperText={!!errorMessage.zip ? errorMessage.zip || 'Invalid' : ''}
              />
            </div>
          </div>
          <div className="card-content">
            <RoundedTextfield
              id="coupon"
              label="Coupon code"
              type="text"
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Enter coupon code"
              value={couponCode}
              onChange={handleCouponCodeChange}
              error={!!couponError}
              helperText={couponError ? couponError : ''}
            />
          </div>
          <div className="card-bottom-pay">
            <div className="next-button-container">
              <Button
                type="submit"
                color="info"
                sx={{ marginTop: '-36px!important', marginBottom: '16px' }}
                className="next-button-green"
                variant="contained"
                disabled={!stripe || Boolean(Object.values(errorMessage).join('')) || isLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export default AddNewCard;
