import React from 'react';
import * as d3 from 'd3';

interface NumericXAxisProps {
  axisLabel: string;
  range: [number, number];
  width: number;
  showAxisLine?: boolean;
  showAxisTicks?: boolean;
}

export const NumericXAxis = ({
  axisLabel,
  range,
  width,
  showAxisLine = true, // Default to true
  showAxisTicks = false,
}: NumericXAxisProps) => {
  const ticks = d3.scaleLinear().domain(range).range([0, width]).ticks(5);

  return (
    <>
      {showAxisLine && <path d={`M0,0 H${width}`} fill="none" stroke="#E0E0E0" />}
      {ticks.map((tick) => (
        <g key={tick} transform={`translate(${d3.scaleLinear().domain(range).range([0, width])(tick)}, 0)`}>
          {showAxisTicks && <line y2={6} stroke="#E0E0E0" />}
          <text fill="#525256" style={{ fontSize: '13px', textAnchor: 'middle' }} y={20}>
            {tick}
          </text>
        </g>
      ))}
      <text fill="#525256" x={width / 2} y={50} textAnchor="middle" style={{ fontSize: '14px', fontWeight: '600' }}>
        {axisLabel}
      </text>
    </>
  );
};
