import React from 'react';
import * as d3 from 'd3';

interface ImportalHorizontalBarsProps {
  data: any[];
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleBand<string>;
  seriesNameKey: string;
  seriesValueKey: string;
  barColor?: string;
}

export function ImportalHorizontalBars({
  data,
  xScale,
  yScale,
  seriesNameKey,
  seriesValueKey,
  barColor = '#FFC107', // Default bar color
}: ImportalHorizontalBarsProps) {
  return (
    <g>
      {data.map((d: any, i: number) => {
        const cat = d[seriesNameKey];
        const val = +d[seriesValueKey];
        const barY = yScale(cat) ?? 0;
        const barWidth = xScale(val) ?? 0;

        return (
          <rect
            key={i}
            x={0}
            y={barY}
            width={barWidth}
            height={yScale.bandwidth()}
            fill={barColor}
            rx={8} // Increase border radius
          >
            <title>{`${cat}: ${val}`}</title>
          </rect>
        );
      })}
    </g>
  );
}
