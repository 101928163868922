import { Box, Card, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';
import {
  EntryPrepDoc,
  EntryPrepDocForDisplay,
  EntryPrepDocReviewStatus,
  reviewStatusToDescription,
} from 'common/interfaces/documentParsing';
import { Settings } from '@mui/icons-material';

interface Props {
  children: ReactNode;
  entryPrepDoc: EntryPrepDocForDisplay;
  onEntryPrepDocChanged: (arg0: EntryPrepDocForDisplay) => void;
}
export default function EntryPrepDocContainer({ children, entryPrepDoc, onEntryPrepDocChanged }: Props) {
  const styles = {
    parsedViewContainer: {
      alignItems: 'center',
      boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
      borderRadius: '8px',
      color: '#525256',
      padding: '24px',
    },
    parsedViewHeader: {
      marginBottom: '2px',
      marginTop: '0px',
      color: 'black',
      display: 'flex',
      justifyContent: 'center',
    },
    parsedViewSubheader: {
      fontSize: '12px',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '24px',
      color: '#525256',
    },
    parsedViewSubheaderContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    parsedViewSummarySection: {
      display: 'flex',
      width: '100%',
      fontSize: '12px',
      marginTop: '16px',
      gap: '24px',
    },
    parsedViewSummarySectionColumn: {
      flex: 1,
      position: 'relative' as 'relative',
    },
    parsedViewSummarySectionColumnHeader: {
      fontWeight: 'bold',
      color: 'black',
    },
    fieldContainer: {
      fontSize: '12px',
      color: '#525256',
      position: 'relative' as 'relative',
      display: 'inline-block',
    },
    importerAddressSpan: {
      marginRight: '3px',
      whiteSpace: 'nowrap',
    },
    editIconButton: {
      position: 'absolute' as 'absolute',
      right: '-20px',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '16px',
    },
  };

  return (
    <>
      <Card style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            right: 16,
            top: 16,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TextField
            variant="standard"
            select
            InputProps={{ style: { fontSize: 12, zIndex: 1 } }}
            value={entryPrepDoc.reviewStatus}
            onChange={(e) => {
              onEntryPrepDocChanged({ ...entryPrepDoc, reviewStatus: EntryPrepDocReviewStatus[e.target.value] });
            }}
            size={'small'}
          >
            {Object.keys(EntryPrepDocReviewStatus).map((value, index) => {
              return (
                <MenuItem value={value} key={index}>
                  {reviewStatusToDescription[value]}
                </MenuItem>
              );
            })}
          </TextField>
          {/*TODO, view stats is where you can see the amount of manual 'fixing' that the parsed doc needed*/}
          {/*<Tooltip title={'View Stats'}>*/}
          {/*  <IconButton>*/}
          {/*    <Settings />*/}
          {/*  </IconButton>*/}
          {/*</Tooltip>*/}
        </div>

        <Box style={styles.parsedViewContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              color: 'black',
              gap: '8px',
              marginBottom: '24px',
              position: 'relative',
            }}
          >
            {children}
          </div>
        </Box>
      </Card>
    </>
  );
}
