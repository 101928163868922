import { Box, Button, CircularProgress, MenuItem, Select, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplayIcon from '@mui/icons-material/Replay';
import { ColumnMapping } from 'common/interfaces/stagingCodes';

type DropDownColumns = {
  currentColumns: ColumnMapping[];
  sourceOptions: string[];
  onMappingChange: (index: number, newMapping: ColumnMapping) => void;
};

type DropDown = {
  value: string;
  options: string[];
  handleChange: (newColumnName: string | null) => void;
};

type Row = {
  sourceTargetMap: { source: string; target: string };
  sourceOptions: string[];
  index: number;
  onMappingChange: (index: number, updatedMapping: { source: string; target: string }) => void;
};

function ColumnDropDown({ value, options, handleChange }: DropDown): React.JSX.Element {
  return (
    <Select
      value={value === null ? '' : value}
      onChange={(e) => handleChange(e.target.value || null)}
      sx={{ width: '200px', marginRight: '8px' }}
    >
      {options.map(
        (option): React.JSX.Element => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        )
      )}
      <MenuItem value="">No Column</MenuItem>
    </Select>
  );
}

function StaticTextBox({ value }): React.JSX.Element {
  return (
    <Box
      sx={{
        marginRight: '8px',
        width: '200px',
        border: '2px solid green',
        height: '50px',
        borderRadius: '5px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography key={value} sx={{ fontSize: '1rem' }}>
        {value}
      </Typography>
    </Box>
  );
}

function ColumnMappingRow({ sourceTargetMap, sourceOptions, index, onMappingChange }: Row): React.JSX.Element {
  const handleMappingChange = (key: keyof typeof sourceTargetMap, newValue: string | null) => {
    onMappingChange(index, { ...sourceTargetMap, [key]: newValue });
  };
  return (
    <Box key={index} sx={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: 1, textAlign: 'left' }}>
        <StaticTextBox value={sourceTargetMap.target}></StaticTextBox>
      </Box>
      <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ArrowBackIcon
          sx={{
            // marginX: '8px',
            fontSize: '2rem', // Adjust size
            transform: 'scaleX(2)', // Stretch the arrow horizontally
          }}
        />
      </Box>
      <Box sx={{ flex: 1, textAlign: 'right' }}>
        <ColumnDropDown
          value={sourceTargetMap.source}
          options={sourceOptions}
          handleChange={(newValue) => handleMappingChange('source', newValue)}
        ></ColumnDropDown>
      </Box>
    </Box>
  );
}

export function ColumnMappingComponent({
  currentColumns,
  sourceOptions,
  onMappingChange,
}: DropDownColumns): React.JSX.Element {
  return (
    <div>
      {currentColumns.map((sourceTargetMap, index) => (
        <ColumnMappingRow
          sourceTargetMap={sourceTargetMap}
          sourceOptions={sourceOptions}
          index={index}
          onMappingChange={onMappingChange}
        ></ColumnMappingRow>
      ))}
    </div>
  );
}

interface ImportalColumnMappingProps {
  columnMapping: ColumnMapping[];
  setColumnMapping: React.Dispatch<React.SetStateAction<ColumnMapping[]>>;
  loading: boolean;
  header: string[];
  filename: string;
  handleColumnMappings: (header: string[], fileName: string) => Promise<void>;
}

export default function ImportalColumnMapping({
  columnMapping,
  setColumnMapping,
  loading,
  header,
  handleColumnMappings,
  filename,
}: ImportalColumnMappingProps): React.JSX.Element {
  const theme = useTheme();
  const handleMappingChange = (index: number, updatedMapping: ColumnMapping) => {
    setColumnMapping((prev) => prev.map((colMap, idx) => (index === idx ? updatedMapping : colMap)));
  };

  return (
    <Box>
      <Button onClick={() => handleColumnMappings(header, filename)} variant="outlined">
        {loading ? <CircularProgress size={20} /> : <ReplayIcon />}
      </Button>
      <ColumnMappingComponent
        currentColumns={columnMapping}
        sourceOptions={header}
        onMappingChange={(index, updatedMapping) => handleMappingChange(index, updatedMapping)}
      />
    </Box>
  );
}
