import { Code } from 'common/interfaces/code';
import { useState } from 'react';
import HTSCode from '@/shared-components/compliance-tools/HTSCode';
import QuantityInfo, { QuantityTotals } from '@/shared-components/compliance-tools/QuantityTotals';
import WeightInfo from '@/shared-components/compliance-tools/WeightInfo';

interface Props {
  htsCode?: Code;
  setHTSCode: (htsCode?: Code) => void;
  totalWeight?: string;
  onTotalWeightSelected?: (totalWeight?: string) => void;
  quantityInfo?: QuantityTotals;
  onQuantityInfoSelected?: (quantityInfo?: QuantityTotals) => void;
  onNext: () => void;
  onBack?: () => void;
}

export default function FirstStepContainer({
  htsCode,
  setHTSCode,
  totalWeight,
  onTotalWeightSelected,
  quantityInfo,
  onQuantityInfoSelected,
  onNext,
}: Props) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      onNext();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onHTSCodeSelected = (code: Code) => {
    setHTSCode(code);
  };

  const steps: React.JSX.Element[] = [<HTSCode htsCode={htsCode} onNext={handleNext} setHTSCode={onHTSCodeSelected} />];
  if (htsCode && htsCode.weightDataRequired) {
    steps.push(
      <WeightInfo
        totalWeight={totalWeight || ''}
        onTotalWeightSelected={(totalWeight?: string) => {
          onTotalWeightSelected?.(totalWeight);
        }}
        onNext={handleNext}
        onBack={handleBack}
      />
    );
  }
  if (htsCode && htsCode.quantityDataRequired) {
    steps.push(
      <QuantityInfo
        quantityInfo={quantityInfo!}
        onQuantityInfoSelected={(quantityInfo?: QuantityTotals) => {
          onQuantityInfoSelected?.(quantityInfo);
        }}
        onNext={handleNext}
        onBack={handleBack}
      />
    );
  }

  return steps[activeStep];
}
