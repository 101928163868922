import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

import { Note } from 'common/interfaces/entry';
import { useAPI } from '@/api/APIContext';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';

interface Props {
  open: boolean;
  onClose: () => void;
  entryNotes: Note[];
  onEntryNotesChanged: (updatedNotes: Note[]) => void;
}

export default function EntryNotesModal({ open, onClose, entryNotes, onEntryNotesChanged }: Props) {
  const api = useAPI();
  const [loading, setLoading] = useState(false);

  // Keep local copy of notes in state. When entryNotes changes externally, sync it in.
  const [notes, setNotes] = useState<Note[]>(entryNotes);
  const [newNoteContent, setNewNoteContent] = useState('');

  useEffect(() => {
    setNotes(entryNotes);
  }, [entryNotes]);

  // Add a new note to the local list
  const handleAddNote = async () => {
    if (!newNoteContent.trim()) return;
    setLoading(true);

    try {
      // Construct a new note object (adjust user info as appropriate)
      const newNote: Note = {
        timestamp: new Date().toISOString(),
        content: newNoteContent.trim(),
        messageType: undefined, // ignoring as requested
      };

      // Optionally call an API here, e.g.:
      // const response = await api.addNoteToEntry(entryId, newNote);
      // const savedNote = response.data;

      // For now, just add it locally:
      const updatedNotes = [...(notes || []), newNote];
      setNotes(updatedNotes);
      onEntryNotesChanged(updatedNotes);
      setNewNoteContent('');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Renders each note with the requested style:
  //   top left => "sender email, time" in smaller bolded font
  //   then margin, then content full-width
  const renderNotes = () => {
    return notes?.map((note, index) => {
      // If note.user is a fully populated object, use note.user.email;
      // if it is an ID, you can adjust accordingly.
      let senderEmail = 'Unknown User';

      const timestampStr = dayjs(note.timestamp).format('MMM D, YYYY h:mm A');

      return (
        <div key={note._id?.toString() || index} style={{ marginBottom: '16px' }}>
          <div style={{ fontWeight: 600, fontSize: '0.85rem' }}>
            {senderEmail}, {timestampStr}
          </div>
          <div style={{ marginTop: '8px', width: '100%' }}>{note.content}</div>
        </div>
      );
    });
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={(event, reason) => {
        // Prevent closing the modal via backdrop click
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
    >
      <DialogContent
        style={{
          // minWidth: 'min(1000px, calc(100vw - 400px))',
          // maxWidth: 'calc(100vw - 200px)',
          minHeight: 'min(600px, calc(100vh - 400px))',
          maxHeight: 'calc(100vh - 200px)',
          // display: 'flex',
          // flexDirection: 'column',
          // position: 'relative',
        }}
      >
        <div className="my-shipments-header">Entry Notes</div>
        {/* Close button in top-right */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        {/* Loading Indicator (optional) */}
        {loading && (
          <Box
            id="send-to-netchb-loading-indicator-container"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: '325px' }}
          >
            <CircularProgress id="send-to-netchb-loading-indicator" color="primary" />
          </Box>
        )}

        {/* Scrollable Notes */}
        {!loading && <div style={{ flex: 1, overflowY: 'auto', paddingTop: '32px' }}>{renderNotes()}</div>}
      </DialogContent>

      {/* Actions: text field to add note, plus "Add Note" and "Done" buttons */}
      <DialogActions sx={{ marginTop: '16px', justifyContent: 'flex-end' }}>
        <TextField
          value={newNoteContent}
          onChange={(e) => setNewNoteContent(e.target.value)}
          label="Add a note..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAddNote();
            }
          }}
          fullWidth
          style={{ marginRight: '8px' }}
        />
        <ImportalPrimaryButton onClick={handleAddNote} text="Add Note" style={{ width: '140px' }} />
      </DialogActions>
    </Dialog>
  );
}
