import { useMemo, useState } from 'react';
import * as d3 from 'd3';

interface Props {
  width: number;
  height: number;
  categories: { category: string; value: number }[];
  range: number[];
  highlightedValue?: string;
  /**
   * If present, these are the lines to draw.
   * e.g. [1000, 2000]. If not provided, no lines drawn or do something default.
   */
  customGridLines?: number[];
}

export const ImportalHistogramBars = ({
  width = 1,
  height = 1,
  categories = [],
  range = [10, 290],
  highlightedValue,
  customGridLines,
}: Props) => {
  const [hoveredBar, setHoveredBar] = useState<number | null>(null);

  // 1) Lines
  const yAxisTicks = useMemo(() => {
    if (!customGridLines) return []; // if no custom lines, return empty
    const yScale = d3.scaleLinear().domain(range).range([0, height]);
    return customGridLines.map((tick) => ({
      value: tick,
      yPos: height - yScale(tick),
    }));
  }, [height, range, customGridLines]);

  // 2) Bars
  const histoBars = useMemo(() => {
    const xScale = d3
      .scaleBand()
      .domain(categories.map((d) => d.category))
      .range([0, width])
      .padding(0.2);

    const yScale = d3.scaleLinear().domain(range).range([0, height]);
    const maxBarWidth = 50;

    return categories.map((d) => {
      const bandwidth = xScale.bandwidth();
      const clampedBarWidth = Math.min(bandwidth, maxBarWidth);
      const barXCenter = xScale(d.category)! + bandwidth / 2;

      return {
        barHeight: yScale(d.value),
        barWidth: clampedBarWidth,
        barX: barXCenter - clampedBarWidth / 2,
        barValue: d.value,
        isHighlighted: d.category === highlightedValue,
      };
    });
  }, [width, height, categories, range, highlightedValue]);

  return (
    <>
      {/** If we have lines, draw them */}
      {yAxisTicks.map((tick) => (
        <line
          key={tick.value}
          x1={0}
          y1={tick.yPos}
          x2={width}
          y2={tick.yPos}
          stroke="#E5E5EF"
          strokeDasharray="8,8"
          strokeWidth={2}
        />
      ))}

      {/** Bars */}
      {histoBars.map(({ barHeight, barWidth, barX, barValue, isHighlighted }, index) => (
        <g
          key={index}
          transform={`translate(${barX}, ${height - barHeight})`}
          onMouseEnter={() => setHoveredBar(index)}
          onMouseLeave={() => setHoveredBar(null)}
        >
          <path
            d={`
              M 0,${barHeight}
              L 0,12
              Q 0,0 12,0
              L ${barWidth - 12},0
              Q ${barWidth},0 ${barWidth},12
              L ${barWidth},${barHeight}
              Z
            `}
            fill={isHighlighted ? '#388e3c' : hoveredBar === index ? '#c0dcc2' : '#388e3c'}
          />
          {hoveredBar === index && (
            <g transform={`translate(${barWidth / 2}, -12)`}>
              <rect x={-30} y={-25} width={60} height={25} rx={4} fill="#666" />
              <text textAnchor="middle" y={-8} fontSize="12px" fill="white">
                {barValue.toLocaleString()}
              </text>
            </g>
          )}
        </g>
      ))}
    </>
  );
};
