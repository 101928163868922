import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import './ConsumerClassifications.css';
import ClassificationsTable from '@/pages/hts-classifications/ConsumerClassificationsTable';
import { useAPI } from '@/api/APIContext';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { Route, Routes } from 'react-router-dom';
import { ConsumerClassificationPage } from '@/pages/hts-classifications/ConsumerClassificationsPage';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import { Add } from '@mui/icons-material';
import HtsClassification from '@/components/hts-classification/HtsClassification';
import { useActiveCustomsOnboarding } from '@/custom-hooks/customs-onboarding/CustomsOnboardingProvider';
import LightTooltip from '@/components/tooltip-component/TooltipComponent';

export interface IConsumerClassificationsContext {
  refreshClassifications: () => void;
  loadingStatus?: LoadingStatus;
  loadingText?: string;
  successText?: string;
  errorText?: string;
  setLoadingStatus: (status: LoadingStatus) => void;
  setLoadingText: (text: string) => void;
  setSuccessText: (text: string) => void;
  setErrorText: (text: string) => void;
}

export const ConsumerClassificationsContext = createContext<IConsumerClassificationsContext>({
  refreshClassifications: () => {},
  loadingStatus: LoadingStatus.NOT_LOADING,
  loadingText: '',
  successText: '',
  errorText: '',
  setLoadingStatus: () => {},
  setLoadingText: () => {},
  setSuccessText: () => {},
  setErrorText: () => {},
});

export default function ConsumerClassifications() {
  const activeCustomsOnboarding = useActiveCustomsOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const api = useAPI();
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [openClassificationsRequestModal, setOpenClassificationsRequestModal] = useState(false);

  const handleHtsClassificationsClick = () => {
    setOpenClassificationsRequestModal(true);
  };

  const [classifications, setClassifications] = useState<any>([]);

  const handleHtsClassificationsClose = () => {
    refreshClassifications();
    setOpenClassificationsRequestModal(false);
  };

  const refreshClassifications = useCallback(() => {
    setIsLoading(true);
    api
      .getUserClassifications()
      .then(({ data }) => {
        setClassifications(data);
      })
      .catch(function (error) {
        console.error('error getting classifications for consumer table');
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    refreshClassifications();
  }, [refreshClassifications]);

  return (
    <>
      <ConsumerClassificationsContext.Provider
        value={{
          refreshClassifications,
          loadingStatus,
          loadingText,
          successText,
          errorText,
          setLoadingStatus,
          setLoadingText,
          setErrorText,
          setSuccessText,
        }}
      >
        <Routes>
          <Route
            index
            element={
              <>
                <div className="main-page-header">
                  <Typography sx={{ color: theme.palette.primary.main, fontSize: '26px' }}>
                    HTS Classifications
                  </Typography>
                </div>
                <div className="universal-subheader">
                  Keep track of your classification request every step of the way.
                </div>
                <div className="shipments-table-container">
                  <ClassificationsTable
                    rows={classifications}
                    isLoading={isLoading}
                    requestClassificationButton={
                      <LightTooltip
                        title={
                          'Finish the customs onboarding steps for your business in order to get access to HTS Classification'
                        }
                        disableHoverListener={activeCustomsOnboarding.hasCompletedOnboarding}
                      >
                        <div>
                          <ImportalPrimaryButton
                            style={{ width: '265px', borderRadius: '8px', fontSize: '14px' }}
                            disabled={!activeCustomsOnboarding.hasCompletedOnboarding}
                            onClick={handleHtsClassificationsClick}
                            text="Request HTS Classification"
                            endIcon={<Add />}
                          />
                        </div>
                      </LightTooltip>
                    }
                  ></ClassificationsTable>
                </div>
                {openClassificationsRequestModal && (
                  <HtsClassification open={openClassificationsRequestModal} onClose={handleHtsClassificationsClose} />
                )}
              </>
            }
          />
          <Route path="/*" element={<ConsumerClassificationPage />} />
        </Routes>
      </ConsumerClassificationsContext.Provider>
    </>
  );
}
