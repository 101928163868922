import React from 'react';
import { IconButton, TableCell } from '@material-ui/core';
import { Add, Check, Close } from '@mui/icons-material';
import './ActionIcons.css';

interface ActionIconsCellProps {
  index: number;
  handleCancelEditRow: (index: number, flag: boolean) => void;
  handleSaveLink: (index: number, flag: boolean) => void;
}

const ActionIcons: React.FC<ActionIconsCellProps> = ({ index, handleCancelEditRow, handleSaveLink }) => {
  return (
    <TableCell className="action-icons">
      <IconButton onClick={() => handleCancelEditRow(index, true)}>
        <Close />
      </IconButton>
      <IconButton onClick={() => handleSaveLink(index, true)}>
        <Check />
      </IconButton>
    </TableCell>
  );
};

export default ActionIcons;
