// ImportalAutocomplete.tsx
import React from 'react';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { ColumnConfig } from 'common/interfaces/importalTable';

interface ImportalAutocompleteProps {
  options: Array<ColumnConfig | string>;
  value: ColumnConfig | string | null;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: ColumnConfig | string | null) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}

const ImportalAutocomplete: React.FC<ImportalAutocompleteProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Select an option...',
  style = {},
}) => {
  return (
    <Paper
      sx={{
        // match ImportalFilter’s Paper styles
        borderRadius: '8px!important',
        minWidth: '250px',
        height: '40px',
        boxShadow: `
          0px 4px 8px 0px rgba(76, 103, 100, 0.02),
          0px -4px 8px 0px rgba(76, 103, 100, 0.02),
          4px 0px 8px 0px rgba(76, 103, 100, 0.02),
          -4px 0px 8px 0px rgba(76, 103, 100, 0.02) !important
        `,
        ...style, // merge any additional inline style
      }}
    >
      <Autocomplete
        options={options}
        getOptionLabel={(option: ColumnConfig | string) => {
          if (typeof option === 'string') return option;
          return option.displayName || option.name;
        }}
        value={value}
        onChange={onChange}
        componentsProps={{
          paper: {
            sx: {
              borderRadius: '8px !important',
              fontSize: '12px',
              boxShadow: `
                  0px 4px 8px 0px rgba(76, 103, 100, 0.02),
                  0px -4px 8px 0px rgba(76, 103, 100, 0.02),
                  4px 0px 8px 0px rgba(76, 103, 100, 0.02),
                  -4px 0px 8px 0px rgba(76, 103, 100, 0.02) !important
                `,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            fullWidth
            sx={{
              backgroundColor: 'transparent',
              '& .MuiOutlinedInput-root': {
                padding: '4px 4px 4px 8px !important',
                fontSize: '12px',
                '& fieldset': {
                  border: 'none',
                  borderRadius: '8px',
                },
              },
              '& .MuiInputBase-input': {
                fontSize: '12px',
              },
            }}
          />
        )}
      />
    </Paper>
  );
};

export default ImportalAutocomplete;
