import * as React from 'react';
import Box from '@mui/material/Box';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadHtsInfo from '../upload-hts-info/UploadHtsInfo';

const UploadHtsInfoModal = ({ open, handleClose }) => {
  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <UploadHtsInfo handleClose={handleClose} />
          </Box>
        </div>
      </Dialog>
    </div>
  );
};

export default UploadHtsInfoModal;
