import { FormEvent } from 'react';
import { formatPhoneNumber, formatPhoneNumberOnChange } from '@/utils/formatPhoneNumber';
import { isEmailValid } from 'common/utilities/emailValidator';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

export interface User {
  firstName: FormDataEntryValue | null;
  lastName: FormDataEntryValue | null;
  email: FormDataEntryValue | null;
  phone: string;
  password: FormDataEntryValue | null;
}

export const handleFormChange = (event: FormEvent<HTMLFormElement>, setEmailError: SetState<string>) => {
  const email = (event.currentTarget['email'] as HTMLInputElement)?.value;
  if (email && !isEmailValid(email)) {
    setEmailError('Invalid Email');
  } else {
    setEmailError('');
  }
};

export const handlePhoneNumberChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setPhoneNumber: SetState<string>
) => {
  const formattedPhoneNumber = formatPhoneNumberOnChange(event.target.value);
  setPhoneNumber(formattedPhoneNumber);
};

export const handleTermsConditionsChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setTermsConditions: SetState<boolean>
) => {
  setTermsConditions(!event.target.checked);
};

export const registerUser = async (
  api: any,
  user: User,
  setLoading: SetState<boolean>,
  setErrorText: SetState<string>,
  navigate: (path: string) => void
) => {
  setLoading(true);
  try {
    await api.registerUser(user);
    navigate('/authenticate-phone');
  } catch (error: any) {
    console.error('Error registering user', error);
    setErrorText(error.response?.data?.message || 'Unknown Error');
  } finally {
    setLoading(false);
  }
};
