import React, { useMemo } from 'react';
import * as d3 from 'd3';
import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import { CategoricalYAxis } from './CategoricalYAxis';
import { NumericXAxis } from './NumericXAxis';
import { ImportalVerticalBars } from './ImportalVerticalBars';
import { ImportalHorizontalBars } from './ImportalHorizontalBars';
import { BarChartOrientation, ChartConfig } from 'common/interfaces/reports';

interface Props {
  chartConfig: ChartConfig;
  data: any[];
}

export default function ImportalBarChart({ chartConfig, data }: Props) {
  const [ref, dms] = useChartDimensions({
    marginLeft: 100,
    marginRight: 40,
    marginTop: 20,
    marginBottom: 60,
  });

  const { seriesNameKey, seriesValueKey, xAxisName, yAxisName } = chartConfig.barConfig || {};

  const categories = useMemo(() => {
    if (!seriesNameKey) return [];
    return data.map((d) => d[seriesNameKey]);
  }, [data, seriesNameKey]);

  const values = useMemo(() => {
    if (!seriesValueKey) return [];
    return data.map((d) => +d[seriesValueKey]);
  }, [data, seriesValueKey]);

  const xScale = useMemo(() => {
    if (!seriesNameKey || !seriesValueKey) return null;
    if (chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL) {
      return d3.scaleBand<string>().domain(categories).range([0, dms.boundedWidth]).padding(0.2);
    } else {
      const maxValue = d3.max(values) || 0;
      return d3.scaleLinear().domain([0, maxValue]).range([0, dms.boundedWidth]).nice();
    }
  }, [chartConfig.barConfig?.orientation, categories, values, dms.boundedWidth, seriesNameKey, seriesValueKey]);

  const yScale = useMemo(() => {
    if (!seriesNameKey || !seriesValueKey) return null;
    if (chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL) {
      const maxValue = d3.max(values) || 0;
      return d3.scaleLinear().domain([0, maxValue]).range([dms.boundedHeight, 0]).nice();
    } else {
      return d3.scaleBand<string>().domain(categories).range([0, dms.boundedHeight]).padding(0.2);
    }
  }, [chartConfig.barConfig?.orientation, categories, values, dms.boundedHeight, seriesNameKey, seriesValueKey]);

  if (!seriesNameKey || !seriesValueKey) {
    return <div style={{ color: '#999' }}>Missing axis keys.</div>;
  }
  if (!xScale || !yScale) {
    return <div style={{ color: '#999' }}>Scales could not be computed.</div>;
  }

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        minWidth: '300px',
        minHeight: '300px',
      }}
    >
      <figure style={{ margin: 0, width: dms.width, height: dms.height }}>
        <svg width={dms.width} height={dms.height}>
          <g transform={`translate(${dms.marginLeft}, ${dms.marginTop})`}>
            {/* Background */}
            <rect width={dms.boundedWidth} height={dms.boundedHeight} fill="#fff" rx={8} />

            {/* Bars */}
            {chartConfig.barConfig?.orientation === BarChartOrientation.VERTICAL ? (
              <ImportalVerticalBars
                data={data}
                xScale={xScale}
                yScale={yScale}
                seriesNameKey={seriesNameKey}
                seriesValueKey={seriesValueKey}
                barColor={chartConfig.barConfig?.barColor}
              />
            ) : (
              <ImportalHorizontalBars
                data={data}
                xScale={xScale}
                yScale={yScale}
                seriesNameKey={seriesNameKey}
                seriesValueKey={seriesValueKey}
                barColor={chartConfig.barConfig?.barColor}
              />
            )}

            {/* Y Axis */}
            <CategoricalYAxis
              axisLabel={yAxisName || ''}
              categories={categories}
              height={dms.boundedHeight}
              showAxisLine={chartConfig.showAxisLine}
            />
          </g>

          {/* X Axis - positioned below the chart */}
          <g transform={`translate(${dms.marginLeft}, ${dms.marginTop + dms.boundedHeight})`}>
            <NumericXAxis
              axisLabel={xAxisName || ''}
              range={[0, d3.max(values)!]}
              width={dms.boundedWidth}
              showAxisLine={chartConfig.showAxisLine}
              showAxisTicks={chartConfig.showAxisTicks}
            />
          </g>
        </svg>
      </figure>
    </div>
  );
}
