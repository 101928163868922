import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ImportalTableDataType, SortDirection, TableConfig } from 'common/interfaces/importalTable';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatUserDate(dateString) {
  // Default to the user's local timezone if none is provided
  const timezone = dayjs.tz.guess();

  // Parse and format the date
  return dayjs.utc(dateString).tz(timezone).format('MMMM D, YYYY, h:mm A');
}

export interface ProgressData {
  progress: number;
  timeLeft: number;
}

export function handleUploadProgress(
  progressEvent: ProgressEvent,
  startTime: number,
  onProgress: (progressData: ProgressData) => void
) {
  if (progressEvent.total) {
    const uploaded = progressEvent.loaded;
    const total = progressEvent.total;
    const progress = Math.round((uploaded / total) * 100);

    const timeElapsed = (Date.now() - startTime) / 1000;
    const speed = uploaded / timeElapsed;
    const remainingBytes = total - uploaded;
    const timeLeft = Math.ceil(remainingBytes / speed);

    onProgress({
      progress,
      timeLeft,
    });
  }
}

export const stagingCodeSummaryTableConfig: TableConfig = {
  viewName: 'Staging Code Summary',

  columns: [
    {
      displayName: 'Date',
      name: 'createdAt',
      dataType: ImportalTableDataType.DATE_ISO,
      sortable: true,
      filterable: true,
      isVisible: true,
      transformer: (value) => formatUserDate(value),
    },
    {
      displayName: 'File Name',
      name: 'fileName',
      dataType: ImportalTableDataType.STRING,
      sortable: true,
      filterable: true,
      isVisible: true,
    },
    {
      displayName: 'Rows Changed',
      name: 'updatedRows',
      dataType: ImportalTableDataType.NUMBER,
      sortable: true,
      filterable: false,
      isVisible: true,
    },
    {
      displayName: 'Total Rows',
      name: 'totalRows',
      dataType: ImportalTableDataType.NUMBER,
      sortable: true,
      filterable: false,
      isVisible: true,
    },
    {
      displayName: 'Invalid Rows',
      name: 'invalidRows',
      dataType: ImportalTableDataType.NUMBER,
      sortable: true,
      filterable: false,
      isVisible: true,
    },
  ],
  sortConfig: [
    {
      columnName: 'createdAt',
      direction: SortDirection.desc,
      displayName: 'Date',
    },
  ],
  filterConfig: {},
};
