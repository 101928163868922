import { User } from 'common/interfaces/user';

const formatUserName = (user: User) => {
  const lastInitial = user.lastName ? user.lastName.charAt(0) : '';
  return `${user.firstName} ${lastInitial}.`;
};

export const formatBusinessSignUpWelcomeString = (users: User[], businessName: string) => {
  const formattedNames = users?.map(formatUserName) || [];

  if (formattedNames.length === 1) {
    return `Sign up below to join ${formattedNames[0]} in the ${businessName} organization within Importal`;
  } else if (formattedNames.length === 2) {
    return `Sign up below to join ${formattedNames[0]} and ${formattedNames[1]} in the ${businessName} organization within Importal`;
  } else if (formattedNames.length > 2) {
    return `Sign up below to join ${formattedNames[0]}, ${formattedNames[1]}, and other colleagues in the ${businessName} organization within Importal`;
  }

  return `Welcome to Importal! We're excited to have you here in the ${businessName} organization.`;
};
