import * as React from 'react';
import { useRef, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import ComplianceSummary from '@/components/compliance-tools/ComplianceSummary';
import CallToActionSnackbar from '@/components/call-to-action-snackbar/CallToActionSnackbar';
import { useActivePlan } from '@/custom-hooks/plan/PlanProvider';
import DataImportLoader, { LoadingState, LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { ImportalStepper, ImportalStepperHandle } from '@/shared-components/ImportalStepper/ImportalStepper';
import { ComplianceSummaryContext, CurrencyCode, Input, WeightUnit } from 'common/interfaces/complianceSummary';
import { useAPI } from '@/api/APIContext';
import { ComplianceSummaryRoute, TransportationType } from '@/api';
import FirstStepContainer from '@/shared-components/compliance-tools/FirstStepContainer';
import CargoValue from '@/shared-components/compliance-tools/CargoValue';
import CountryOfOrigin from '@/shared-components/compliance-tools/CountryOfOrigin';
import ModeOfTransportation from '@/shared-components/compliance-tools/ModeOfTransportation';
import { Code } from 'common/interfaces/code';
import { QuantityTotals } from '@/shared-components/compliance-tools/QuantityTotals';
import { getUnitOfMeasureForCode, quantityDataRequiredForDuty } from 'common/utilities/quantityDataRequiredForDuty';
import { AxiosError } from 'axios';
import LegalText from '@/shared-components/LegalText/LegalText';

export default function ComplianceOne() {
  const api = useAPI();
  const theme = useTheme();

  const activePlan = useActivePlan();

  const [loadingState, setLoadingState] = useState<LoadingState>({ loadingStatus: LoadingStatus.NOT_LOADING });
  const [showUpgradeBanner, setShowUpgradeBanner] = useState(activePlan.isFreePlan);

  const [htsCode, setHTSCode] = useState<Code | undefined>();
  const [complianceSummaryInput, setComplianceSummaryInput] = useState<Input>({ htsno: '', countryOfOrigin: '' });
  const [complianceSummary, setComplianceSummary] = useState<ComplianceSummaryContext | undefined>();

  const stepperRef = useRef<ImportalStepperHandle>(null);

  const handleCloseCallToAction = () => {
    setShowUpgradeBanner(false);
  };

  const handleReset = () => {
    setHTSCode(undefined);
    setComplianceSummaryInput({ htsno: '', countryOfOrigin: '' });
    setComplianceSummary(undefined);

    stepperRef.current?.resetStepper();
  };

  const doComplianceSearch = async (onSuccess: () => void) => {
    try {
      setLoadingState({ loadingStatus: LoadingStatus.LOADING });
      const { data } = await api.getComplianceSummary(ComplianceSummaryRoute.COMPLIANCE_ONE, complianceSummaryInput);
      setComplianceSummary(data);
      setLoadingState({ loadingStatus: LoadingStatus.SUCCESS });
      onSuccess();
    } catch (err) {
      console.error('error getting compliance summary');
      console.error(err);
      let axiosError = err as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        const responseData = axiosError.response.data as any;
        console.error(responseData);
        if (responseData.errors && responseData.errors.length > 0) {
          setLoadingState({
            loadingStatus: LoadingStatus.ERROR,
            error: responseData.errors[0],
          });
        }
        if (responseData.error) {
          setLoadingState({
            loadingStatus: LoadingStatus.ERROR,
            error: responseData.error,
          });
        }
      } else {
        setLoadingState({
          loadingStatus: LoadingStatus.ERROR,
        });
      }
    }
  };

  return (
    <div className="content-step-wrapper">
      <div className="main-step-content">
        <div className="main-page-header">
          <Typography sx={{ color: theme.palette.primary.main, fontSize: '26px' }}>Compliance One</Typography>
        </div>
        <div className="universal-subheader">
          Our flagship all-in-one compliance tool will give your product a comprehensive compliance summary.
        </div>
        <ImportalStepper
          ref={stepperRef}
          showLoadingSpinner={false}
          isClickable={true}
          steps={[
            {
              label: 'HTS Code',
              element: (activeStep, next, back) => (
                <FirstStepContainer
                  htsCode={htsCode}
                  setHTSCode={(htsCode?: Code) => {
                    setHTSCode(htsCode);
                    const quantityInfo = {};
                    if (htsCode && quantityDataRequiredForDuty(htsCode)) {
                      // @ts-ignore
                      quantityInfo.totalUnitsMeasureOfQuantity = getUnitOfMeasureForCode(htsCode)!;
                    }
                    setComplianceSummaryInput({
                      ...complianceSummaryInput,
                      htsno: htsCode?.htsno || '',
                      // @ts-ignore (ignoring string to decimal)
                      totals: {
                        ...complianceSummaryInput.totals,
                        ...quantityInfo,
                      },
                    });
                  }}
                  totalWeight={complianceSummaryInput.totals?.totalWeight?.toString()}
                  onTotalWeightSelected={(totalWeight?: string) => {
                    setComplianceSummaryInput({
                      ...complianceSummaryInput,
                      totals: {
                        ...complianceSummaryInput?.totals,
                        // @ts-ignore (ignoring string to decimal)
                        totalWeight: totalWeight,
                        totalWeightUnits: WeightUnit.KG,
                      },
                    });
                  }}
                  quantityInfo={complianceSummaryInput.totals}
                  onQuantityInfoSelected={(quantityInfo?: QuantityTotals) => {
                    setComplianceSummaryInput({
                      ...complianceSummaryInput,
                      // @ts-ignore (ignoring string to decimal)
                      totals: {
                        ...complianceSummaryInput?.totals,
                        ...quantityInfo,
                      },
                    });
                  }}
                  onNext={next}
                />
              ),
            },
            {
              label: 'Cargo Value',
              element: (activeStep, next, back) => (
                <CargoValue
                  cargoValue={complianceSummaryInput.totals?.totalValue?.toString() || ''}
                  onCargoValueSet={(cargoValue?: any) => {
                    setComplianceSummaryInput({
                      ...complianceSummaryInput,
                      totals: {
                        ...complianceSummaryInput?.totals,
                        totalValue: cargoValue,
                        totalValueCurrency: CurrencyCode.USD,
                      },
                    });
                  }}
                  onNext={next}
                  onBack={back}
                />
              ),
            },
            {
              label: 'Country of Origin',
              element: (activeStep, next, back) => (
                <CountryOfOrigin
                  selectedCountryCode={complianceSummaryInput.countryOfOrigin}
                  onCountryCodeSelected={(countryCode?: string) => {
                    setComplianceSummaryInput({
                      ...complianceSummaryInput,
                      countryOfOrigin: countryCode || '',
                    });
                  }}
                  onNext={next}
                  onBack={back}
                />
              ),
            },
            {
              label: 'Transportation Type',
              element: (activeStep, next, back) => (
                <ModeOfTransportation
                  modeOfTransportation={complianceSummaryInput.transportationType}
                  onModeOfTransportationSelected={(tranportationType: TransportationType) => {
                    setComplianceSummaryInput({
                      ...complianceSummaryInput,
                      transportationType: tranportationType,
                    });
                  }}
                  onNext={next}
                  onBack={back}
                />
              ),
              doBeforeNext: doComplianceSearch,
            },
            {
              label: 'Summary',
              element: (activeStep, next, back) => (
                <ComplianceSummary htsCode={htsCode} complianceSummary={complianceSummary!} handleReset={handleReset} />
              ),
            },
          ]}
        />
        <DataImportLoader
          loadingState={loadingState}
          loadingText={'Compliance greatness loading...'}
          successText={'Locked and loaded!'}
          errorText={'Whoops! Looks like we had an issue.'}
        />
        <div className="need-help">
          {showUpgradeBanner && <CallToActionSnackbar handleClose={handleCloseCallToAction} />}
        </div>
      </div>
      <LegalText />
    </div>
  );
}
