import React, { forwardRef, useImperativeHandle, useState, Ref } from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { NavigateNext } from '@mui/icons-material';

type BreadCrumbTextGenerator = (item: any) => string;

interface BreadcrumbItem {
  label: string | BreadCrumbTextGenerator;
  render: ({
    popItem,
    pushItem,
    item,
  }: {
    popItem: () => void;
    pushItem: (item: any) => void;
    item: any;
  }) => React.JSX.Element;
}

interface Props {
  showCrumbAtBaseLevel?: boolean;
  elements: BreadcrumbItem[];
}

/**
 * Define an interface for the methods you want to expose through the ref.
 */
export interface ImportalBreadCrumbsHandle {
  pushItem: (item: any) => void;
  popItem: () => void;
  reset?: () => void;
}

/**
 * Convert your component to `forwardRef` and specify its type params:
 *   - The first param is the interface (ImportalBreadCrumbsHandle) that you want to expose.
 *   - The second param is the component props (Props).
 */
export const ImportalBreadCrumbs = forwardRef<ImportalBreadCrumbsHandle, Props>(
  ({ showCrumbAtBaseLevel = false, elements }, ref) => {
    const [breadcrumbTrail, setBreadcrumbTrail] = useState<any[]>([{}]);

    // Method to push an item to the breadcrumb trail
    const pushItem = (item: any) => {
      setBreadcrumbTrail((prev) => [...prev, item]);
    };

    // Method to pop an item from the breadcrumb trail
    const popItem = () => {
      setBreadcrumbTrail((prev) => prev.slice(0, -1));
    };

    // (Optional) Reset method to clear the trail
    const reset = () => {
      setBreadcrumbTrail([{}]);
    };

    /**
     * useImperativeHandle is where you expose the methods to the parent.
     * For example, if a parent has a ref to this component, calling
     * ref.current.pushItem(...) will invoke the pushItem method here.
     */
    useImperativeHandle(ref, () => ({
      pushItem,
      popItem,
      reset,
    }));

    const getActiveBreadCrumbElement = (breadcrumbTrail: any[]) => {
      const item = breadcrumbTrail[breadcrumbTrail.length - 1];
      const element = elements[breadcrumbTrail.length - 1];
      return element.render({ popItem, pushItem, item });
    };

    return (
      <>
        {elements && elements.length && (
          <>
            {(showCrumbAtBaseLevel || breadcrumbTrail.length > 1) && (
              <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbTrail.map((item, index) => {
                  const { label } = elements[index];
                  const labelText = typeof label === 'function' ? label(item) : label;

                  const isLastItem = index === breadcrumbTrail.length - 1;
                  return isLastItem ? (
                    <Typography color="text.primary" key={index}>
                      {labelText}
                    </Typography>
                  ) : (
                    <Link
                      id={index + '-bread-crumb-link'}
                      underline="hover"
                      href={item.href}
                      key={index}
                      onClick={() => popItem()}
                    >
                      {labelText}
                    </Link>
                  );
                })}
              </Breadcrumbs>
            )}

            {getActiveBreadCrumbElement(breadcrumbTrail)}
          </>
        )}
      </>
    );
  }
);
