import * as React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

interface ImportalLinearProgressProps {
  value: number;
  variant?: 'determinate' | 'indeterminate' | 'buffer' | 'query';
  barColor?: string;
  trackColor?: string;
}

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'barColor' && prop !== 'trackColor',
})<{
  barColor?: string;
  trackColor?: string;
}>(({ theme, barColor, trackColor }) => ({
  height: 5,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: trackColor || theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: barColor || (theme.palette.mode === 'light' ? '#ffd965' : '#ffd965'),
  },
}));

const ImportalLinearProgress: React.FC<ImportalLinearProgressProps> = ({
  value,
  variant = 'determinate',
  barColor,
  trackColor,
}) => {
  return <BorderLinearProgress variant={variant} value={value} barColor={barColor} trackColor={trackColor} />;
};

export default ImportalLinearProgress;
