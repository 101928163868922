import React from 'react';
import * as d3 from 'd3';

interface ImportalVerticalBarsProps {
  data: any[];
  xScale: d3.ScaleBand<string>;
  yScale: d3.ScaleLinear<number, number>;
  seriesNameKey: string;
  seriesValueKey: string;
  barColor?: string;
  customGridLines?: number[]; // Add a prop for custom grid lines
}

export function ImportalVerticalBars({
  data,
  xScale,
  yScale,
  seriesNameKey,
  seriesValueKey,
  barColor = '#FFC107',
  customGridLines = [],
}: ImportalVerticalBarsProps) {
  return (
    <g>
      {data.map((d: any, i: number) => {
        const cat = d[seriesNameKey];
        const val = +d[seriesValueKey];
        const barX = xScale(cat) ?? 0;
        const barHeight = yScale(0)! - yScale(val);

        return (
          <rect
            key={i}
            x={barX}
            y={yScale(val)}
            width={xScale.bandwidth()}
            height={barHeight}
            fill={barColor}
            rx={12} // Rounded corners
          >
            <title>{`${cat}: ${val}`}</title>
          </rect>
        );
      })}

      {/* Optional grid lines */}
      {customGridLines.map((lineValue, i) => {
        const y = yScale(lineValue);
        return <line key={i} x1={0} x2={xScale.range()[1]} y1={y} y2={y} stroke="#E0E0E0" strokeDasharray="4 4" />;
      })}
    </g>
  );
}
