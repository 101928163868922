import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Add, Check, Close, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Mode } from 'common/interfaces/business';
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { CSSObject } from '@mui/system';
import FloatingTooltip from '../FloatingTooltip/FloatingTooltip';
import { capitalizeWord } from 'common/utilities/stringUtility';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

// Update your type to use CSSObject
type ManageItemStyles = {
  itemsTableRow: CSSObject;
  entryCardHeaderContainer: CSSObject;
  myShipmentsHeader: CSSObject;
  itemsTableContainer: CSSObject;
  noResultsContainer: CSSObject;
  userRowInputBase: CSSObject;
  rowCellStyles: CSSObject;
  headerCellStyles: CSSObject;
  iconButton: CSSObject;
  icon: CSSObject;
};

const defaultStyles: ManageItemStyles = {
  itemsTableRow: {
    padding: '5px',
    paddingLeft: '0px',
    paddingRight: '16px',
    paddingBottom: '5px',
  },
  entryCardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  myShipmentsHeader: {
    fontWeight: 700,
  },
  itemsTableContainer: {
    '& .MuiTableCell-root': {
      padding: '0px!important',
    },
  },
  noResultsContainer: {
    padding: '16px',
    textAlign: 'center',
    color: '#525256',
  },
  userRowInputBase: {
    borderRadius: '4px',
    background: 'white',
    fontSize: '14px',
    '& .MuiInputBase-root': {
      borderRadius: '4px!important',
      background: 'white!important',
      fontSize: '14px!important',
    },
  },
  rowCellStyles: {
    padding: '2px!important',
    textWrap: 'nowrap',
    '& .MuiTypography-root, & .MuiInputBase-root, & .MuiButtonBase-root': {
      fontSize: '12px!important',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fontSize: '12px!important',
  },
  headerCellStyles: {
    padding: '2px!important',
    fontSize: '12px!important',
    fontWeight: '700',
    color: '#525256',
  },
  iconButton: {
    padding: '8px',
  },
  icon: {
    fontSize: '18px',
  },
};

const mergeStyles = (defaultStyles: ManageItemStyles, customStyles?: Partial<typeof defaultStyles>) => {
  return Object.assign({}, defaultStyles, customStyles);
};

export interface ManageItemsConfig {
  columns: HeaderConfig[];
  viewModeShowEditIcon?: (item: any) => boolean;
  viewModeShowDeleteIcon?: (item: any) => boolean;
  viewModeAdditionalIcons?: Array<{
    icon: (item: any) => React.JSX.Element;
    onClick: (item, index) => void;
  }>;
  beforeItemAdded?: (item: any) => any;
  beforeItemUpdated?: (item: any) => any;
  saveDisabled?: (item: any) => boolean;
}

export interface HeaderConfig {
  header: string;
  viewComponent: (item: any, setItem?: (arg0: any) => void) => React.JSX.Element;
  addComponent?: (item: any, setItem: (arg0: any) => void) => React.JSX.Element;
  editComponent: (item: any, setItem: (arg0: any) => void) => React.JSX.Element;
  customStyle?: CSSObject;
}

interface ManageItemsProps<T> {
  manageItemsConfig: ManageItemsConfig;
  items: T[];
  getDefaultItem: () => T;

  onItemSaved?: (item: T, index: number) => void;
  onItemDeleted?: (item: T, index: number) => void;
  onItemsUpdated?: (items: T[]) => void;

  itemName: string;
  parentName?: string;
  useDeleteConfirmationModal?: boolean;
  editable?: boolean;
  style?: CSSObject;
  useHeader?: boolean;
  styles?: Partial<ManageItemStyles>;
  useDynamicColumnHeader?: boolean;
  dragToReorder?: boolean;
  parentLoadingStatus: LoadingStatus;
}

export interface ManageItemsHandles<T> {
  addItems: (items: T[]) => void;
}

const ManageItems = forwardRef(
  <T,>(
    {
      manageItemsConfig,
      items = [],
      getDefaultItem,

      onItemSaved,
      onItemDeleted,
      onItemsUpdated,

      parentName,
      itemName,
      useDeleteConfirmationModal,
      editable = true,
      style = {},
      useHeader = true,
      styles: customStyles = {} as ManageItemStyles,
      useDynamicColumnHeader = false,
      dragToReorder = false,
      parentLoadingStatus,
    }: ManageItemsProps<T>,
    ref: React.Ref<ManageItemsHandles<T>>
  ) => {
    // Merge default styles with custom styles
    const mergedStyles = mergeStyles(defaultStyles, customStyles);

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(0);

    const [localItems, setLocalItems] = useState<T[]>([]);
    const [itemModes, setItemModes] = useState<Mode[]>([]);
    const headerRefs = useRef<(HTMLTableCellElement | null)[]>([]);
    const [headerTexts, setHeaderTexts] = useState(Array(manageItemsConfig.columns.length).fill(''));

    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [rowIndexBeingDragged, setRowIndexBeingDragged] = useState<number | null>(null);

    const handleDragStart = (index: number) => {
      setRowIndexBeingDragged(index);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
      event.preventDefault();
      setHoveredIndex(index); // Update hoveredIndex during drag
    };

    const handleDragEnd = () => {
      setHoveredIndex(null);
      setRowIndexBeingDragged(null);
    };

    const handleDrop = (index: number) => {
      setHoveredIndex(null);
      setRowIndexBeingDragged(null);

      if (rowIndexBeingDragged === null || rowIndexBeingDragged === index) {
        return;
      }

      const updatedItems = [...localItems];
      const [draggedItem] = updatedItems.splice(rowIndexBeingDragged, 1);
      updatedItems.splice(index, 0, draggedItem);
      setLocalItems(updatedItems);
      onItemsUpdated?.(updatedItems);
    };

    const syncItemModes = (updatedItems: T[], updatedModes: Mode[]) => {
      if (updatedItems.length !== updatedModes.length) {
        const newModes = [...updatedModes];
        while (newModes.length < updatedItems.length) {
          newModes.push(Mode.ADD);
        }
        setItemModes(newModes);
      } else {
        setItemModes(updatedModes);
      }
    };

    useEffect(() => {
      setLocalItems(items);
      setItemModes(items.map(() => Mode.VIEW));
    }, [editable]);

    useEffect(() => {
      if (localItems.length === 0 && items.length === 0) {
        return;
      }
      if (localItems.length === 0 && items.length > 0) {
        setLocalItems(items);
        setItemModes(items.map((item) => Mode.VIEW));
        return;
      }
      const newLocalItems = localItems.map((localItem, index) => {
        if (itemModes[index] === Mode.ADD) {
          return localItems[index];
        } else if (itemModes[index] === Mode.VIEW) {
          // find number of ADD in between 0 and this index
          const numberOfAddsInLocalItems =
            itemModes.slice(0, index).filter((itemMode) => itemMode === Mode.ADD)?.length || 0;
          return items[index - numberOfAddsInLocalItems];
        } else {
          return localItems[index];
        }
      });

      setLocalItems(newLocalItems);
    }, [items]);

    useImperativeHandle(ref, () => ({
      addItems(newItems: T[]) {
        setLocalItems([...localItems, ...newItems]);
        setItemModes([...itemModes, ...newItems.map((i) => Mode.ADD)]);
      },
    }));

    const handleDeleteConfirmClose = (confirm: boolean) => {
      if (confirm) {
        handleDeleteItemRow(indexToDelete);
      }
      setDeleteConfirmOpen(false);
    };

    const setItemAtIndex = (item: any, index: number) => {
      const updatedItems = [...localItems];
      updatedItems[index] = item;
      setLocalItems(updatedItems);
    };

    const handleAddItemRow = () => {
      const updatedItems = [...localItems, getDefaultItem()];
      setLocalItems(updatedItems);
      syncItemModes(updatedItems, [...itemModes, Mode.ADD]);
    };

    const handleCancelEditItemRow = (indexToUpdate: number) => {
      const updatedItemModes = [...itemModes];
      updatedItemModes[indexToUpdate] = Mode.VIEW;
      setItemModes(updatedItemModes);
    };

    const handleEditItemRow = (indexToUpdate: number) => {
      const updatedItemModes = [...itemModes];
      updatedItemModes[indexToUpdate] = Mode.EDIT;
      setItemModes(updatedItemModes);
    };

    const handleSaveItemRow = async (indexInLocalItems: number) => {
      const currentMode = itemModes[indexInLocalItems];
      let itemToSave: T = localItems[indexInLocalItems];
      let updatedItems: T[] = [...items];

      if (currentMode === Mode.EDIT) {
        itemToSave = manageItemsConfig.beforeItemUpdated
          ? await manageItemsConfig.beforeItemUpdated?.(itemToSave)
          : itemToSave;
        // by definition ADD are not in items yet.
        const numberOfAddBeforeThisElement =
          itemModes.slice(0, indexInLocalItems).filter((itemMode) => itemMode === Mode.ADD).length || 0;
        const indexInItems = indexInLocalItems - numberOfAddBeforeThisElement;
        updatedItems[indexInItems] = itemToSave;
      } else if (currentMode === Mode.ADD) {
        itemToSave = manageItemsConfig.beforeItemAdded
          ? await manageItemsConfig.beforeItemAdded?.(itemToSave)
          : itemToSave;
        // **ADD Mode Handling**
        if (indexInLocalItems > items.length - 1) {
          // **Case 1:** Adding at the end
          updatedItems.push(itemToSave);
        } else {
          // **Case 2:** Inserting within the array
          const numberOfAddBeforeThisElement =
            itemModes
              .slice(0, indexInLocalItems) // Use slice to avoid mutating 'itemModes'
              .filter((itemMode) => itemMode === Mode.ADD).length || 0;

          const indexInItems = indexInLocalItems - numberOfAddBeforeThisElement;

          // Ensure the calculated index is within bounds
          const safeIndex = Math.max(0, Math.min(indexInItems, updatedItems.length));

          updatedItems.splice(safeIndex, 0, itemToSave);
        }
      }

      const updatedItemModes = [...itemModes];
      updatedItemModes[indexInLocalItems] = Mode.VIEW;
      setItemModes(updatedItemModes);

      onItemSaved?.(itemToSave, indexInLocalItems);
      onItemsUpdated?.(updatedItems);
    };

    const handleOpenDeleteConfirmModal = (index) => {
      setIndexToDelete(index);
      if (useDeleteConfirmationModal) {
        setDeleteConfirmOpen(true);
      } else {
        handleDeleteItemRow(index);
      }
    };

    const handleDeleteItemRow = (indexInLocalItems: number) => {
      const currentMode = itemModes[indexInLocalItems];
      const itemToDelete = localItems[indexInLocalItems];

      if (currentMode === Mode.ADD) {
        const updatedItems = localItems.filter((_, i) => i !== indexInLocalItems);
        const updatedModes = itemModes.filter((_, i) => i !== indexInLocalItems);

        setLocalItems(updatedItems);
        syncItemModes(updatedItems, updatedModes);
      } else if (currentMode === Mode.VIEW) {
        // **Handling DELETE for VIEW Mode**
        // Need to remove the item from both items and localItems

        // Step 1: Calculate the number of ADD modes before the current index
        const numberOfAddBeforeThisElement = itemModes
          .slice(0, indexInLocalItems) // Get all modes before the current index
          .filter((mode) => mode === Mode.ADD).length; // Count how many are ADD

        // Step 2: Determine the corresponding index in the items array
        const indexInItems = indexInLocalItems - numberOfAddBeforeThisElement;

        // **Edge Case Handling:** Ensure indexInItems is within the bounds of the items array
        if (indexInItems < 0 || indexInItems >= items.length) {
          console.error('Invalid index calculated for items array.');
          return; // Exit early to prevent further execution
        }

        // Step 3: Remove the item from the items array
        const updatedItems = [...items];
        updatedItems.splice(indexInItems, 1); // Remove 1 item at indexInItems

        // Step 4: Remove the item from localItems and itemModes
        const updatedLocalItems = localItems.filter((_, i) => i !== indexInLocalItems);
        const updatedLocalModes = itemModes.filter((_, i) => i !== indexInLocalItems);

        // Step 5: Update state
        setLocalItems(updatedLocalItems);
        syncItemModes(updatedLocalItems, updatedLocalModes);

        // Step 6: Trigger callbacks if provided
        onItemDeleted?.(itemToDelete, indexInLocalItems);
        onItemsUpdated?.(updatedItems);
      }
    };

    const responsiveHeaderText = (text, width) => {
      // Dynamically adjust the number of characters based on the width
      const characterWidth = 10; // Adjust this value as needed for your font
      const maxChars = Math.floor(width / characterWidth);
      return text.length > maxChars ? text.slice(0, maxChars) + '...' : text;
    };

    useEffect(() => {
      if (useDynamicColumnHeader) {
        const updateHeaderTexts = () => {
          const newHeaderTexts = manageItemsConfig.columns.map((config, idx) => {
            const width = headerRefs.current[idx]?.offsetWidth || 0;
            return responsiveHeaderText(config.header, width);
          });
          setHeaderTexts(newHeaderTexts);
        };

        // Call the function initially and set up a listener for resizing
        updateHeaderTexts();
        window.addEventListener('resize', updateHeaderTexts);

        return () => {
          window.removeEventListener('resize', updateHeaderTexts);
        };
      }
    }, [manageItemsConfig, useDynamicColumnHeader]);

    const renderItemRow = (item: any, manageItemConfig: ManageItemsConfig, index: number, mode: Mode) => {
      if (!item) {
        return (
          <TableRow>
            <TableCell colSpan={manageItemConfig.columns.length + 2} style={{ textAlign: 'center' }}>
              Error
            </TableCell>
          </TableRow>
        );
      }

      if (dragToReorder && rowIndexBeingDragged !== null && hoveredIndex === index) {
        return (
          <TableRow
            key={`filler-${index}`}
            style={{ backgroundColor: '#f0f0f0', height: '48px' }}
            onDrop={dragToReorder ? () => handleDrop(index) : undefined}
          >
            <TableCell colSpan={manageItemConfig.columns.length + 2} style={{ textAlign: 'center' }}>
              Drop Here
            </TableCell>
          </TableRow>
        );
      }

      return (
        <TableRow
          sx={mergedStyles.itemsTableRow}
          key={`${mode}-${index}`}
          onDragStart={dragToReorder ? () => handleDragStart(index) : undefined}
          onDragOver={dragToReorder ? (e) => handleDragOver(e, index) : undefined}
          onDragEnd={dragToReorder ? () => handleDragEnd() : undefined}
          draggable={dragToReorder}
        >
          {manageItemConfig.columns.map((manageItemConfig) => (
            <TableCell
              sx={Object.assign(manageItemConfig.customStyle || {}, mergedStyles.rowCellStyles)}
              key={`${manageItemConfig.header}-${index}-${mode}`}
            >
              {mode === Mode.VIEW && manageItemConfig.viewComponent(item)}
              {mode === Mode.ADD &&
                (manageItemConfig.addComponent?.(item, (item) => setItemAtIndex(item, index)) ||
                  manageItemConfig.editComponent(item, (item) => setItemAtIndex(item, index)))}
              {mode === Mode.EDIT && manageItemConfig.editComponent(item, (item) => setItemAtIndex(item, index))}
            </TableCell>
          ))}
          {editable && (
            <TableCell key={`${index}-${mode}`} sx={mergedStyles.rowCellStyles}>
              {mode === Mode.VIEW && (
                <>
                  {(manageItemConfig.viewModeShowEditIcon ? manageItemConfig.viewModeShowEditIcon(item) : true) && (
                    <IconButton size="small" onClick={() => handleEditItemRow(index)}>
                      <EditIcon sx={mergedStyles.icon} />
                    </IconButton>
                  )}
                  {(manageItemConfig.viewModeShowDeleteIcon ? manageItemConfig.viewModeShowDeleteIcon(item) : true) && (
                    <IconButton size="small" onClick={() => handleOpenDeleteConfirmModal(index)}>
                      <DeleteIcon sx={mergedStyles.icon} />
                    </IconButton>
                  )}
                  {Array.isArray(manageItemConfig.viewModeAdditionalIcons) &&
                    manageItemConfig.viewModeAdditionalIcons.length > 0 &&
                    manageItemConfig.viewModeAdditionalIcons.map((iconBuilder, index) => {
                      return (
                        <IconButton
                          key={index}
                          size="small"
                          onClick={() => {
                            iconBuilder.onClick(item, index);
                          }}
                        >
                          {React.cloneElement(iconBuilder.icon(item), { sx: mergedStyles.icon })}
                        </IconButton>
                      );
                    })}
                </>
              )}
              {mode === Mode.EDIT && (
                <>
                  <IconButton size="small" onClick={() => handleCancelEditItemRow(index)}>
                    <Close sx={mergedStyles.icon} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleSaveItemRow(index)}
                    disabled={
                      manageItemConfig.saveDisabled?.(item) || [LoadingStatus.LOADING].includes(parentLoadingStatus)
                    }
                  >
                    <Check sx={mergedStyles.icon} />
                  </IconButton>
                </>
              )}
              {mode === Mode.ADD && (
                <>
                  <IconButton size="small" onClick={() => handleDeleteItemRow(index)}>
                    <Close sx={mergedStyles.icon} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleSaveItemRow(index)}
                    disabled={
                      manageItemConfig.saveDisabled?.(item) || [LoadingStatus.LOADING].includes(parentLoadingStatus)
                    }
                  >
                    <Check sx={mergedStyles.icon} />
                  </IconButton>
                </>
              )}
            </TableCell>
          )}
          {dragToReorder && (
            <TableCell
              key={`${index}-${mode}`}
              sx={{
                ...mergedStyles.rowCellStyles,
                width: '40px',
                textAlign: 'center',
                boxSizing: 'border-box',
                borderTop: index === 0 ? '1px solid #d3d3d3' : 'none',
                verticalAlign: 'bottom',
              }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '24px',
                  height: '24px',
                }}
              >
                <DragIndicatorIcon sx={{ fontSize: '18px', cursor: 'grab', color: 'grey' }} />
              </Box>
            </TableCell>
          )}
        </TableRow>
      );
    };

    return (
      <div>
        <Box sx={{ ...mergedStyles.entryCardHeaderContainer, ...style }}>
          <Box sx={mergedStyles.myShipmentsHeader}>{useHeader ? capitalizeWord(itemName) : ''}</Box>
          {editable && (
            <IconButton onClick={handleAddItemRow}>
              <Add />
            </IconButton>
          )}
        </Box>
        {localItems && localItems.length > 0 ? (
          <Box sx={mergedStyles.itemsTableContainer}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {manageItemsConfig.columns.map(({ header, customStyle }, idx) => (
                      <TableCell
                        sx={mergedStyles.headerCellStyles}
                        key={idx}
                        ref={(el) => {
                          headerRefs.current[idx] = el as HTMLTableCellElement | null;
                        }}
                        className="responsive-header"
                      >
                        {useDynamicColumnHeader ? (
                          <FloatingTooltip title={header} placement="top">
                            <span>{headerTexts[idx] || header}</span>
                          </FloatingTooltip>
                        ) : (
                          header
                        )}
                      </TableCell>
                    ))}
                    {editable && <TableCell sx={mergedStyles.headerCellStyles}></TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody sx={mergedStyles.userRowInputBase}>
                  {localItems.map((item, index) => renderItemRow(item, manageItemsConfig, index, itemModes[index]))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box sx={mergedStyles.noResultsContainer}>
            No {itemName} saved yet for this {parentName}.
          </Box>
        )}
        <DeleteConfirmationModal open={deleteConfirmOpen} title={'Delete Item'} onClose={handleDeleteConfirmClose} />
      </div>
    );
  }
) as <T>(props: ManageItemsProps<T> & { ref?: React.Ref<ManageItemsHandles<T>> }) => React.ReactElement | null;

export default ManageItems;
