import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import './ImportalStepper.css';
import useIsMobile from '@/custom-hooks/mobile-device/isMobileDevice';
import { range } from 'common/utilities/range';
import BackdropLoader from '@/components/snackbar-loader/SnackbarLoader';

export interface ImportalStepperHandle {
  setActive: (arg0: number) => void;
  setCompletedUpTo: (arg0: number) => void;
  back: () => void;
  next: () => void;
  allStepsCompleted: () => boolean;
  getActiveStep: () => number;
  getTotalSteps: () => number;
  getNumberOfCompletedSteps: () => number;
  resetStepper: () => void;
}

export type Steps = Array<{
  label: string;
  icon?: React.ReactElement;
  element?: (activeStep: number, next: () => void, back: () => void) => React.JSX.Element;
  doBeforeNext?: (onSuccess: () => void) => void;
}>;

interface Props {
  showLoadingSpinner: boolean;
  steps: Steps;
  children?: any; // TODO what is proper type?
  stepperCompletedElement?: React.JSX.Element;
  isClickable?: boolean;
}

export const ImportalStepper = forwardRef(
  ({ showLoadingSpinner, steps, children, stepperCompletedElement, isClickable }: Props, ref) => {
    const theme = useTheme();
    const isMobile = useIsMobile(() => {});

    const [activeStep, setActiveStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);

    // Determine if all steps are completed
    const isAllCompleted = completedSteps.length === steps.length;

    const back = () => {
      setActiveStep(activeStep - 1);

      // TODO: next needs to be 'index out of bounds' safe
    };

    const next = () => {
      // TODO: next needs to be 'index out of bounds' safe

      const currentStep = steps[activeStep];
      const completeAndProceed = () => {
        setCompletedSteps([...completedSteps, activeStep]);
        setActiveStep(activeStep + 1);
      };

      if (currentStep.doBeforeNext) {
        currentStep.doBeforeNext(completeAndProceed);
      } else {
        completeAndProceed();
      }
    };

    // Use Imperative Handle to allow parent to set stepper state
    useImperativeHandle(ref, () => ({
      setActive: (active) => {
        setActiveStep(active);
      },
      setCompletedUpTo: (completedTo: number) => {
        setCompletedSteps(range(0, completedTo));
      },
      back,
      next,
      resetStepper: () => {
        setActiveStep(0);
        setCompletedSteps([]);
      },
    }));

    interface ExtendedStepIconProps extends StepIconProps {
      index: number; // Adding index field
    }

    function ColorlibStepIcon(props: ExtendedStepIconProps) {
      const { index, active, completed, className, icon } = props;

      return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
          {completed ? <CheckIcon /> : icon || index + 1}
        </ColorlibStepIconRoot>
      );
    }

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        fontWeight: 600,
      },
      [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage: 'linear-gradient(95deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)', // Green gradient for completed state
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // White background with shadow for active line
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          backgroundImage: 'linear-gradient(95deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)', // Green gradient for completed state
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for completed line
        },
      },
      [`& .${stepConnectorClasses.line}`]: {
        height: 6,
        border: 0,
        backgroundColor: '#ffffff', // Default color as white
        borderRadius: 1,
        width: '100%',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for default state
      },
    }));

    const handleStepClick = (index: number) => {
      if ((Boolean(isClickable) && completedSteps.includes(index)) || isAllCompleted) {
        setActiveStep(index);
      }
    };

    const ColorlibStepIconRoot = styled('div')<{
      ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
      backgroundColor:
        ownerState.active || ownerState.completed
          ? 'linear-gradient(136deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)'
          : '#ffffff', // Default background color to white
      zIndex: 1,
      color: ownerState.active || ownerState.completed ? '#fff' : '#9e9e9e', // Set text color to gray for default state
      width: 45,
      height: 45,
      display: 'flex',
      borderRadius: '50%',
      fontWeight: '700',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: ownerState.active || ownerState.completed ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shadow for default state
      ...(ownerState.active && {
        backgroundImage: 'linear-gradient( 136deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      }),
      ...(ownerState.completed && {
        backgroundImage: 'linear-gradient( 136deg, rgb(56,142,60) 0%, rgb(56,142,60) 50%, rgb(56,142,60) 100%)',
      }),
      '@media (max-width: 600px)': {
        width: 35,
        height: 35,
      },
    }));

    if (isMobile) {
      return (
        <>
          <div className="mobile-stepper">
            <div className="step-indicator">
              <div className="step-indicator-circle">
                {activeStep + 1}/{steps.length}
              </div>
              <Typography sx={{ marginTop: '12px', fontWeight: 600 }}>{steps[activeStep]?.label}</Typography>
            </div>
          </div>
          {stepperCompletedElement && activeStep === steps.length ? (
            stepperCompletedElement
          ) : (
            <>
              {showLoadingSpinner && <BackdropLoader isLoading={showLoadingSpinner} />}
              {steps[activeStep].element?.(activeStep, next, back)}
              {children?.(activeStep)}
            </>
          )}
        </>
      );
    }

    return (
      <>
        {stepperCompletedElement && activeStep === steps.length ? (
          stepperCompletedElement
        ) : (
          <>
            <Stepper
              alternativeLabel
              sx={{
                marginBottom: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
              connector={<ColorlibConnector />}
              nonLinear
              activeStep={activeStep}
            >
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  onClick={() => handleStepClick(index)}
                  sx={{ cursor: 'pointer' }}
                  completed={completedSteps.includes(index)}
                >
                  <StepLabel
                    StepIconComponent={(props) => <ColorlibStepIcon {...props} index={index} icon={step.icon} />}
                    sx={{
                      '& .MuiStepLabel-label': {
                        fontSize: '14px!important',
                        marginTop: '8px!important',
                        '&.Mui-active': {
                          fontWeight: '600',
                        },
                      },
                    }}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {showLoadingSpinner && <BackdropLoader isLoading={showLoadingSpinner} />}
            {steps[activeStep].element?.(activeStep, next, back)}
            {children?.(activeStep)}
          </>
        )}
      </>
    );
  }
);
