import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import ActionIcons from '@/shared-components/ActionIcons/ActionIcons';
import { Mode } from 'common/interfaces/business';
import { CreateSessionRequest, Link, SessionType, SessionTypeText } from 'common/interfaces/session';

const RenderRow = ({
  link,
  index,
  handleLinkChange,
  handleCancelEditRow,
  handleSaveLink,
  handleDeleteLink,
  config,
}) => {
  const isEditMode = link.mode === Mode.EDIT || link.mode === Mode.ADD;

  if (isEditMode) {
    return (
      <TableRow key={index}>
        {config.typeComponent && <TableCell>{config.typeComponent(link, index, handleLinkChange)}</TableCell>}
        {config.userComponent && <TableCell>{config.userComponent(link, index, handleLinkChange)}</TableCell>}
        <TableCell></TableCell>
        {config.visitsComponent && <TableCell>{config.visitsComponent(link)}</TableCell>}

        <ActionIcons index={index} handleCancelEditRow={handleCancelEditRow} handleSaveLink={handleSaveLink} />
      </TableRow>
    );
  } else {
    return (
      config.CopyableTableRow && (
        <config.CopyableTableRow key={link.shortLink} link={link} index={index} handleDeleteLink={handleDeleteLink} />
      )
    );
  }
};

export default RenderRow;
