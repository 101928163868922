import React, { useEffect, useState } from 'react';
import ImportalHistogram from '@/components/reporting/ImportalHistogram';
import { BarChartOrientation, ChartConfig, LegendPositionType, ReportChartType } from 'common/interfaces/reports';
import ImportalPieChart from '@/components/reporting/ImportalPieChart';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import { Add } from '@mui/icons-material';
import ImportalBarChart from '@/components/reporting/ImportalBarChart';
import ImportalReportCard from '@/shared-components/ImportalReportCard/ImportalReportCard';
import ImportalFilter from '@/shared-components/ImportalFilter/ImportalFilter';
import ImportalAutocomplete from '@/shared-components/ImportalAutocomplete/ImportalAutocomplete';
import ImportalSearch from '@/shared-components/ImportalSearch/ImportalSearch'; // <-- 1) Import the new component
import { ColumnConfig, TableConfig } from 'common/interfaces/importalTable';
import { Chip } from '@mui/material';
import moment from 'moment';
import { styled } from '@mui/system';

const StyledChip = styled(Chip)(() => ({
  backgroundColor: '#77A8F6',
  color: 'white',
  borderRadius: '10px!important',
  fontWeight: '700',
  padding: '8px',
  fontSize: '12px',
  '& .MuiChip-deleteIcon': {
    color: 'white',
    fontSize: '18px',
  },
}));

export default function Summary() {
  // ----- Country Pie Chart Data -----
  const [countryPieChartData] = useState<any[]>([
    { country: 'China', productCount: 120, color: '#388E3C' },
    { country: 'Peru', productCount: 80, color: '#4CAF50' },
    { country: 'Taiwan', productCount: 50, color: '#8BC34A' },
    { country: 'Vietnam', productCount: 35, color: '#C8E6C9' },
    { country: 'India', productCount: 25, color: '#E8F5E9' },
  ]);

  const countryPieChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'Distribution of Products by Country',
    chartDescriptor: 'This chart shows the distribution of products across different countries.',
    pieConfig: {
      seriesNameKey: 'country',
      seriesValueKey: 'productCount',
    },
  };

  // ----- Additional Fees Pie Chart Data -----
  const [additionalFeesData] = useState<any[]>([
    { feeType: 'HMF', feeAmount: 321, color: '#EB666B' },
    { feeType: 'MPF', feeAmount: 750, color: '#77A8F6' },
  ]);

  const additionalFeesChartConfig: ChartConfig = {
    chartType: ReportChartType.PIE,
    chartName: 'Additional Fees',
    chartDescriptor: 'Distribution of fees across MPF, HMF, and Cotton Fee.',
    pieConfig: {
      seriesNameKey: 'feeType',
      seriesValueKey: 'feeAmount',
    },
    showLabelInChart: true,
    legendPosition: LegendPositionType.TOP,
  };

  // ----- Overall Landed Costs Data -----
  const [overallLandedCostsData] = useState<any[]>([
    { month: 'Jan', sales: 1200 },
    { month: 'Feb', sales: 1800 },
    { month: 'Mar', sales: 1400 },
    { month: 'Apr', sales: 2200 },
    { month: 'May', sales: 1600 },
    { month: 'Jun', sales: 2900 },
  ]);

  const overallLandedCostsChartConfig: ChartConfig = {
    chartType: ReportChartType.HISTOGRAM,
    chartName: 'Overall Customs Landed Cost',
    chartDescriptor: 'Total landed compliance costs per month',
    histogramConfig: {
      xAxisName: 'Month',
      yAxisName: 'Landed Cost ($)',
      seriesNameKey: 'month',
      seriesValueKey: 'sales',
    },
    yAxisTickValues: [1000, 2000, 3000],
  };

  // ----- General Duty Spend Data -----
  const [generalDutyData] = useState<any[]>([
    { dutyType: 'SKU-1234', cost: 1400, color: '#FFB300' },
    { dutyType: 'SKU-1235', cost: 700, color: '#D81B60' },
    { dutyType: 'SKU-1236', cost: 500, color: '#8E24AA' },
    { dutyType: 'SKU-1237', cost: 500, color: '#8E24AA' },
    { dutyType: 'SKU-1238', cost: 500, color: '#8E24AA' },
  ]);

  const generalDutyChartConfig: ChartConfig = {
    chartType: ReportChartType.BAR,
    chartName: 'General Duty Spend',
    chartDescriptor: 'Breakdown of general duties.',
    barConfig: {
      xAxisName: 'Cost ($)',
      yAxisName: 'Product',
      seriesNameKey: 'dutyType',
      seriesValueKey: 'cost',
      orientation: BarChartOrientation.HORIZONTAL,
      barColor: '#EABC66',
    },
  };

  // ----- Duty Spend By Vendor Data -----
  const [dutySpendByVendorData] = useState<any[]>([
    { vendor: 'Vendor A', cost: 2000, color: '#42A5F5' },
    { vendor: 'Vendor B', cost: 1500, color: '#66BB6A' },
    { vendor: 'Vendor C', cost: 1000, color: '#FFCA28' },
    { vendor: 'Vendor D', cost: 700, color: '#EC407A' },
  ]);

  const dutySpendByVendorChartConfig: ChartConfig = {
    chartType: ReportChartType.HISTOGRAM,
    chartName: 'Duty Spend By Vendor',
    chartDescriptor: 'Overall duty spend per vendor.',
    barConfig: {
      xAxisName: 'Cost ($)',
      yAxisName: 'Vendor',
      seriesNameKey: 'vendor',
      seriesValueKey: 'cost',
      orientation: BarChartOrientation.HORIZONTAL,
      barColor: '#EABC66',
    },
  };

  // ----- Define Columns for Filtering -----
  const [columns] = useState<ColumnConfig[]>([
    {
      name: 'category',
      displayName: 'Category',
      isVisible: true,
      sortable: true,
      filterable: true,
      dataType: 'string',
    },
    {
      name: 'date',
      displayName: 'Date',
      isVisible: true,
      sortable: true,
      filterable: true,
      dataType: 'date',
    },
  ]);

  // ----- Define Table Configuration -----
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    columns: columns,
    filterConfig: {},
    sortConfig: [],
    subKeyForData: '',
  });

  // ----- Define Selected Filtering Column and Values -----
  const [selectedFilteringColumn, setSelectedFilteringColumn] = useState<ColumnConfig | null>(null);
  const [selectedFilteringValues, setSelectedFilteringValues] = useState<any[]>([]);

  // ----- Add Filter Chip -----
  const handleAddFilterChip = (columnName: string, displayName: string, values: Array<any>) => {
    const newFilterConfig = {
      ...tableConfig.filterConfig,
      [columnName]: {
        allowedValues: values,
        columnName: columnName,
        displayName: displayName,
      },
    };
    setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
  };

  // ----- Delete Filter Chip -----
  const handleDeleteFilterChip = (columnName: string, value: string) => {
    const newFilterConfig = { ...tableConfig.filterConfig };

    if (value === '') {
      delete newFilterConfig[columnName];
    } else {
      const filteredValues = newFilterConfig[columnName].allowedValues.filter((val) => val !== value);
      if (filteredValues.length === 0) {
        delete newFilterConfig[columnName];
      } else {
        newFilterConfig[columnName] = {
          ...newFilterConfig[columnName],
          allowedValues: filteredValues,
        };
      }
    }
    setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
  };

  // ----- Manage Filtered Data for Each Chart -----
  const [filteredData, setFilteredData] = useState<{
    overallLandedCosts: any[];
    generalDuty: any[];
    dutySpendByVendor: any[];
    additionalFees: any[];
  }>({
    overallLandedCosts: overallLandedCostsData,
    generalDuty: generalDutyData,
    dutySpendByVendor: dutySpendByVendorData,
    additionalFees: additionalFeesData,
  });

  // ----- Local Search State for ImportalSearch -----
  const [searchQuery, setSearchQuery] = useState('');

  // Handler for ImportalSearch changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    // You can also incorporate search logic here if you want to filter data
    // setFilteredData(...) etc.
  };

  // ----- Apply Filters -----
  useEffect(() => {
    const getFilteredData = (dataArray: any[]): any[] => {
      return dataArray.filter((item) => {
        return Object.keys(tableConfig.filterConfig).every((columnName) => {
          const filter = tableConfig.filterConfig[columnName];
          if (filter.allowedValues.length === 0) return true;

          const itemValue = item[columnName];
          const columnConfig = columns.find((col) => col.name === columnName);
          if (!columnConfig) return true;

          if (filter.allowedValues.length === 2 && columnConfig.dataType === 'date') {
            const [startDate, endDate] = filter.allowedValues;
            const itemDate = moment(itemValue);
            return itemDate.isSameOrAfter(moment(startDate), 'day') && itemDate.isSameOrBefore(moment(endDate), 'day');
          }
          return filter.allowedValues.includes(itemValue);
        });
      });
    };

    const filteredOverallLandedCostsData = getFilteredData(overallLandedCostsData);
    const filteredGeneralDutyData = getFilteredData(generalDutyData);
    const filteredDutySpendByVendorData = getFilteredData(dutySpendByVendorData);
    const filteredAdditionalFeesData = getFilteredData(additionalFeesData);

    setFilteredData({
      overallLandedCosts: filteredOverallLandedCostsData,
      generalDuty: filteredGeneralDutyData,
      dutySpendByVendor: filteredDutySpendByVendorData,
      additionalFees: filteredAdditionalFeesData,
    });
  }, [tableConfig, overallLandedCostsData, generalDutyData, dutySpendByVendorData, additionalFeesData, columns]);

  // ----- Options for Action Buttons -----
  const menuOptions = [
    { label: 'Edit', onClick: () => console.log('Edit clicked') },
    { label: 'Delete', onClick: () => console.log('Delete clicked') },
    { label: 'View Details', onClick: () => console.log('View Details clicked') },
  ];

  // ----- Render Filter Chips -----
  const renderFilterChips = () => {
    return Object.keys(tableConfig.filterConfig).map((columnName) => {
      const filterConfig = tableConfig.filterConfig[columnName];
      const columnConfig = columns.find((col) => col.name === columnName);
      const isDateColumn = columnConfig?.dataType === 'date';

      if (isDateColumn && filterConfig.allowedValues.length === 2) {
        const [startDate, endDate] = filterConfig.allowedValues;
        return (
          <StyledChip
            key={`${columnName}-date-range`}
            label={`${filterConfig.displayName || columnName}: ${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`}
            onDelete={() => handleDeleteFilterChip(columnName, '')}
          />
        );
      }

      return filterConfig.allowedValues.map((value: any) => (
        <StyledChip
          key={`${columnName}-${value}`}
          label={`${filterConfig.displayName || columnName}: ${value}`}
          onDelete={() => handleDeleteFilterChip(columnName, value)}
        />
      ));
    });
  };

  // ----- Example combined data array for demonstration -----
  const sortedAndFilteredData = [
    ...filteredData.overallLandedCosts.map((item) => ({ ...item, source: 'overallLandedCostsData' })),
    ...filteredData.generalDuty.map((item) => ({ ...item, source: 'generalDutyData' })),
    ...filteredData.dutySpendByVendor.map((item) => ({ ...item, source: 'dutySpendByVendorData' })),
    ...filteredData.additionalFees.map((item) => ({ ...item, source: 'additionalFeesData' })),
  ];

  return (
    <>
      {/* ----- Top Section: ImportalAutocomplete, ImportalFilter, Filter Chips, and Action Button ----- */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        {/* ----- LEFT AREA: Autocomplete & Filter Controls ----- */}
        <div style={{ flex: 1, display: 'flex', gap: '6px', alignItems: 'end' }}>
          {/* ImportalAutocomplete */}
          <ImportalAutocomplete
            options={columns}
            value={selectedFilteringColumn}
            onChange={(event, value) => {
              setSelectedFilteringColumn(value as ColumnConfig | null);
              setSelectedFilteringValues([]);
            }}
            placeholder="Filter by column..."
          />

          {/* ImportalFilter */}
          <ImportalFilter
            selectedFilteringColumn={selectedFilteringColumn}
            selectedFilteringValues={selectedFilteringValues}
            setSelectedFilteringColumn={setSelectedFilteringColumn}
            setSelectedFilteringValues={setSelectedFilteringValues}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            sortedAndFilteredData={sortedAndFilteredData}
            handleAddFilterChip={handleAddFilterChip}
          />

          {/* Display Filter Chips */}
          <div style={{ overflowY: 'auto', alignSelf: 'center', maxHeight: '40px' }}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'end',
                columnGap: '4px',
                rowGap: '2px',
              }}
            >
              {renderFilterChips()}
            </div>
          </div>
        </div>

        {/* ----- RIGHT AREA: ImportalSearch and Create New Report Button ----- */}
        <div style={{ display: 'flex', alignItems: 'end', gap: '8px' }}>
          {/* 2) Use ImportalSearch to the left of the button */}
          <ImportalSearch value={searchQuery} onChange={handleSearchChange} placeholder="Search..." />

          <ImportalPrimaryButton
            style={{ width: '200px', borderRadius: '10px', fontSize: '14px' }}
            onClick={() => console.log('Create New Report')}
            text="Create New Report"
            endIcon={<Add />}
          />
        </div>
      </div>

      {/* ----- Existing Report Cards & Charts ----- */}
      <div style={{ display: 'flex', width: '100%', gap: '24px', marginBottom: '24px' }}>
        <ImportalReportCard
          title="Overall Customs Landed Cost"
          viewFullReport={true}
          menuOptions={menuOptions}
          style={{ flex: 1 }}
        >
          <ImportalHistogram chartConfig={overallLandedCostsChartConfig} data={filteredData.overallLandedCosts} />
        </ImportalReportCard>

        <ImportalReportCard
          title="General Duty Spend"
          viewFullReport={true}
          menuOptions={menuOptions}
          style={{ flex: 1 }}
        >
          <ImportalBarChart chartConfig={generalDutyChartConfig} data={filteredData.generalDuty} />
        </ImportalReportCard>
      </div>

      <div style={{ display: 'flex', gap: '24px', flexWrap: 'wrap' }}>
        <div style={{ flex: '1 1 calc(33.333% - 24px)' }}>
          <ImportalReportCard title="Duty Spend By Country" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={countryPieChartConfig} data={countryPieChartData} />
          </ImportalReportCard>
        </div>
        <div style={{ flex: '1 1 calc(33.333% - 24px)' }}>
          <ImportalReportCard title="Duty Spend By Vendor" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalBarChart chartConfig={dutySpendByVendorChartConfig} data={filteredData.dutySpendByVendor} />
          </ImportalReportCard>
        </div>
        <div style={{ flex: '1 1 calc(33.333% - 24px)', minWidth: '300px' }}>
          <ImportalReportCard title="Additional Fees" viewFullReport={true} menuOptions={menuOptions}>
            <ImportalPieChart chartConfig={additionalFeesChartConfig} data={filteredData.additionalFees} />
          </ImportalReportCard>
        </div>
      </div>
    </>
  );
}
