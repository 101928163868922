import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import React from 'react';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export interface ImportalMenuButtonItem {
  text: string;
  disabled?: boolean;
  onClick: () => void;
  onlyShowWithBetaFeature?: string;
}

export interface ImportalMenuButtonProps {
  buttonText: string;
  items: Array<ImportalMenuButtonItem>;
}

export default function ImportalMenuButton({ buttonText, items }: ImportalMenuButtonProps) {
  // Menu Component boiler plate
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ImportalPrimaryButton
        style={{
          width: '120px',
          borderRadius: '8px',
          height: '40px',
          fontSize: '12px',
        }}
        onClick={(e) => handleMenuClick(e as React.MouseEvent<HTMLElement>)}
        text={buttonText}
        endIcon={<ArrowDropDownIcon />}
      />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {items.map((item, index) => {
          return (
            <MenuItem
              key={item.text + index}
              onClick={() => {
                item.onClick();
                handleMenuClose();
              }}
            >
              {item.text}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
