import { useMatch } from 'react-router';
import { useAPI } from '@/api/APIContext';
import React, { useContext, useEffect, useState } from 'react';
import { Card, CardContent, IconButton, MenuItem, Select, TableCell, TableRow, Typography } from '@mui/material';
import { CreateSessionRequest, Link, SessionType, SessionTypeText } from 'common/interfaces/session';
import DeleteIcon from '@mui/icons-material/Delete';
import { Mode } from 'common/interfaces/business';
import './BusinessAdminTabLinksCard.css';
import DataImportLoader, { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import LightTooltip from '@/components/tooltip-component/TooltipComponent';
import { BusinessPageContext } from './BusinessPage';
import LinksSection from '@/shared-components/LinksSection/LinksSection';
import RenderRow from '@/shared-components/RenderRow/RenderRow';

export default function BusinessAdminTabLinksCard() {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);

  const match = useMatch({
    path: '/broker/dashboard/businesses/:businessId',
  });
  const api = useAPI();

  const { users, refreshUsers, planMetadata, plan, links, refreshLinks } = useContext(BusinessPageContext);
  const businessId: string | undefined = match?.params.businessId as string;
  const [tooltipVisible, setTooltipVisible] = useState(Array(links.length).fill(false));

  const [localLinks, setLocalLinks] = useState<Link[]>(
    links.filter((link): link is Link => link.subjectType === 'USER')
  );

  const [localBusinessLinks, setLocalBusinessLinks] = useState<Link[]>(
    links.filter((link): link is Link => link.subjectType === 'BUSINESS')
  );

  useEffect(() => {
    setLocalLinks(links.filter((link): link is Link => link.subjectType === 'USER'));
    setLocalBusinessLinks(links.filter((link): link is Link => link.subjectType === 'BUSINESS'));
  }, [links]);

  const handleAddLink = () => {
    const updatedLinks: Link[] = [
      ...localLinks,
      {
        linkURL: '',
        visits: 0,
        user: '',
        mode: Mode.ADD,
        type: SessionType.CUSTOMS_ONBOARDING,
        subjectType: 'USER',
      },
    ];
    setLocalLinks(updatedLinks);
  };

  const handleAddBusinessLink = () => {
    const updatedBusinessLinks: Link[] = [
      ...localBusinessLinks,
      {
        linkURL: '',
        visits: 0,
        business: '',
        mode: Mode.ADD,
        type: SessionType.BUSINESS_SIGNUP,
        subjectType: 'BUSINESS',
      },
    ];
    setLocalBusinessLinks(updatedBusinessLinks);
  };

  const handleBusinessLinkChange = (index: number, key: keyof Link, value: string) => {
    const updatedBusinessLinks = localBusinessLinks.map((link, idx) =>
      idx === index ? { ...link, [key]: value } : link
    );
    setLocalBusinessLinks(updatedBusinessLinks);
  };

  const handleLinkChange = (index: number, key: keyof Link, value: any) => {
    const updatedLinks = [...localLinks].map((link, idx) => (idx === index ? { ...link, [key]: value } : link));
    setLocalLinks(updatedLinks);
  };

  const handleEditRow = (indexToUpdate: number) => {
    const updatedLinks = [...localLinks].map((link, index) =>
      index === indexToUpdate ? { ...link, mode: Mode.EDIT } : link
    );
    setLocalLinks(updatedLinks);
  };

  const handleBusinessEditRow = (indexToUpdate: number) => {
    const updatedLinks = localBusinessLinks.map((link, index) =>
      index === indexToUpdate ? { ...link, mode: Mode.EDIT } : link
    );
    setLocalBusinessLinks(updatedLinks);
  };

  const handleSaveRow = (indexToUpdate: number) => {
    const updatedLinks = [...localLinks].map((link, index) =>
      index === indexToUpdate ? { ...link, mode: Mode.VIEW } : link
    );
    setLocalLinks(updatedLinks);
  };

  const handleCancelEditRow = (indexToUpdate: number, isBusinessLink: boolean) => {
    if (isBusinessLink) {
      const updatedBusinessLinks = localBusinessLinks.filter((_, index) => index !== indexToUpdate);
      setLocalBusinessLinks(updatedBusinessLinks);
    } else {
      const updatedLinks = localLinks.filter((_, index) => index !== indexToUpdate);
      setLocalLinks(updatedLinks);
    }
  };

  const handleDeleteLink = (link: Link, index: number, isBusinessLink: boolean) => {
    setLoadingStatus(LoadingStatus.LOADING);

    if (link.sessionId) {
      api.deleteSession(link.sessionId as string).then((response) => {
        setLoadingStatus(LoadingStatus.SUCCESS);
        refreshLinks();
      });
    } else {
      if (isBusinessLink) {
        const newBusinessLinks = [...localBusinessLinks];
        newBusinessLinks.splice(index, 1);
        setLocalBusinessLinks(newBusinessLinks);
      } else {
        const newLinks = [...localLinks];
        newLinks.splice(index, 1);
        setLocalLinks(newLinks);
      }
    }
  };

  const handleSaveLink = (indexToUpdate: number, isBusinessLink: boolean) => {
    const linkToUpdate = isBusinessLink ? localBusinessLinks[indexToUpdate] : localLinks[indexToUpdate];

    setLoadingStatus(LoadingStatus.LOADING);

    if (linkToUpdate.mode === Mode.ADD) {
      const newSession: CreateSessionRequest = isBusinessLink
        ? {
            business: businessId,
            sessionType: SessionType.BUSINESS_SIGNUP,
          }
        : {
            business: businessId,
            user: (linkToUpdate as Link).user || '',
            email: (linkToUpdate as Link).user || '',
            sessionType: linkToUpdate.type,
          };

      api
        .createSession(newSession)
        .then(() => {
          setLoadingStatus(LoadingStatus.SUCCESS);
          refreshLinks();
        })
        .catch(() => {
          setLoadingStatus(LoadingStatus.ERROR);
        });
    } else if (linkToUpdate.mode === Mode.EDIT) {
      // Handle EDIT mode if necessary
    }

    const updatedLinks = isBusinessLink
      ? localBusinessLinks.map((link, index) => (index === indexToUpdate ? { ...link, mode: Mode.VIEW } : link))
      : localLinks.map((link, index) => (index === indexToUpdate ? { ...link, mode: Mode.VIEW } : link));

    if (isBusinessLink) {
      setLocalBusinessLinks(updatedLinks as Link[]);
    } else {
      setLocalLinks(updatedLinks as Link[]);
    }
  };

  const CopyableTableRow = ({ link, index, handleDeleteLink }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleCopyToClipboard = (text) => {
      const showTooltip = () => {
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 1400);
      };

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            showTooltip();
          })
          .catch((err) => {
            console.error('Failed to copy text: ', err);
          });
      } else {
        // Fallback for older browsers
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          showTooltip();
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
        document.body.removeChild(textArea);
      }
    };

    return (
      <TableRow key={link.shortLink}>
        <TableCell>{SessionTypeText[link.type]}</TableCell>
        {link.email && <TableCell>{link.email}</TableCell>}
        <TableCell>
          <LightTooltip open={tooltipVisible} title={'Link copied to clipboard'}>
            <a className="link-container" onClick={() => handleCopyToClipboard(link.linkURL)}>
              {link.linkURL}
            </a>
          </LightTooltip>
        </TableCell>
        <TableCell>{link.visits}</TableCell>
        <TableCell className="action-icons">
          <IconButton>
            <DeleteIcon onClick={() => handleDeleteLink(link, index, link.subjectType === 'BUSINESS')} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const renderUserLinksRow = (link, index) => (
    <RenderRow
      link={link}
      index={index}
      handleLinkChange={handleLinkChange}
      handleCancelEditRow={(i) => handleCancelEditRow(i, false)}
      handleSaveLink={(i) => handleSaveLink(i, false)}
      handleDeleteLink={(i) => handleDeleteLink(link, i, false)}
      config={{
        typeComponent: (link, index, handleLinkChange) => (
          <Select
            id="demo-simple-select"
            fullWidth
            onChange={(e) => handleLinkChange(index, 'type', e.target.value)}
            size="small"
            value={link.type || ''}
            MenuProps={{
              disablePortal: true,
            }}
          >
            {Object.keys(SessionType).map((key) => (
              <MenuItem
                key={key}
                value={SessionType[key]}
                style={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  padding: '8px',
                }}
              >
                {SessionTypeText[key]}
              </MenuItem>
            ))}
          </Select>
        ),
        userComponent: (link, index, handleLinkChange) => (
          <Select
            id="demo-simple-select"
            fullWidth
            size="small"
            value={link.user}
            onChange={(e) => handleLinkChange(index, 'user', e.target.value)}
            MenuProps={{
              disablePortal: true,
            }}
          >
            {users?.map((businessUser) => (
              <MenuItem
                key={businessUser.email}
                value={businessUser.email}
                style={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  padding: '8px',
                }}
              >
                {businessUser.email}
              </MenuItem>
            ))}
          </Select>
        ),
        visitsComponent: (link) => <>{link.visits}</>,
        CopyableTableRow: CopyableTableRow,
      }}
    />
  );

  const renderBusinesLinksRow = (link, index) => (
    <RenderRow
      link={link}
      index={index}
      handleLinkChange={handleLinkChange}
      handleCancelEditRow={(i) => handleCancelEditRow(i, true)}
      handleSaveLink={(i) => handleSaveLink(i, true)}
      handleDeleteLink={(i) => handleDeleteLink(link, i, true)}
      config={{
        typeComponent: () => <>{SessionTypeText.BUSINESS_SIGNUP}</>,
        userComponent: null,
        visitsComponent: (link) => <>{link.visits}</>,
        CopyableTableRow: CopyableTableRow,
      }}
    />
  );

  return (
    <>
      <Card className="my-shipments-card">
        <CardContent style={{ position: 'relative' }}>
          <div className="header-question">
            <Typography>Links</Typography>
            <div className="user-links">
              <LinksSection
                title="User Links"
                links={localLinks}
                renderRow={renderUserLinksRow}
                onAddClick={handleAddLink}
                columnNames={['Type', 'User', 'URL', 'Visits']}
              />
            </div>
            <div className="organization-links">
              <LinksSection
                title="Organization Links"
                links={localBusinessLinks}
                renderRow={renderBusinesLinksRow}
                onAddClick={handleAddBusinessLink}
                columnNames={['Type', 'URL', 'Visits']}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      <DataImportLoader
        loadingState={{ loadingStatus }}
        loadingText={'Saving link...'}
        successText={'Successfully saved link'}
        errorText={'Error saving link'}
      />
    </>
  );
}
