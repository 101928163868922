import React from 'react';
import ImportalCard from '@/shared-components/ImportalCard';
import ImportalMoreOptionsButton from '@/shared-components/ImportalMoreOptionsButton/ImportalMoreOptionsButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface ImportalReportCardProps {
  title: string;
  menuOptions?: { label: string; onClick: () => void }[];
  viewFullReport?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode; // Allow children to be passed directly
}

export default function ImportalReportCard({
  title,
  menuOptions = [],
  viewFullReport = false,
  style = {},
  children,
}: ImportalReportCardProps) {
  const cardStyle: React.CSSProperties = {
    borderRadius: '20px',
    minHeight: '300px',
    position: 'relative', // Enables absolute positioning inside the card
    ...style,
  };

  const viewReportContainer: React.CSSProperties = {
    color: '#525256',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute', // Fixes the container to the bottom right
    bottom: '16px',
    right: '16px',
    cursor: 'pointer', // Adds a pointer cursor for better UX
  };

  const viewReportIconContainer: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
  };

  return (
    <ImportalCard
      style={cardStyle}
      title={title}
      topRightActionButton={
        menuOptions.length > 0 ? <ImportalMoreOptionsButton menuOptions={menuOptions} /> : undefined
      }
    >
      <div style={{ height: style.height || '100%', paddingBottom: '24px' }}>{children}</div>
      {viewFullReport && (
        <div style={viewReportContainer}>
          <div>View Full Report</div>
          <div style={viewReportIconContainer}>
            <ChevronRightIcon />
          </div>
        </div>
      )}
    </ImportalCard>
  );
}
