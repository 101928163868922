import React from 'react';
import { Card, Box, Typography, Grid } from '@mui/material';
import {
  EntryHeaderInfo,
  modeOfTransportationToDescription,
  entryTypeToDescription,
  paymentTypeToDescription,
} from 'common/interfaces/entry';
import {
  DatePickerWithEditMode,
  SelectWithEditMode,
  TextFieldWithEditMode,
} from '@/broker-app/pages/shipments/EntryPrepMisc';

const styles = {
  container: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  header: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: '16px',
    paddingBottom: '2px',
    borderBottom: '1px solid #D1D1D1',
    marginBottom: '8px',
  },
  subheader: {
    fontSize: '12px',
    color: '#525256',
    marginBottom: '16px',
  },
  fieldLabel: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: 'black',
  },
  fieldValue: {
    fontSize: '12px',
    color: '#525256',
    marginBottom: '8px',
  },
};

interface EntryHeaderInfoProps {
  entryHeaderInfo: EntryHeaderInfo;
  onEntryHeaderInfoChanged: (arg0: EntryHeaderInfo) => void;
}

const EntryHeaderInfoCard: React.FC<EntryHeaderInfoProps> = ({ entryHeaderInfo, onEntryHeaderInfoChanged }) => {
  return (
    <Card sx={styles.container}>
      <Typography sx={styles.header}>Header Info</Typography>
      <div>
        <Typography sx={styles.fieldLabel}>Description of Merchandise</Typography>
        <TextFieldWithEditMode
          value={entryHeaderInfo.descriptionOfMerchandise}
          onSaveClicked={(descriptionOfMerchandise) => {
            onEntryHeaderInfoChanged({ ...entryHeaderInfo, descriptionOfMerchandise });
          }}
          viewModeProps={styles.fieldValue}
        />
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography sx={styles.fieldLabel}>Entry Type</Typography>
            <SelectWithEditMode
              value={entryHeaderInfo.entryType}
              onSaveClicked={(entryType) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, entryType });
              }}
              options={Object.keys(entryTypeToDescription)}
              optionToDisplayText={entryTypeToDescription}
              viewModeProps={styles.fieldValue}
            />
          </div>

          <div>
            <Typography sx={styles.fieldLabel}>Freight Charges</Typography>
            <TextFieldWithEditMode
              value={entryHeaderInfo.freightCharges}
              onSaveClicked={(freightCharges) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, freightCharges });
              }}
              viewModeProps={styles.fieldValue}
            />
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Gross Weight</Typography>
            <TextFieldWithEditMode
              value={entryHeaderInfo.grossWeight}
              onSaveClicked={(grossWeight) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, grossWeight });
              }}
              viewModeProps={styles.fieldValue}
            />
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Total Entry Value</Typography>
            <TextFieldWithEditMode
              value={entryHeaderInfo.totalEntryValue}
              onSaveClicked={(totalEntryValue) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, totalEntryValue });
              }}
              viewModeProps={styles.fieldValue}
            />
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Location of Goods</Typography>
            <Typography sx={styles.fieldValue}>{entryHeaderInfo.locationOfGoods}</Typography>
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Port of Entry</Typography>
            <TextFieldWithEditMode
              value={entryHeaderInfo.portOfEntry}
              onSaveClicked={(portOfEntry) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, portOfEntry });
              }}
              viewModeProps={styles.fieldValue}
            />
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Processing Port</Typography>
            <TextFieldWithEditMode
              value={entryHeaderInfo.processingPort}
              onSaveClicked={(processingPort) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, processingPort });
              }}
              viewModeProps={styles.fieldValue}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography sx={styles.fieldLabel}>Mode of Transportation</Typography>
            <SelectWithEditMode
              value={entryHeaderInfo.modeOfTransportation}
              onSaveClicked={(modeOfTransportation) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, modeOfTransportation });
              }}
              options={Object.keys(modeOfTransportationToDescription)}
              optionToDisplayText={modeOfTransportationToDescription}
              viewModeProps={styles.fieldValue}
            />
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Estimated Entry Date</Typography>
            <DatePickerWithEditMode
              value={entryHeaderInfo.estimatedEntryDate ? new Date(entryHeaderInfo.estimatedEntryDate) : new Date()}
              onSaveClicked={(estimatedEntryDate) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, estimatedEntryDate: estimatedEntryDate!.toString() });
              }}
              viewModeProps={styles.fieldValue}
            />
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Date of Import</Typography>
            <DatePickerWithEditMode
              value={entryHeaderInfo.dateOfImport ? new Date(entryHeaderInfo.dateOfImport) : new Date()}
              onSaveClicked={(dateOfImport) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, dateOfImport: dateOfImport!.toString() });
              }}
              viewModeProps={styles.fieldValue}
            />
          </div>
          <div>
            <Typography sx={styles.fieldLabel}>Payment Type</Typography>
            <SelectWithEditMode
              value={entryHeaderInfo.paymentType || ''}
              onSaveClicked={(paymentType) => {
                onEntryHeaderInfoChanged({ ...entryHeaderInfo, paymentType });
              }}
              options={Object.keys(paymentTypeToDescription)}
              optionToDisplayText={paymentTypeToDescription}
              viewModeProps={styles.fieldValue}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EntryHeaderInfoCard;
