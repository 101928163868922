import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface SignUpFormFieldsProps {
  handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handlePhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTermsConditionsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setHidePassword: (hide: boolean) => void;
  hidePassword: boolean;
  phoneNumber: string;
  emailError: string;
  isLoading: boolean;
  termsConditions: boolean;
  errorText: string;
}

const SignUpFormFields: React.FC<SignUpFormFieldsProps> = ({
  handleFormChange,
  handleSubmit,
  handlePhoneNumberChange,
  handleTermsConditionsChange,
  setHidePassword,
  hidePassword,
  phoneNumber,
  emailError,
  isLoading,
  termsConditions,
  errorText,
}) => {
  return (
    <Box component="form" onChange={handleFormChange} onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <div className="sign-up-and-features-container">
        <div className="sign-up-container">
          <div className="name-row">
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600' }}>First Name</Typography>
              </div>
              <div>
                <TextField autoComplete="given-name" name="firstName" required fullWidth id="firstName" autoFocus />
              </div>
            </div>
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600' }}>Last Name</Typography>
              </div>
              <div>
                <TextField required fullWidth id="lastName" name="lastName" autoComplete="family-name" />
              </div>
            </div>
          </div>

          <div className="email-password-row">
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600' }}>Email</Typography>
              </div>
              <div>
                <TextField
                  error={!!emailError}
                  helperText={emailError}
                  required
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                />
              </div>
            </div>
            <div className="signup-form-field-container">
              <div className="business-form-field-label">
                <Typography sx={{ fontWeight: '600' }}>Password</Typography>
              </div>
              <TextField
                type={hidePassword ? 'password' : 'text'}
                required
                fullWidth
                id="password"
                name="password"
                autoComplete="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={hidePassword ? 'show password' : 'hide password'}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setHidePassword(!hidePassword)}
                        >
                          {hidePassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <div className="signup-form-field-container business-phone-container">
            <div className="business-form-field-label">
              <Typography sx={{ fontWeight: '600' }}>Business Phone</Typography>
            </div>
            <div>
              <TextField
                required
                fullWidth
                name="phone"
                type="phone"
                id="phone"
                onChange={handlePhoneNumberChange}
                value={phoneNumber}
                autoComplete="business-phone"
              />
            </div>
          </div>

          <div className="terms-conditions-container">
            <input type="checkbox" id="acceptTerms" onChange={handleTermsConditionsChange} />
            <Typography sx={{ fontSize: '12px', color: '#525256' }}>
              By signing up for an account, you agree to our{' '}
              <a target="_blank" href="https://www.importal.com/terms-of-use">
                terms and conditions
              </a>{' '}
              and{' '}
              <a target="_blank" href="https://www.importal.com/privacy-policy">
                privacy policy.
              </a>
            </Typography>
          </div>

          <Grid container justifyContent="center">
            <div>
              <Button
                type="submit"
                fullWidth
                sx={{
                  marginTop: '24px!important',
                  fontWeight: '700',
                  textTransform: 'none',
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  fontSize: '24px!important',
                }}
                className="signup-button-green"
                variant="contained"
                disabled={isLoading || termsConditions}
              >
                {isLoading ? <CircularProgress color="success" size={20} /> : 'Sign Up'}
              </Button>
            </div>
          </Grid>

          <Grid className="error-text">{errorText}</Grid>
        </div>
      </div>
    </Box>
  );
};

export default SignUpFormFields;
