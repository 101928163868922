import { Card, CardContent, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import { DocumentTypeToText } from 'common/interfaces/document';
import moment from 'moment/moment';
import { EntryPrepDocForDisplay } from 'common/interfaces/documentParsing';

export interface EntryDocumentsIncludedProps {
  entryPrepDocs: EntryPrepDocForDisplay[];
  onClickChangeDocuments: () => void;
}

export default function IncludedEntryPrepDocsPreview({
  entryPrepDocs,
  onClickChangeDocuments,
}: EntryDocumentsIncludedProps) {
  const theme = useTheme();

  const viewAllText = 'Change ›';
  return (
    <>
      <Card sx={{ padding: '24px!important' }}>
        <CardContent sx={{ fontSize: '12px', padding: '0px!important' }}>
          <div
            style={{
              fontWeight: '700',
              fontSize: '16px',
              paddingBottom: '2px',
              borderBottom: '1px solid #D1D1D1',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Documents</span>

              <span
                onClick={onClickChangeDocuments}
                style={{
                  fontSize: 'smaller',
                  alignSelf: 'center',
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                }}
              >
                {viewAllText}
              </span>
            </div>
          </div>
          <div style={{ position: 'sticky', top: 0 }}>
            {entryPrepDocs.length ? (
              <>
                <List>
                  {entryPrepDocs.map((doc, index) => {
                    return (
                      <ListItem>
                        <EntryPrepDocPreview entryPrepDoc={doc} />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            ) : (
              <>
                <div
                  style={{
                    height: '100px',
                    width: '100%',
                    alignContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  No Documents
                </div>
              </>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
}

interface EntryPrepDocPreviewProps {
  entryPrepDoc: EntryPrepDocForDisplay;
}

function EntryPrepDocPreview({ entryPrepDoc }: EntryPrepDocPreviewProps) {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            textAlign: 'left',
            whiteSpace: 'nowrap',
            width: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <div style={{ fontSize: '11px', textWrap: 'wrap' }}>
            <b>{entryPrepDoc.document.fileName}</b>
          </div>
          <div style={{ fontSize: '10px' }}>
            {DocumentTypeToText[entryPrepDoc.document.type]} (
            {moment((entryPrepDoc.document as any).createdAt)
              .tz('America/Chicago')
              .format('MM/DD/YY')}
            )
          </div>
          <div style={{ fontSize: '10px' }}>
            {entryPrepDoc.properties.invoiceNumber ? entryPrepDoc.properties.invoiceNumber : 'No Invoice Number'}
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: '10px', display: 'flex', alignItems: 'center' }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`/documents/${entryPrepDoc.document._id}`, '_blank', 'width=800,height=600');
                }}
              >
                View Document
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
