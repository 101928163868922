import * as React from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import EntryPrepApplicationFiling from './EntryPrepApplicationFiling';
import { Entry, EntryFilingStateShortDescription, EntryFilingStatus } from 'common/interfaces/entry';
import { ShipmentPageContext } from '@/broker-app/pages/shipments/ShipmentPage';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import ImportalCard from '@/shared-components/ImportalCard';

import ImportalSimpleTable from '@/shared-components/ImportalSimpleTable';
import AddIcon from '@mui/icons-material/Add';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import Link from '@mui/material/Link';
import { Delete, Visibility } from '@mui/icons-material';
import { AddEntryDialog } from '@/broker-app/pages/shipments/AddEntryDialog';
import { ImportalBreadCrumbs, ImportalBreadCrumbsHandle } from '@/shared-components/ImportalBreadCrumbs';
import { IconButton, Tooltip } from '@mui/material';
import DeleteConfirmationModal from '@/shared-components/DeleteConfirmationModal/DeleteConfirmationModal';
import { buildEntryNumberNetchbUrl } from '@/utils/entryUtils';
import { mmmddyyHmmDate } from '@/utils/activePlanUtils';
import AddOrEditDocumentsForEntry from '@/broker-app/pages/shipments/AddOrEditDocumentsForEntry';

export interface IEntryPrepTabContext {
  loadingStatus: LoadingStatus;
  setLoadingStatus: (arg0: LoadingStatus) => void;
  setLoadingText: (text: string) => void;
  setSuccessText: (text: string) => void;
  setErrorText: (text: string) => void;
  refreshEntriesForShipment: () => void;
}

export const EntryPrepTabContext = createContext<IEntryPrepTabContext>({
  loadingStatus: LoadingStatus.NOT_LOADING,
  setLoadingStatus: () => {},
  setLoadingText: () => {},
  setSuccessText: () => {},
  setErrorText: () => {},
  refreshEntriesForShipment: () => {},
});

const { Provider } = EntryPrepTabContext;

interface EntryPrepTabProps {
  shipmentId: string;
}

export default function EntryPrepTab({ shipmentId }: EntryPrepTabProps) {
  const { businessId, refreshEntries } = useContext(ShipmentPageContext);
  const api = useAPI();

  const [existingEntries, setExistingEntries] = useState<Entry[]>([]);

  const [importalEntry, setImportalEntry] = useState<Entry | undefined>();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.NOT_LOADING);
  const [loadingText, setLoadingText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');

  const [showDeleteEntryConfirmModal, setShowDeleteEntryConfirmModal] = useState(false);
  const [entryForDeleting, setEntryForDeleting] = useState<Entry | undefined>();

  const [addEntryDialogOpen, setAddEntryDialogOpen] = useState(false);
  const breadcrumbsRef = useRef<ImportalBreadCrumbsHandle>(null);

  useEffect(() => {
    refreshEntriesForShipment();
  }, []);

  const refreshEntriesForShipment = () => {
    api
      .getEntriesForShipment(shipmentId)
      .then(({ data }) => {
        setExistingEntries(data);
      })
      .catch((err) => {
        console.error('error getting all entries for shipment', err);
      });
  };

  const createNewEntry = async (partialEntry: Partial<Entry> = {}) => {
    try {
      const { data: entry } = await api.createEntry(partialEntry, businessId, shipmentId);
      setImportalEntry(entry);
      refreshEntriesForShipment();
      refreshEntries();
      return entry;
    } catch (err) {
      console.error('error creating new entry', err);
      throw err;
    }
  };

  const onConfirmCreateNewEntry = async (internalWorkingName?: string) => {
    const newEntry = await createNewEntry({ internalWorkingName });
    breadcrumbsRef.current?.pushItem(newEntry);
    setAddEntryDialogOpen(false);
  };

  const onConfirmAddEntryFromReferenceNumber = async (entryNumber: string, internalWorkingName?: string) => {
    const newEntry = await createNewEntry({
      entryNumber,
      internalWorkingName,
      entryFilingStatus: EntryFilingStatus.SENT_TO_NETCHB,
    });
    breadcrumbsRef.current?.pushItem(newEntry);
    setAddEntryDialogOpen(false);
  };

  const handleConfirmDeleteEntry = (confirm: boolean) => {
    if (confirm) {
      api
        .deleteEntry(entryForDeleting!._id!.toString())
        .then(() => {
          setShowDeleteEntryConfirmModal(false);
          setEntryForDeleting(undefined);
          refreshEntriesForShipment();
        })
        .catch((err) => {
          console.error('error deleting entry', err);
        });
    } else {
      setShowDeleteEntryConfirmModal(false);
    }
  };

  return (
    <Provider
      value={{
        loadingStatus,
        setLoadingStatus,
        setLoadingText,
        setSuccessText,
        setErrorText,
        refreshEntriesForShipment,
      }}
    >
      <ImportalBreadCrumbs
        ref={breadcrumbsRef}
        elements={[
          {
            render: ({ popItem, pushItem, item }) => (
              <ImportalCard
                title={'Entries'}
                topRightActionButton={
                  <ImportalPrimaryButton
                    endIcon={<AddIcon />}
                    onClick={() => {
                      setAddEntryDialogOpen(true);
                    }}
                    text={'Add Entry'}
                  />
                }
              >
                <ImportalSimpleTable
                  data={existingEntries}
                  columns={['Entry Number', 'Internal Name', 'Date Created', 'Filing Status', '']}
                  renderListItem={(datum: Entry, index) => (
                    <>
                      <Link
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                        component="a"
                        onClick={(e) => {
                          e.preventDefault();
                          setImportalEntry(datum);
                          pushItem(datum);
                        }}
                      >
                        <span>{datum.entryNumber || 'In Progress'}</span>
                      </Link>
                      <span>{datum.internalWorkingName || '--'}</span>
                      <span>{mmmddyyHmmDate(datum.createdAt)}</span>
                      <span>{EntryFilingStateShortDescription[datum.entryFilingStatus]}</span>
                      <div style={{ display: 'flex' }}>
                        <Tooltip title="View in Netchb" placement={'right'}>
                          <IconButton
                            onClick={() => {
                              //open new window with entry in netchb...
                              const link = document.createElement('a');
                              link.href = buildEntryNumberNetchbUrl(datum.entryNumber!);
                              link.target = '_blank';
                              link.click();
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>

                        <IconButton
                          onClick={() => {
                            // delete entry
                            setEntryForDeleting(datum);
                            setShowDeleteEntryConfirmModal(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    </>
                  )}
                />
              </ImportalCard>
            ),
            label: 'All Entries',
          },
          {
            render: ({ popItem, pushItem, item }) => (
              <EntryPrepApplicationFiling
                entry={importalEntry!}
                setEntry={setImportalEntry}
                onClickChangeDocuments={() => {
                  pushItem({});
                }}
              />
            ),
            label: (entry: Entry) => {
              let entryNumber = importalEntry?.entryNumber || 'New Entry';
              if (importalEntry?.internalWorkingName) {
                entryNumber = `${entryNumber} (${importalEntry.internalWorkingName})`;
              }
              return entryNumber;
            },
          },
          {
            render: ({ popItem, pushItem, item }) => (
              <AddOrEditDocumentsForEntry
                entry={importalEntry!}
                onEntryChanged={setImportalEntry}
                popAddOrEditScreen={popItem}
              />
            ),
            label: (entry: Entry) => {
              return entry.entryFilingStatus === EntryFilingStatus.PREPARING_DOCUMENTS
                ? 'Change Documents'
                : 'Review & Add Documents';
            },
          },
        ]}
      />

      <AddEntryDialog
        open={addEntryDialogOpen}
        onClose={() => {
          setAddEntryDialogOpen(false);
        }}
        onConfirmCreateNewEntry={onConfirmCreateNewEntry}
        onAddEntryFromReferenceNumber={onConfirmAddEntryFromReferenceNumber}
      />

      <DeleteConfirmationModal
        open={showDeleteEntryConfirmModal}
        onClose={handleConfirmDeleteEntry}
        title={'Delete Entry'}
        itemName={entryForDeleting?.entryNumber ? `Entry with Entry Number: ${entryForDeleting?.entryNumber}` : 'Entry'}
      />
    </Provider>
  );
}
