import React from 'react';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalSecondaryButton from '@/shared-components/ImportalSecondaryButton/ImportalSecondaryButton';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

interface Props {
  open: boolean;
  onClose: () => void;
  loadingStatus: LoadingStatus;
  potentialChangesToEntry: any;
  onConfirmChanges: () => void;
}

export default function PreviewEntryDataChangeModal({
  open,
  onClose,
  loadingStatus,
  potentialChangesToEntry,
  onConfirmChanges,
}: Props) {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={(event, reason) => {
        // Prevent closing the modal via backdrop click
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
    >
      <DialogContent
        style={{
          // minWidth: 'min(1000px, calc(100vw - 400px))',
          // maxWidth: 'calc(100vw - 200px)',
          minHeight: 'min(600px, calc(100vh - 400px))',
          maxHeight: 'calc(100vh - 200px)',
          // display: 'flex',
          // flexDirection: 'column',
          // position: 'relative',
        }}
      >
        {/* Close button in top-right */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        {loadingStatus === LoadingStatus.LOADING && (
          <Box
            id="send-to-netchb-loading-indicator-container"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: '325px' }}
          >
            <CircularProgress id="send-to-netchb-loading-indicator" color="primary" />
          </Box>
        )}

        {loadingStatus !== LoadingStatus.LOADING && (
          <div style={{ flex: 1, overflowY: 'auto', paddingTop: '8px' }}>
            <div style={{ flex: 1, overflowY: 'auto', paddingTop: '32px' }}>
              {potentialChangesToEntry && (
                <div style={{ display: 'grid', gap: '24px' }}>
                  {/* High-level summary */}
                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '16px' }}>
                    <div style={{ textAlign: 'center' }}>
                      <h4>Documents Added</h4>
                      <p style={{ margin: 0 }}>{potentialChangesToEntry.documentsAdded ?? 0}</p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <h4>Documents Removed</h4>
                      <p style={{ margin: 0 }}>{potentialChangesToEntry.documentsRemoved ?? 0}</p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <h4>Documents Changed</h4>
                      <p style={{ margin: 0 }}>{potentialChangesToEntry.documentsChanged ?? 0}</p>
                    </div>
                  </div>

                  {/* Data changed list/table */}
                  <div>
                    <h4>Data Changes</h4>

                    {Array.isArray(potentialChangesToEntry.dataChanged) &&
                    potentialChangesToEntry.dataChanged.length > 0 ? (
                      <table
                        style={{
                          width: '100%',
                          borderCollapse: 'collapse',
                          marginTop: '8px',
                        }}
                      >
                        <thead>
                          <tr style={{ borderBottom: '1px solid #ccc' }}>
                            <th style={{ textAlign: 'left', padding: '8px' }}>Title</th>
                            <th style={{ textAlign: 'left', padding: '8px' }}>Old Value</th>
                            <th style={{ textAlign: 'left', padding: '8px' }}>New Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {potentialChangesToEntry.dataChanged.map((change: any, index: number) => (
                            <tr
                              key={index}
                              style={{
                                borderBottom: '1px solid #eee',
                              }}
                            >
                              <td style={{ padding: '8px' }}>{change.title}</td>
                              <td style={{ padding: '8px' }}>{change.oldValue}</td>
                              <td style={{ padding: '8px' }}>{change.newValue}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p style={{ marginTop: '8px' }}>No data changes</p>
                    )}
                  </div>

                  {/* Optionally show dataAdded / dataRemoved if needed */}

                  <div>
                    <h4>Data Added</h4>
                    {potentialChangesToEntry.dataAdded?.length ? (
                      potentialChangesToEntry.dataAdded.map((item: any, idx: number) => (
                        <div key={idx}>{JSON.stringify(item)}</div>
                      ))
                    ) : (
                      <p>No data added</p>
                    )}
                  </div>

                  <div>
                    <h4>Data Removed</h4>
                    {potentialChangesToEntry.dataRemoved?.length ? (
                      potentialChangesToEntry.dataRemoved.map((item: any, idx: number) => (
                        <div key={idx}>{JSON.stringify(item)}</div>
                      ))
                    ) : (
                      <p>No data removed</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </DialogContent>

      <DialogActions sx={{ marginTop: '16px', justifyContent: 'flex-end' }}>
        <ImportalSecondaryButton onClick={onClose} text={'Cancel'} />
        <ImportalPrimaryButton onClick={onConfirmChanges} text="Confirm" style={{ width: '140px' }} />
      </DialogActions>
    </Dialog>
  );
}
