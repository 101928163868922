import React, { useMemo } from 'react';
import * as d3 from 'd3';
import { ChartConfig, LegendPositionType } from 'common/interfaces/reports';
import { useChartDimensions } from '@/custom-hooks/reporting/useChartDimensions';
import { ImportalChartLegend } from '@/shared-components/ImportalChartLegend/ImportalChartLegend';

export interface Props {
  chartConfig: ChartConfig;
  data: Array<{
    [key: string]: any;
    color?: string;
  }>;
}

function getRandomColor(): string {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const chartSettings = {
  marginLeft: 1,
  marginTop: 12,
};

export default function ImportalPieChart({ chartConfig, data }: Props) {
  const [ref, dms] = useChartDimensions(chartSettings);

  const seriesData = useMemo(() => {
    if (!chartConfig?.pieConfig?.seriesNameKey || !chartConfig?.pieConfig?.seriesValueKey) {
      return [];
    }
    const { seriesNameKey, seriesValueKey } = chartConfig.pieConfig;

    return data.map((datum) => ({
      name: datum[seriesNameKey],
      value: parseFloat(datum[seriesValueKey]),
      color: datum.color ?? getRandomColor(),
    }));
  }, [chartConfig, data]);

  const arcs = useMemo(() => {
    const pie = d3
      .pie<{ name: string; value: number; color: string }>()
      .sort(null)
      .value((d) => d.value);

    return pie(seriesData);
  }, [seriesData]);

  const width = 50;
  const height = 50;

  const outerR = Math.min(width, height) / 2;

  const arcGen = useMemo(() => {
    return d3.arc<d3.PieArcDatum<{ name: string; value: number; color: string }>>().innerRadius(0).outerRadius(outerR);
  }, [outerR]);

  const labelArcGen = useMemo(() => {
    return d3
      .arc<d3.PieArcDatum<{ name: string; value: number; color: string }>>()
      .innerRadius(outerR * 0.5)
      .outerRadius(outerR * 0.5);
  }, [outerR]);

  const isColumnLayout =
    chartConfig.legendPosition === LegendPositionType.TOP || chartConfig.legendPosition === LegendPositionType.BOTTOM;

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: isColumnLayout ? 'column' : 'row',
  };

  const chartWrapperStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  };

  return (
    <div ref={ref} style={containerStyle}>
      {chartConfig.legendPosition === LegendPositionType.TOP && (
        <ImportalChartLegend
          seriesData={seriesData}
          chartConfig={chartConfig}
          legendPosition={chartConfig.legendPosition}
        />
      )}

      <figure style={{ ...chartWrapperStyle }}>
        <svg viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMidYMid meet">
          <title id="pie-title">{chartConfig.chartName}</title>
          <rect fill="#fff" />
          <g transform={`translate(${width / 2}, ${height / 2})`}>
            <g stroke="none">
              {arcs.map((arcData, i) => (
                <path key={`arc-${i}`} fill={arcData.data.color} d={arcGen(arcData) ?? undefined}>
                  <title>{`${arcData.data.name}: ${arcData.data.value.toLocaleString('en-US')}`}</title>
                </path>
              ))}
            </g>

            {chartConfig.showLabelInChart && (
              <g>
                {arcs.map((arcData, i) => {
                  const [labelX, labelY] = labelArcGen.centroid(arcData);

                  return (
                    <text
                      key={`label-${i}`}
                      transform={`translate(${labelX}, ${labelY})`}
                      fill="#fff"
                      fontWeight="bold"
                      textAnchor="middle"
                      style={{ pointerEvents: 'none', fontSize: '3px' }}
                    >
                      ${arcData.data.value.toLocaleString('en-US')}
                    </text>
                  );
                })}
              </g>
            )}
          </g>
        </svg>
      </figure>

      {chartConfig.legendPosition === LegendPositionType.BOTTOM && (
        <ImportalChartLegend
          seriesData={seriesData}
          chartConfig={chartConfig}
          legendPosition={chartConfig.legendPosition}
        />
      )}

      {chartConfig.legendPosition === LegendPositionType.LEFT && (
        <ImportalChartLegend
          seriesData={seriesData}
          chartConfig={chartConfig}
          legendPosition={chartConfig.legendPosition}
          styles={{ marginRight: '24px' }}
        />
      )}

      {chartConfig.legendPosition === LegendPositionType.RIGHT && (
        <ImportalChartLegend
          seriesData={seriesData}
          chartConfig={chartConfig}
          legendPosition={chartConfig.legendPosition}
          styles={{ marginLeft: '24px' }}
        />
      )}
    </div>
  );
}
