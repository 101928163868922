import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  CommercialInvoiceLineItem,
  CommercialInvoiceWithDocument,
  EntryPrepDocReviewStatus,
} from 'common/interfaces/documentParsing';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import { Box, Card, IconButton, MenuItem, TextField, Tooltip, useTheme } from '@mui/material';
import { formatMMMDDYYYYDate } from '@/utils/activePlanUtils';
import { ShipmentPageContext } from '@/broker-app/pages/shipments/ShipmentPage';
import { useAPI } from '@/api/APIContext';
import AddOrEditAProductModal from '@/pages/product-library/AddOrEditAProductModal';
import { ProductAction } from 'common/interfaces/product';
import { ImportalRequiredFieldNames, ProductFieldsConfig } from 'common/interfaces/productfields';
import { Inventory, Settings } from '@mui/icons-material';
import { EntryPrepTabContext } from '@/broker-app/pages/shipments/EntryPrepTab';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';
import { CurrencyUtility } from 'common/utilities/currencyUtility';
import { DatePickerWithEditMode, TextFieldWithEditMode } from '@/broker-app/pages/shipments/EntryPrepMisc';
import { ManageItemsStyles } from '@/broker-app/pages/shipments/ManageItemsStyles';

interface CommercialInvoiceParsedViewProps {
  commercialInvoice: CommercialInvoiceWithDocument;
  onCommercialInvoiceChanged: (arg0: CommercialInvoiceWithDocument) => void;
}

const styles = {
  parsedViewContainer: {
    alignItems: 'center',
    boxShadow: '0px 8px 16px 8px rgba(76, 103, 100, 0.05)',
    borderRadius: '8px',
    color: '#525256',
    padding: '24px',
  },
  parsedViewHeader: {
    marginBottom: '2px',
    marginTop: '0px',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
  },
  parsedViewSubheader: {
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '24px',
    color: '#525256',
  },
  parsedViewSubheaderContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  parsedViewSummarySection: {
    display: 'flex',
    width: '100%',
    fontSize: '12px',
    marginTop: '16px',
    gap: '24px',
  },
  parsedViewSummarySectionColumn: {
    flex: 1,
    position: 'relative' as 'relative',
  },
  parsedViewSummarySectionColumnHeader: {
    fontWeight: 'bold',
    color: 'black',
  },
  fieldContainer: {
    fontSize: '12px',
    color: '#525256',
    position: 'relative' as 'relative',
    display: 'inline-block',
  },
  importerAddressSpan: {
    marginRight: '3px',
    whiteSpace: 'nowrap',
  },
  editIconButton: {
    position: 'absolute' as 'absolute',
    right: '-20px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '16px',
  },
};

const CommercialInvoiceParsedView: React.FC<CommercialInvoiceParsedViewProps> = ({
  commercialInvoice,
  onCommercialInvoiceChanged,
}) => {
  const theme = useTheme();
  const api = useAPI();

  const { businessId } = useContext(ShipmentPageContext);
  const { loadingStatus, setLoadingStatus } = useContext(EntryPrepTabContext);
  const [addOrEditAProductOpen, setAddOrEditAProductOpen] = useState(false);
  const [productIDForViewingInModal, setProductIdForViewingInModal] = useState<string | undefined>();
  const [commercialInvoiceLineForUpsertProduct, setCommercialInvoiceLineForUpsertProduct] = useState<
    CommercialInvoiceLineItem | undefined
  >(undefined);
  const [productFieldsConfig, setProductFieldsConfig] = useState<ProductFieldsConfig>();

  useEffect(() => {
    setLoadingStatus(LoadingStatus.LOADING);
    api
      .getProductFields(businessId)
      .then(({ data }) => {
        setProductFieldsConfig(data);
        setLoadingStatus(LoadingStatus.NOT_LOADING);
      })
      .catch((err) => {
        console.error(err);
        console.error('error getting product fields config for business');
        setLoadingStatus(LoadingStatus.ERROR);
      });
  }, [businessId]);

  const onProductCreatedModifiedOrDeleted = (productId: string, action: ProductAction) => {
    switch (action) {
      case ProductAction.CREATED:
        // if product was created in this instance, then we now need to create 'imported product'
        // and attach to the CommercialInvoiceLineItem
        if (!commercialInvoiceLineForUpsertProduct) return;

        setLoadingStatus(LoadingStatus.LOADING);
        api
          .recordImportedProduct(
            productId,
            {
              invoiceNumber: commercialInvoice.invoiceNumber,
              invoiceDate: commercialInvoice.invoiceDate,
              commercialInvoice: commercialInvoice,
              lineItem: commercialInvoiceLineForUpsertProduct,
            },
            businessId
          )
          .then(({ data: importedProduct }) => {
            // now update the commercialInvoiceLineItem
            const indexForUpdate = commercialInvoice.lineItems.findIndex(
              (line) => line._id!.toString() === commercialInvoiceLineForUpsertProduct._id!.toString()
            );
            const updatedLineItem = commercialInvoice.lineItems[indexForUpdate];
            updatedLineItem.importedProductConnection = importedProduct._id;
            const updatedCommercialInvoice = { ...commercialInvoice };
            updatedCommercialInvoice[indexForUpdate] = updatedLineItem;

            onCommercialInvoiceChanged(commercialInvoice);

            setCommercialInvoiceLineForUpsertProduct(undefined);
            setProductIdForViewingInModal(undefined);
            setAddOrEditAProductOpen(false);
            setLoadingStatus(LoadingStatus.NOT_LOADING);
          })
          .catch((err) => {
            console.error('error getting product in parsed commercial invoice after creation');
            console.error(err);
            setLoadingStatus(LoadingStatus.ERROR);
          });

        api
          .getProductByID(productId)
          .then(({ data: newProduct }) => {})
          .catch((err) => {
            console.error('error getting product in parsed commercial invoice after creation');
            console.error(err);
          });

        break;
      case ProductAction.MODIFIED:
        setLoadingStatus(LoadingStatus.LOADING);
        api
          .getProductByID(productId)
          .then(({ data: updatedProduct }) => {
            setLoadingStatus(LoadingStatus.NOT_LOADING);
          })
          .catch((err) => {
            console.error('error getting product in parsed commercial invoice after modification');
            console.error(err);
            setLoadingStatus(LoadingStatus.ERROR);
          });

        break;
      case ProductAction.DELETED:
        // TODO: interesting corner case with deleting.
        // if you are on a paginating page, and the product that was deleted is the only thing on that page
        // i.e. last page with one item,
        // then you would want to mess with the paginating controls while deleting the product
        // i.e. move the user to the last page with contents since the products list will shrink

        break;
      default:
        console.log('no op');
    }
  };

  const computedProductPropertiesFromCommercialInvoiceLine = useMemo(() => {
    const properties = {};
    if (!commercialInvoiceLineForUpsertProduct) {
      return properties;
    }

    const primaryIdentifierField = productFieldsConfig?.fields.filter((field) => field.isPrimaryIdentifier);
    if (!primaryIdentifierField) return properties;

    properties[primaryIdentifierField[0].name] = commercialInvoiceLineForUpsertProduct.sku;
    properties[ImportalRequiredFieldNames.HTS] = commercialInvoiceLineForUpsertProduct.htsno;

    properties[ImportalRequiredFieldNames.COUNTRY_OF_ORIGIN] = commercialInvoiceLineForUpsertProduct.countryOfOrigin;

    properties[ImportalRequiredFieldNames.UNIT_COST] = commercialInvoiceLineForUpsertProduct.unitValue;

    properties[ImportalRequiredFieldNames.UNIT_COST_CURRENCY] = commercialInvoiceLineForUpsertProduct.currencyCode;

    properties[ImportalRequiredFieldNames.MATERIAL_COMPOSITION] =
      commercialInvoiceLineForUpsertProduct.materialComposition;

    properties[ImportalRequiredFieldNames.UNIT_WEIGHT] = commercialInvoiceLineForUpsertProduct.unitWeight;

    properties[ImportalRequiredFieldNames.UNIT_WEIGHT_UNITS] = 'kg';

    // keys from commercial invoice
    properties[ImportalRequiredFieldNames.SUPPLIER] = commercialInvoice.supplierName;

    return properties;
  }, [commercialInvoiceLineForUpsertProduct]);

  const manageCommercialInvoiceLineItemsConfig: ManageItemsConfig = {
    columns: [
      {
        header: 'PO #',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.poNumber}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.poNumber}
            onChange={(e) => setItem({ ...item, poNumber: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'Container #',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.containerNumber}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.containerNumber}
            onChange={(e) => setItem({ ...item, containerNumber: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'SKU',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.sku}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.sku}
            onChange={(e) => setItem({ ...item, sku: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'Description',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.description}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.description}
            onChange={(e) => setItem({ ...item, description: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'HTS',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.htsno}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.htsno}
            onChange={(e) => setItem({ ...item, htsno: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'Quantity',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.unitQuantity}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.unitQuantity}
            onChange={(e) => setItem({ ...item, unitQuantity: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'Unit Value',
        viewComponent: (item: CommercialInvoiceLineItem) => (
          <>{CurrencyUtility.formatCurrency(Number(item.unitValue), 'USD')}</>
        ),
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.unitValue}
            onChange={(e) => setItem({ ...item, unitValue: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'Total Value',
        viewComponent: (item: CommercialInvoiceLineItem) => (
          <>{CurrencyUtility.formatCurrency(Number(item.totalValue), 'USD')}</>
        ),
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.totalValue}
            onChange={(e) => setItem({ ...item, totalValue: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'COO',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.countryOfOrigin}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.countryOfOrigin}
            onChange={(e) => setItem({ ...item, countryOfOrigin: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'Net Wt.',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.netWeight}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.netWeight}
            onChange={(e) => setItem({ ...item, netWeight: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
      {
        header: 'Gross Wt.',
        viewComponent: (item: CommercialInvoiceLineItem) => <>{item.grossWeight}</>,
        editComponent: (item: CommercialInvoiceLineItem, setItem: (arg0: CommercialInvoiceLineItem) => void) => (
          <TextField
            value={item.grossWeight}
            onChange={(e) => setItem({ ...item, grossWeight: e.target.value })}
            fullWidth
            size="small"
            variant="standard"
            sx={{ '& .MuiInputBase-input.MuiInput-input': { fontSize: 12 } }}
          />
        ),
      },
    ],
    viewModeAdditionalIcons: [
      {
        icon: (item: CommercialInvoiceLineItem) => {
          const color = item.importedProductConnection ? theme.palette.primary.main : 'grey';
          const message: string = item.importedProductConnection
            ? 'This line item is matched to a product.'
            : 'No product found. Want to add one?';

          return (
            <Tooltip title={message} placement={'top'}>
              <Inventory style={{ color }} />
            </Tooltip>
          );
        },
        onClick: (item: CommercialInvoiceLineItem, index) => {
          if (item.importedProductConnection) {
            api
              .getProductForRecordedProductImported(item.importedProductConnection.toString())
              .then(({ data: product }) => {
                setCommercialInvoiceLineForUpsertProduct(item);
                setProductIdForViewingInModal(product._id!.toString());
                setAddOrEditAProductOpen(true);
              })
              .catch((err) => {
                console.error('error getting product for recorded import', err);
              });
          } else {
            setCommercialInvoiceLineForUpsertProduct(item);
            setProductIdForViewingInModal(undefined);
            setAddOrEditAProductOpen(true);
          }
        },
      },
    ],
  };

  return (
    <>
      <div style={{ fontSize: '12px' }}>
        <b>Invoice Number</b>
        <div style={{ color: '#525256' }}>
          <TextFieldWithEditMode
            value={commercialInvoice.invoiceNumber}
            onSaveClicked={(invoiceNumber) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, invoiceNumber });
            }}
          />
        </div>

        <b>Invoice Date</b>
        <div style={{ color: '#525256' }}>
          <DatePickerWithEditMode
            value={commercialInvoice.invoiceDate}
            onSaveClicked={(invoiceDate) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, invoiceDate });
            }}
          />
        </div>
      </div>
      <div>
        <h4 style={styles.parsedViewHeader}>Commercial Invoice</h4>
        <div style={styles.parsedViewSubheader}>
          <div>
            <TextFieldWithEditMode
              value={commercialInvoice.importerOfRecordName || ''}
              onSaveClicked={(importerOfRecordName) => {
                onCommercialInvoiceChanged({ ...commercialInvoice, importerOfRecordName });
              }}
              viewModeProps={styles.parsedViewSubheaderContent}
            />

            <div style={styles.parsedViewSubheaderContent}>
              <TextFieldWithEditMode
                value={commercialInvoice.importerOfRecordStreet || ''}
                onSaveClicked={(importerOfRecordStreet) => {
                  onCommercialInvoiceChanged({ ...commercialInvoice, importerOfRecordStreet });
                }}
                viewModeProps={styles.importerAddressSpan}
              />
              <TextFieldWithEditMode
                value={commercialInvoice.importerOfRecordCity || ''}
                onSaveClicked={(importerOfRecordCity) => {
                  onCommercialInvoiceChanged({ ...commercialInvoice, importerOfRecordCity });
                }}
                viewModeProps={styles.importerAddressSpan}
              />{' '}
              <TextFieldWithEditMode
                value={commercialInvoice.importerOfRecordState || ''}
                onSaveClicked={(importerOfRecordState) => {
                  onCommercialInvoiceChanged({ ...commercialInvoice, importerOfRecordState });
                }}
                viewModeProps={styles.importerAddressSpan}
              />{' '}
              <TextFieldWithEditMode
                value={commercialInvoice.importerOfRecordZip || ''}
                onSaveClicked={(importerOfRecordZip) => {
                  onCommercialInvoiceChanged({ ...commercialInvoice, importerOfRecordZip });
                }}
                viewModeProps={styles.importerAddressSpan}
              />{' '}
              <TextFieldWithEditMode
                value={commercialInvoice.importerOfRecordCountry || ''}
                onSaveClicked={(importerOfRecordCountry) => {
                  onCommercialInvoiceChanged({ ...commercialInvoice, importerOfRecordCountry });
                }}
                viewModeProps={styles.importerAddressSpan}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ fontSize: '12px', display: 'flex' }}></div>

      <div style={styles.parsedViewSummarySection}>
        <div style={styles.parsedViewSummarySectionColumn}>
          <div style={styles.parsedViewSummarySectionColumnHeader}>Supplier Information</div>
          <TextFieldWithEditMode
            value={commercialInvoice.supplierName || ''}
            onSaveClicked={(supplierName) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, supplierName });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.supplierStreet || ''}
            onSaveClicked={(supplierStreet) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, supplierStreet });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.supplierCity || ''}
            onSaveClicked={(supplierCity) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, supplierCity });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.supplierState || ''}
            onSaveClicked={(supplierState) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, supplierState });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.supplierZip || ''}
            onSaveClicked={(supplierZip) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, supplierZip });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.supplierCountry || ''}
            onSaveClicked={(supplierCountry) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, supplierCountry });
            }}
          />
        </div>
        <div style={styles.parsedViewSummarySectionColumn}>
          <div style={styles.parsedViewSummarySectionColumnHeader}>Manufacturer Information</div>
          <TextFieldWithEditMode
            value={commercialInvoice.manufacturerName || ''}
            onSaveClicked={(manufacturerName) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, manufacturerName });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.manufacturerStreet || ''}
            onSaveClicked={(manufacturerStreet) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, manufacturerStreet });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.manufacturerCity || ''}
            onSaveClicked={(manufacturerCity) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, manufacturerCity });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.manufacturerState || ''}
            onSaveClicked={(manufacturerState) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, manufacturerState });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.manufacturerZip || ''}
            onSaveClicked={(manufacturerZip) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, manufacturerZip });
            }}
          />
          <TextFieldWithEditMode
            value={commercialInvoice.manufacturerCountry || ''}
            onSaveClicked={(manufacturerCountry) => {
              onCommercialInvoiceChanged({ ...commercialInvoice, manufacturerCountry });
            }}
          />
        </div>
      </div>

      <ManageItems<CommercialInvoiceLineItem>
        manageItemsConfig={manageCommercialInvoiceLineItemsConfig}
        items={commercialInvoice.lineItems}
        getDefaultItem={() => ({
          poNumber: '',
          sku: '',
          description: '',
          materialComposition: '',
          unitQuantity: '',
          unitValue: '',
          currencyCode: '',
          totalValue: '',
          countryOfOrigin: '',
          additionalProperties: {},
          containerNumber: '',
          htsno: '',
          unitWeight: '',
          netWeight: '',
          grossWeight: '',
        })}
        itemName=""
        styles={ManageItemsStyles}
        useDeleteConfirmationModal={true}
        onItemsUpdated={(items) => {
          onCommercialInvoiceChanged({ ...commercialInvoice, lineItems: items });
        }}
        parentLoadingStatus={loadingStatus}
      />
      <div
        style={{
          fontWeight: 'bold',
          color: 'black',
          fontSize: '12px',
          padding: '6px',
          marginTop: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid lightgrey',
          borderRadius: '4px',
        }}
      >
        <div style={{ paddingRight: '32px', borderRight: '1px solid lightgrey' }}>Total</div>
        <div>{CurrencyUtility.formatCurrency(Number(commercialInvoice.totalValue), 'USD')}</div>
      </div>

      <AddOrEditAProductModal
        open={addOrEditAProductOpen}
        productID={productIDForViewingInModal}
        productFieldsConfig={productFieldsConfig}
        onProductCreatedOrModified={onProductCreatedModifiedOrDeleted}
        onClose={() => setAddOrEditAProductOpen(false)}
        draftStateProductProperties={computedProductPropertiesFromCommercialInvoiceLine}
        businessId={businessId}
      />
    </>
  );
};

export default CommercialInvoiceParsedView;
