// src/shared-components/ImportalMoreOptionsButton/ImportalMoreOptionsButton.tsx

import React from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface ImportalMoreOptionsButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties; // Optional style prop to override default styles
  menuOptions?: { label: string; onClick: () => void }[]; // Optional menu options
}

const ImportalMoreOptionsButton: React.FC<ImportalMoreOptionsButtonProps> = ({
  onClick,
  disabled = false,
  size = 'medium',
  style = {},
  menuOptions = [],
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (menuOptions.length > 0) {
      setAnchorEl(event.currentTarget);
    }
    if (onClick) {
      onClick(event);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more options"
        aria-controls={open ? 'importal-more-options-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
        size={size}
        style={{
          color: disabled ? '#a9a9a9' : '#525256',
          width: '40px',
          height: '40px',
          ...style,
        }}
        className="importal-more-options-button"
      >
        <MoreVertIcon style={{ fontSize: '28px' }} />
      </IconButton>
      {menuOptions.length > 0 && (
        <Menu
          id="importal-more-options-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {menuOptions.map((option, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                option.onClick();
                handleClose();
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default ImportalMoreOptionsButton;
