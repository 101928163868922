import React from 'react';
import { IconButton } from '@mui/material';
import LinksTable from './LinksTable';
import { Add, Check, Close } from '@mui/icons-material';
import './LinksSection.css';

const LinksSection = ({ title, links, renderRow, onAddClick, columnNames }) => (
  <div className="links-section">
    <div className="section-header">
      <div className="links-title">{title}</div>
      <IconButton
        style={{
          color: 'grey',
        }}
        onClick={onAddClick}
      >
        <Add />
      </IconButton>
    </div>
    {links && <LinksTable links={links} renderRow={renderRow} columnNames={columnNames} />}
  </div>
);

export default LinksSection;
