import React from 'react';
import { Paper, TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

export interface ImportalSearchProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: React.CSSProperties;
}

const ImportalSearch: React.FC<ImportalSearchProps> = ({ value, onChange, placeholder = 'Search...', style = {} }) => {
  return (
    <Paper
      sx={{
        minWidth: '300px',
        borderRadius: '8px!important',
        height: '40px',
        paddingLeft: '10px',
        boxShadow: `
          0px 4px 8px 0px rgba(76, 103, 100, 0.02),
          0px -4px 8px 0px rgba(76, 103, 100, 0.02),
          4px 0px 8px 0px rgba(76, 103, 100, 0.02),
          -4px 0px 8px 0px rgba(76, 103, 100, 0.02) !important
        `,
        ...style, // merge any additional inline style
      }}
    >
      <TextField
        variant="outlined"
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: '4px !important',
            background: 'white !important',
            fontSize: '12px !important',
            height: '40px',
          },
          '& .MuiInputBase-input': {
            fontSize: '12px !important',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search sx={{ fontSize: 20 }} />
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  );
};

export default ImportalSearch;
