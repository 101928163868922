import * as React from 'react';
import { useState } from 'react';
import { Button, Dialog, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import HighlightOff from '@mui/icons-material/HighlightOff';
import './CreateShipmentModal.css';
import { useAPI } from '@/api/APIContext';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';

type EntryAddMode = 'new' | 'entryNumber' | 'externalRef';

export interface AddEntryDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirmCreateNewEntry: (internalWorkingName?: string) => void;
  onAddEntryFromReferenceNumber: (entryNumber: string, internalWorkingName?: string) => void;
}

export function AddEntryDialog({
  open,
  onClose,
  onConfirmCreateNewEntry,
  onAddEntryFromReferenceNumber,
}: AddEntryDialogProps) {
  const api = useAPI();

  // Radio selection: which method the user wants to use.
  const [selectedMode, setSelectedMode] = useState<EntryAddMode>('new');

  // Fields and states for "entryNumber" query
  const [entryNumber, setEntryNumber] = useState('');
  const [internalWorkingName, setInternalWorkingName] = useState<string | undefined>();
  const [entryNumberFound, setEntryNumberFound] = useState<boolean | null>(null); // null means not queried yet

  // Fields and states for "externalReferenceNumber" query
  const [externalReferenceNumber, setExternalReferenceNumber] = useState('');
  const [externalRefFound, setExternalRefFound] = useState<boolean | null>(null);

  // If user changes radio selection, reset old query states
  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const mode = event.target.value as EntryAddMode;
    setSelectedMode(mode);
    // Optionally reset found states if user switches modes
    setEntryNumberFound(null);
    setExternalRefFound(null);
  };

  // Called when user clicks the "X" button in the top-right corner
  const handleClose = (event: {}, reason: string) => {
    // If you need to filter out "backdropClick" or "escapeKeyDown", do so
    onClose();
  };

  const handleQueryEntryNumber = async () => {
    try {
      // Example calling your query with "entryNumber" only
      const result = await api.queryEntryStatusFromNETCHB(entryNumber, '');
      if (result) {
        // Adjust logic as needed. If result indicates it's found, set true, else false
        setEntryNumberFound(true);
      } else {
        setEntryNumberFound(false);
      }
    } catch (error) {
      console.error(`error querying entry status for entryNumber: [${entryNumber}]`, error);
      setEntryNumberFound(false);
    }
  };

  const handleQueryExternalReference = async () => {
    try {
      // Example calling your query with "externalReferenceNumber" only
      const result = await api.queryEntryStatusFromNETCHB('', externalReferenceNumber);
      if (result) {
        setExternalRefFound(true);
      } else {
        setExternalRefFound(false);
      }
    } catch (error) {
      console.error(error);
      setExternalRefFound(false);
    }
  };

  // Decide how the final "confirm" button works, based on selected mode
  const handleConfirm = () => {
    switch (selectedMode) {
      case 'new':
        onConfirmCreateNewEntry(internalWorkingName);
        break;
      case 'entryNumber':
        onAddEntryFromReferenceNumber(entryNumber, internalWorkingName);
        break;
      case 'externalRef':
        onAddEntryFromReferenceNumber(externalReferenceNumber, internalWorkingName);
        break;
      default:
        break;
    }
  };

  const confirmEnabled = (): boolean => {
    switch (selectedMode) {
      case 'new':
        return true;
      case 'entryNumber':
        return true;
      case 'externalRef':
        return !!externalRefFound;
      default:
        return false;
    }
  };

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
      <div className="header-question">
        <Typography>Add Entry</Typography>
      </div>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>

      <div
        style={{
          padding: '1rem 1.5rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <TextField
            size="small"
            label="Entry Name (Optional)"
            value={internalWorkingName || ''}
            onChange={(e) => setInternalWorkingName(e.target.value)}
          />
        </div>
        <Typography variant="subtitle1">Choose how you would like to add this entry:</Typography>

        <RadioGroup
          value={selectedMode}
          onChange={handleModeChange}
          sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
        >
          {/* --- Option A: Create a new blank entry --- */}
          <FormControlLabel value="new" control={<Radio />} label="Create a new entry" />

          {/* --- Option B: Query by Entry Number --- */}
          <FormControlLabel value="entryNumber" control={<Radio />} label="Lookup by Entry Number" />
          {/* Show the text field + button only if user selected "entryNumber" */}
          {selectedMode === 'entryNumber' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                marginLeft: '2rem',
              }}
            >
              <TextField
                size="small"
                label="Entry Number"
                value={entryNumber}
                onChange={(e) => setEntryNumber(e.target.value)}
              />
              <Button variant="outlined" onClick={handleQueryEntryNumber}>
                Query
              </Button>
              {entryNumberFound === true && <CheckCircleOutline color="success" />}
              {entryNumberFound === false && <HighlightOff color="error" />}
            </div>
          )}

          {/* --- Option C: Query by External Reference Number --- */}
          <FormControlLabel value="externalRef" control={<Radio />} label="Lookup by External Reference Number" />
          {selectedMode === 'externalRef' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                marginLeft: '2rem',
              }}
            >
              <TextField
                size="small"
                label="External Reference Number"
                value={externalReferenceNumber}
                onChange={(e) => setExternalReferenceNumber(e.target.value)}
              />
              <Button variant="outlined" onClick={handleQueryExternalReference}>
                Query
              </Button>
              {externalRefFound === true && <CheckCircleOutline color="success" />}
              {externalRefFound === false && <HighlightOff color="error" />}
            </div>
          )}
        </RadioGroup>
      </div>

      {/* --- Bottom Action Buttons --- */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '1rem',
          padding: '1rem',
        }}
      >
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <ImportalPrimaryButton disabled={!confirmEnabled()} onClick={handleConfirm} text={'Confirm'} />
      </div>
    </Dialog>
  );
}
