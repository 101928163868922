// ImportalFilter.tsx
import { Autocomplete, Paper, TextField } from '@mui/material';
import { ColumnConfig, ImportalTableDataType, TableConfig } from 'common/interfaces/importalTable';
import moment from 'moment';
import React from 'react';
import ImportalDateRangePicker from '../ImportalDateRangePicker';

interface ImportalFilterProps {
  selectedFilteringColumn: ColumnConfig | null;
  selectedFilteringValues: Array<any>;
  setSelectedFilteringColumn: (col: ColumnConfig | null) => void;
  setSelectedFilteringValues: (values: Array<any>) => void;
  tableConfig: TableConfig;
  setTableConfig: (tableConfig: TableConfig) => void;
  sortedAndFilteredData: any[];
  handleAddFilterChip: (columnName: string, displayName: string, values: Array<any>) => void;
}

const ImportalFilter: React.FC<ImportalFilterProps> = ({
  selectedFilteringColumn,
  selectedFilteringValues,
  setSelectedFilteringColumn,
  setSelectedFilteringValues,
  tableConfig,
  setTableConfig,
  sortedAndFilteredData,
  handleAddFilterChip,
}) => {
  // Generates an array of date strings between startDate and endDate
  const generateDateRange = (startDate: Date, endDate: Date): string[] => {
    const dates: string[] = [];
    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate, 'day')) {
      dates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }

    return dates;
  };

  // Checks if the selected column is of type 'date'
  const isDateColumn = (column: ColumnConfig) => column.dataType === ImportalTableDataType.DATE_STRING;

  // Retrieves unique values for the selected column excluding already filtered values
  const getUniqueValuesForColumn = (column: ColumnConfig): string[] => {
    const columnValues = sortedAndFilteredData.map((row) => {
      const rowData = tableConfig.subKeyForData ? row[tableConfig.subKeyForData] : row;
      return String(rowData[column.name] || '');
    });

    return Array.from(new Set(columnValues)).filter((value) => {
      return value.trim() !== '' && !tableConfig.filterConfig[column.name]?.allowedValues?.includes(value);
    });
  };

  // Handles key events for the Autocomplete component
  const handleFilterValueAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      (event.key === 'Tab' || event.code === 'Enter') &&
      selectedFilteringColumn &&
      selectedFilteringValues.length > 0
    ) {
      const newFilterConfig = {
        ...tableConfig.filterConfig,
        [selectedFilteringColumn.name]: {
          allowedValues: selectedFilteringValues,
          columnName: selectedFilteringColumn.name,
          displayName: selectedFilteringColumn.displayName,
        },
      };
      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });

      handleAddFilterChip(
        selectedFilteringColumn.name,
        selectedFilteringColumn.displayName as string,
        selectedFilteringValues
      );

      setSelectedFilteringColumn(null);
      setSelectedFilteringValues([]);
    }
  };

  // Handles changes in the Autocomplete component
  const handleValueChange = (event: React.SyntheticEvent, values: Array<any>) => {
    if (!event) return;
    const isTabKey = (event as React.KeyboardEvent).key === 'Tab';
    setSelectedFilteringValues(values);

    if (isTabKey && selectedFilteringColumn && values.length > 0) {
      const newFilterConfig = {
        ...tableConfig.filterConfig,
        [selectedFilteringColumn.name]: {
          allowedValues: values,
          columnName: selectedFilteringColumn.name,
          displayName: selectedFilteringColumn.displayName,
        },
      };
      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });

      handleAddFilterChip(selectedFilteringColumn.name, selectedFilteringColumn.displayName as string, values);
    }
  };

  // Handles the selection of a date range
  const handleTimePeriodSelected = ({
    periodStartDate,
    periodEndDate,
  }: {
    periodStartDate: Date | null;
    periodEndDate: Date | null;
  }) => {
    if (periodStartDate && periodEndDate && selectedFilteringColumn) {
      // Set selected filtering values to be the raw date objects
      setSelectedFilteringValues([periodStartDate, periodEndDate]);

      // Generate date-only strings for the allowedValues array in New York timezone
      const dateRangeValues = generateDateRange(periodStartDate, periodEndDate).map((dateStr) =>
        moment.tz(dateStr, 'America/New_York').format('YYYY-MM-DD')
      );

      const newFilterConfig = {
        ...tableConfig.filterConfig,
        [selectedFilteringColumn.name]: {
          allowedValues: dateRangeValues, // Store date-only values in New York timezone format
          columnName: selectedFilteringColumn.name,
          displayName: selectedFilteringColumn.displayName,
        },
      };

      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
      setSelectedFilteringColumn(null);
      setSelectedFilteringValues([]);
    }
  };

  // Handles the completion of date range selection
  const handleCompleteSelection = () => {
    if (!selectedFilteringColumn) return;
    const startDate = moment(selectedFilteringValues[0]).format('MM/DD/YY');
    const endDate = moment(selectedFilteringValues[1]).format('MM/DD/YY');
    const chipLabel = `${selectedFilteringColumn.displayName || ''}: ${startDate} - ${endDate}`;

    handleAddFilterChip(
      selectedFilteringColumn.name,
      chipLabel, // Display formatted date range as chip label
      selectedFilteringValues
    );
    setSelectedFilteringColumn(null);
    setSelectedFilteringValues([]);
  };

  // If no column is selected for filtering, render nothing
  if (!selectedFilteringColumn) return null;

  // If the selected column is a date type, render the date range picker
  if (isDateColumn(selectedFilteringColumn)) {
    return (
      <ImportalDateRangePicker
        timePeriod={{
          periodStartDate: selectedFilteringValues[0] || null,
          periodEndDate: selectedFilteringValues[1] || null,
        }}
        onTimePeriodSelected={handleTimePeriodSelected}
        onCompleteSelection={handleCompleteSelection}
      />
    );
  }

  // For non-date columns, render the Autocomplete for selecting filter values
  return (
    <Paper
      sx={{
        borderRadius: '8px!important',
        minWidth: '250px',
        height: '40px',
        boxShadow: `
        0px 4px 8px 0px rgba(76, 103, 100, 0.02),
        0px -4px 8px 0px rgba(76, 103, 100, 0.02),
        4px 0px 8px 0px rgba(76, 103, 100, 0.02),
        -4px 0px 8px 0px rgba(76, 103, 100, 0.02) !important
      `,
      }}
    >
      <Autocomplete
        options={getUniqueValuesForColumn(selectedFilteringColumn)}
        multiple={true}
        isOptionEqualToValue={(option, value) => option === value}
        value={selectedFilteringValues} // Reflects the current selected values
        onChange={handleValueChange}
        disableCloseOnSelect={true}
        onKeyDown={handleFilterValueAutocompleteKeyDown}
        componentsProps={{
          paper: {
            sx: {
              borderRadius: '8px !important',
              fontSize: '12px',
              boxShadow: `
          0px 4px 8px 0px rgba(76, 103, 100, 0.02),
          0px -4px 8px 0px rgba(76, 103, 100, 0.02),
          4px 0px 8px 0px rgba(76, 103, 100, 0.02),
          -4px 0px 8px 0px rgba(76, 103, 100, 0.02) !important
        `,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={`Select a value for ${selectedFilteringColumn.displayName || selectedFilteringColumn?.name}`}
            fullWidth={true}
            sx={{
              backgroundColor: 'transparent',
              '& .MuiOutlinedInput-root': {
                padding: '4px 4px 4px 8px !important',
                '& fieldset': {
                  border: 'none',
                  borderRadius: '8px',
                },
                fontSize: '12px',
              },
              '& .MuiInputBase-input': {
                fontSize: '12px',
              },
            }}
          />
        )}
      />
    </Paper>
  );
};

export default ImportalFilter;
