import { Entry } from 'common/interfaces/entry';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import { useAPI } from '@/api/APIContext';
import React, { useState } from 'react';
import { decodeHtmlEntities } from '@/utils/htmlUtilities';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { Box, CircularProgress, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';

interface Props {
  open: boolean;
  onClose: () => void;
  entry: Entry;
  onEntryAccepted: (arg0: Entry) => void;
  onEntryRejected: () => void;
}

export default function EntryPrepSendToNETCHB({ open, onClose, entry, onEntryAccepted }: Props) {
  const api = useAPI();

  const [result, setResult] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const onEntryAcceptedByNETCHB = () => {
    api
      .getEntryById(entry._id!.toString())
      .then(({ data }) => {
        onEntryAccepted(data);
      })
      .catch((err) => {
        console.error('error getting entry after sending to NETCHB', err);
      });
  };

  const sendEntryToNetchb = () => {
    api
      .sendEntryToNETCHB(entry._id!.toString())
      .then(({ data }) => {
        setResult(data.rawResponse);
        if (data.outcome === 'accepted') {
          onEntryAcceptedByNETCHB();
        }
      })
      .catch((err) => {
        console.error('error sending entry to netchb', err);
      });
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
    >
      <DialogContent
        style={{
          minWidth: 'min( 1000px, calc(100vw - 400px))',
          maxWidth: 'calc(100vw - 200px)',
          minHeight: 'min(600px, calc(100vh - 400px))',
          maxHeight: 'calc(100vh - 200px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
          onClick={onClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        <div style={{ textAlign: 'center' }}>
          {loading && (
            <Box
              id={'send-to-netchb-loading-indicator-container'}
              style={{ height: '325px' }}
              display="flex"
              justifyContent="center"
              alignItems="center" // Center vertically
            >
              <CircularProgress id="send-to-netchb-loading-indicator" color="primary" />
            </Box>
          )}
        </div>

        <>{decodeHtmlEntities(result)}</>
        <ImportalPrimaryButton onClick={sendEntryToNetchb} text={'Send Entry to NETCHB'} />
      </DialogContent>

      <DialogActions sx={{ marginTop: '16px', justifyContent: 'flex-end' }}>
        <ImportalPrimaryButton onClick={onClose} text="Done" style={{ width: '140px' }} />
      </DialogActions>
    </Dialog>
  );
}
