import React, { useEffect, useState } from 'react';
import { useAPI } from '@/api/APIContext';
import Dialog from '@mui/material/Dialog';
import { TextField, Tooltip, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ProductField, ProductFieldsConfig } from 'common/interfaces/productfields';
import Checkbox from '@mui/material/Checkbox';
import ManageItems, { ManageItemsConfig } from '@/shared-components/ManageItems/ManageItems';
import './EditProductFieldsModal.css';
import ImportalPrimaryButton from '@/shared-components/ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalSecondaryButton from '@/shared-components/ImportalSecondaryButton/ImportalSecondaryButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LoadingStatus } from '@/components/data-import-loader/DataImportLoader';

interface Props {
  open: boolean;
  onClose: () => void;
  onFieldsEdited: () => void;
  refreshProducts: () => void;
  businessId?: string;
}

export default function EditProductFieldsModal({ open, onClose, onFieldsEdited, refreshProducts, businessId }: Props) {
  const api = useAPI();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [productFieldsAreValid, setProductFieldsAreValid] = useState(false);

  const [productFields, setProductFields] = useState<ProductField[]>([]);

  const [draggedTabIndex, setDraggedTabIndex] = useState<number | null>(null);

  const validateProductFields = () => {
    const primaryIdentifierFields = productFields.filter((field) => field.isPrimaryIdentifier);
    const duplicateNames = productFields
      .map((field) => field.name)
      .filter((name, index, arr) => arr.indexOf(name) !== index);
    const emptyNames = productFields.map((field) => field.name).filter((name) => name === '');

    if (primaryIdentifierFields.length === 1 && duplicateNames.length === 0 && emptyNames.length === 0) {
      setProductFieldsAreValid(true);
    } else {
      setProductFieldsAreValid(false);
    }
  };

  useEffect(() => {
    validateProductFields();
  }, [productFields]);

  useEffect(() => {
    setLoading(true);
    api
      .getProductFields(businessId)
      .then(({ data: productFieldsConfig }) => {
        setProductFields(productFieldsConfig.fields);
      })
      .catch((err) => {
        console.error('error getting product fields for edit modal');
        console.error(err);
      });
  }, []);

  const handleItemsUpdated = (updatedProductFields: ProductField[]) => {
    setProductFields(updatedProductFields);
    setIsEdited(true);
  };

  const onSubmit = () => {
    const updatedProductFields = {
      fields: productFields,
    } as ProductFieldsConfig;

    api
      .updateProductFields(updatedProductFields, businessId)
      .then(() => {
        setLoading(false);
        setIsEdited(false);
        onFieldsEdited();
        onClose();
        refreshProducts();
      })
      .catch((err) => {
        console.error('error updating product fields');
        console.error(err);
        setLoading(false);
      });
  };

  const onClickDeleteProductField = (index: number) => {
    const updatedProductFields = [...productFields];
    updatedProductFields.splice(index, 1);
    setProductFields(updatedProductFields);
    setIsEdited(true);
    validateProductFields();
  };

  const productFieldsConfig: ManageItemsConfig = {
    viewModeShowDeleteIcon: (item: ProductField) => !item.fieldIsRequiredByImportal,
    columns: [
      {
        header: 'Name',
        viewComponent: (item: ProductField) => (
          <>
            {item.fieldIsRequiredByImportal && item.isOutputColumn && (
              <Tooltip title="This field is calculated by Importal." followCursor={true}>
                <Typography>{item.name}</Typography>
              </Tooltip>
            )}
            {item.fieldIsRequiredByImportal && !item.isOutputColumn && !item.isPrimaryIdentifier && (
              <Tooltip title="Importal uses this field to calculate your duties." followCursor={true}>
                <Typography>{item.name}</Typography>
              </Tooltip>
            )}
            {item.isPrimaryIdentifier && (
              <Tooltip title="This field uniquely identifies a product in the library." followCursor={true}>
                <Typography>{item.name}</Typography>
              </Tooltip>
            )}
            {!item.fieldIsRequiredByImportal && !item.isPrimaryIdentifier && <Typography>{item.name}</Typography>}
          </>
        ),
        editComponent: (item: ProductField, setItem: (updatedItem: ProductField) => void) => (
          <>
            {item.fieldIsRequiredByImportal && item.isOutputColumn && (
              <Tooltip title="This field is calculated by Importal." followCursor={true}>
                <Typography>{item.name}</Typography>
              </Tooltip>
            )}
            {item.fieldIsRequiredByImportal && !item.isOutputColumn && !item.isPrimaryIdentifier && (
              <Tooltip title="Importal uses this field to calculate your duties." followCursor={true}>
                <Typography>{item.name}</Typography>
              </Tooltip>
            )}
            {item.isPrimaryIdentifier && (
              <TextField
                value={item.name}
                size="small"
                onChange={(e) => {
                  setItem({
                    ...item,
                    name: e.target.value,
                  });
                }}
                disabled={item.fieldIsRequiredByImportal && !item.isPrimaryIdentifier}
                InputProps={{
                  classes: {
                    input: 'input-primary-identifier',
                  },
                }}
              />
            )}
            {!item.fieldIsRequiredByImportal && !item.isPrimaryIdentifier && (
              <TextField
                value={item.name}
                size="small"
                onChange={(e) => {
                  setItem({
                    ...item,
                    name: e.target.value,
                  });
                }}
                disabled={item.fieldIsRequiredByImportal && !item.isPrimaryIdentifier}
                InputProps={{
                  classes: {
                    input: 'input-primary-identifier',
                  },
                }}
              />
            )}
          </>
        ),
        addComponent: (item: ProductField, setItem: (updatedItem: ProductField) => void) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              value={item.name}
              size="small"
              onChange={(e) => {
                setItem({
                  ...item,
                  name: e.target.value,
                });
              }}
              disabled={item.fieldIsRequiredByImportal && !item.isPrimaryIdentifier}
              InputProps={{
                classes: {
                  input: 'input-primary-identifier',
                },
              }}
            />
          </div>
        ),
      },
      {
        header: 'Visible',
        viewComponent: (item: ProductField, setItem?: (updatedItem: ProductField) => void) => (
          <Checkbox
            checked={item.showInUI}
            onChange={(e) => {
              setItem?.({ ...item, showInUI: e.target.checked });
            }}
            sx={{ padding: '0px' }}
            icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: 20 }} />}
            checkedIcon={<CheckBoxIcon sx={{ fontSize: 20 }} />}
            disabled={true}
          />
        ),
        editComponent: (item: ProductField, setItem?: (updatedItem: ProductField) => void) => (
          <Checkbox
            checked={item.showInUI}
            onChange={(e) => {
              setItem?.({ ...item, showInUI: e.target.checked });
            }}
            sx={{ padding: '0px' }}
            icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: 20 }} />}
            checkedIcon={<CheckBoxIcon sx={{ fontSize: 20 }} />}
          />
        ),
      },
    ],
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
      fullWidth={true}
    >
      <IconButton
        aria-label="close"
        style={{ position: 'absolute', right: 8, top: 8, color: 'grey' }}
        onClick={onClose}
      >
        <CloseIcon sx={{ fontSize: '24px' }} />
      </IconButton>
      <div
        style={{
          color: '#525256',
          lineHeight: '28px',
          fontWeight: '700',
          fontSize: '24px',
        }}
      >
        Edit Product Fields
      </div>
      <DialogContent>
        <div style={{ paddingLeft: '4%', paddingRight: '4%', overflow: 'visible', maxHeight: 'unset' }}>
          <ManageItems<ProductField>
            manageItemsConfig={productFieldsConfig}
            items={productFields}
            onItemsUpdated={handleItemsUpdated}
            onItemDeleted={(item, index) => onClickDeleteProductField(index)}
            itemName=""
            getDefaultItem={() => ({
              name: '',
              showInUI: true,
              fieldIsRequiredByImportal: false,
              dataType: 'string',
            })}
            dragToReorder={true}
            parentLoadingStatus={LoadingStatus.NOT_LOADING}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ marginTop: '32px' }}>
        <ImportalSecondaryButton onClick={onClose} style={{ color: `${theme.palette.primary.main}` }} text="Cancel" />
        <ImportalPrimaryButton disabled={!productFieldsAreValid || !isEdited} onClick={onSubmit} text="Save" />
      </DialogActions>
    </Dialog>
  );
}
