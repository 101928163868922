import { Autocomplete, Box, TextField } from '@mui/material';
import { countryNameToISOCode } from 'common/utilities/countryIsoCodes';
import { useState } from 'react';

function CountryDropdown({ countryError, handleAddressChange }): React.JSX.Element {
  const countries = Object.keys(countryNameToISOCode).map((name) => ({
    name,
    code: countryNameToISOCode[name],
  }));

  const [value, setValue] = useState<Record<string, string> | null>(null);
  const [inputValue, setInputValue] = useState('');

  return (
    <div className="country-drop-down">
      <Autocomplete
        sx={{ width: 300 }}
        options={countries}
        size="small"
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
          handleAddressChange({
            target: { name: 'country', value: newValue?.code || '' },
          });
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.name} ({option.code})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            error={Boolean(countryError)}
            helperText={countryError}
          />
        )}
      />
    </div>
  );
}

export default CountryDropdown;
