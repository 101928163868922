import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImportalLinearProgress from '../ImportalLinearProgress/ImportalLinearProgress';

interface ImportalFileUploadedCardProps {
  uploadFile?: File;
  onRemove?: () => void;
  progressValue?: number;
  timeLeft?: string;
}

const ImportalFileUploadedCard: React.FC<ImportalFileUploadedCardProps> = ({
  uploadFile,
  onRemove,
  progressValue = 50,
  timeLeft = '2 seconds left',
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: '#F3F3F3',
        border: '1px solid #E8E8E8',
        borderRadius: '4px',
        padding: '16px',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            gap: '11px',
            marginBottom: '16px',
          }}
        >
          <div className="file-logo-container">
            <img src="/file_icon_excel.png" alt="File Icon" />
          </div>
          <div style={{ flex: 1 }}>
            <div className="file-info-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#525256',
                }}
              >
                {uploadFile ? uploadFile.name : 'No Name'}
              </div>
              <div>
                <IconButton
                  aria-label="close"
                  sx={{
                    color: '#525256',
                    marginRight: '-8px',
                    marginTop: '-8px',
                    marginBottom: '-2px',
                  }}
                  onClick={onRemove}
                >
                  <CloseIcon sx={{ fontSize: '24px' }} />
                </IconButton>
              </div>
            </div>
            <div
              style={{
                color: '#979797',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {uploadFile ? `${(uploadFile.size / 1024).toFixed(2)} KB` : '0 KB'} · {timeLeft}
              </div>
              <div>{progressValue}%</div>
            </div>
          </div>
        </div>
        <div>
          <ImportalLinearProgress trackColor="#FFFFFF" barColor={theme.palette.primary.main} value={progressValue} />
        </div>
      </div>
    </Box>
  );
};

export default ImportalFileUploadedCard;
