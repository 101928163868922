// src/shared-components/ImportalTable/ImportalTable.tsx

import { Settings } from '@mui/icons-material';
import {
  Badge,
  BadgeProps,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  ColumnConfig,
  ColumnSortConfig,
  ImportalTableDataType,
  SortDirection,
  TableConfig,
} from 'common/interfaces/importalTable';
import {
  createTableFilterFunction,
  createTableSortFunction,
  FilterFunction,
  noFilter,
  noSort,
  SortFunction,
} from 'common/utilities/importalTable';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import HoverState from '../HoverState';
import ImportalAutocomplete from '../ImportalAutocomplete/ImportalAutocomplete';
import ImportalButtonGroup from '../ImportalButtonGroup/ImportalButtonGroup';
import ImportalFilter from '../ImportalFilter/ImportalFilter';
import ImportalPrimaryButton from '../ImportalPrimaryButton/ImportalPrimaryButton';
import ImportalSearch from '../ImportalSearch/ImportalSearch';
import ImportalTableSettingsModal from '../ImportalTableSettingsModal/ImportalTableSettingsModal';
import './ImportalTable.css';

const SortOrderBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    padding: '4px',
    transform: 'translate(0px, 0px) scale(0.7)',
  },
}));

interface Props<T> {
  data: Array<T>;
  rowGenerator?: (rowData: T, index: number) => React.JSX.Element;
  tableConfig: TableConfig;
  setTableConfig: (tableConfig: TableConfig) => void;
  topRightElement?: React.JSX.Element;
  embeddedStyling?: boolean;
  loading?: boolean;
  defaultRowsPerPage?: number;
  hasRowExpand?: boolean;
  topRightButton?: React.JSX.Element;
  useSearch?: boolean;
  useSettings?: boolean;
  predefinedViews?: TableConfig[];
  openModal?: () => void;
  isProductModal?: boolean;
}

export interface ImportalTableHandles {
  getTableConfig: () => TableConfig;
  setTableConfig: (tableConfig: TableConfig) => void;
}

const DefaultRowGenerator = (rowData: any, index: number, columnConfig: ColumnConfig[]) => {
  return (
    <TableRow key={`default-row-${index}`} sx={{ '& > *': { borderBottom: 'unset' } }}>
      <TableCell />
      {columnConfig
        .filter((config) => config.isVisible !== false) // Only visible columns
        .map((config) => {
          const rawValue = rowData[config.name];
          const displayValue = config.transformer?.(rawValue) ?? rawValue;
          return <TableCell key={config.name}>{displayValue}</TableCell>;
        })}
    </TableRow>
  );
};

const ImportalTable = forwardRef<ImportalTableHandles, Props<any>>(
  (
    {
      data = [],
      rowGenerator,
      tableConfig,
      setTableConfig,
      topRightElement,
      embeddedStyling = false,
      loading = false,
      defaultRowsPerPage = 20,
      hasRowExpand = true,
      topRightButton,
      useSearch = true,
      useSettings = false,
      predefinedViews = [],
      openModal,
      isProductModal = false,
    },
    ref
  ) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [filterFunction, setFilterFunction] = useState<FilterFunction>(() => noFilter);
    const [sortFunction, setSortFunction] = useState<SortFunction>(() => noSort);
    const [sortedAndFilteredData, setSortedAndFilteredData] = useState<any[]>([]);
    const [tableSettingsModalOpen, setTableSettingsModalOpen] = useState(false);
    const [selectedFilteringColumn, setSelectedFilteringColumn] = useState<ColumnConfig | null>(null);
    const [selectedFilteringValues, setSelectedFilteringValues] = useState<Array<any>>([]);
    const [viewCounts, setViewCounts] = useState<Record<string, number>>({});

    // Sorting utilities
    const isSorted = (columnName: string): boolean => {
      return tableConfig.sortConfig.some((columnSortConfig) => columnSortConfig.columnName === columnName);
    };

    const getColumnSortDirection = (columnName: string): SortDirection | undefined => {
      return tableConfig.sortConfig.find((columnSortConfig) => columnSortConfig.columnName === columnName)?.direction;
    };

    const getSortOrderForColumn = (columnName: string): number | undefined => {
      const index = tableConfig.sortConfig.findIndex((columnSortConfig) => columnSortConfig.columnName === columnName);
      return index !== -1 ? index + 1 : undefined;
    };

    const handleSettingsClick = () => {
      if (isProductModal) {
        openModal?.();
      } else {
        handleTableSettingsModalOpen();
      }
    };

    const handleTableSettingsModalOpen = () => {
      setTableSettingsModalOpen(true);
    };

    const handleTableSettingsModalClose = () => {
      setTableSettingsModalOpen(false);
    };

    useImperativeHandle(ref, () => ({
      getTableConfig() {
        return tableConfig;
      },
      setTableConfig(tableConfig: TableConfig) {
        setTableConfig(tableConfig);
      },
    }));

    useEffect(() => {
      if (data.length > 0) {
        const counts = countDataForViews(predefinedViews, data, tableConfig.subKeyForData);
        setViewCounts(counts);
      }
    }, [data, predefinedViews, tableConfig.subKeyForData]);

    useEffect(() => {
      setFilterFunction(() =>
        createTableFilterFunction(tableConfig.filterConfig, tableConfig.subKeyForData, tableConfig.columns)
      );
    }, [tableConfig.filterConfig, tableConfig.subKeyForData]);

    useEffect(() => {
      setSortFunction(() =>
        createTableSortFunction(tableConfig.columns, tableConfig.sortConfig, tableConfig.subKeyForData)
      );
    }, [tableConfig.sortConfig, tableConfig.columns, tableConfig.subKeyForData]);

    useEffect(() => {
      if (data && data.length > 0) {
        let filteredData = data.filter(filterFunction).sort(sortFunction);
        if (searchQuery) {
          filteredData = filteredData.filter((row) =>
            Object.values(tableConfig.subKeyForData ? row[tableConfig.subKeyForData] : row).some((value) =>
              String(value).toLowerCase().includes(searchQuery.toLowerCase())
            )
          );
        }

        setSortedAndFilteredData(filteredData);
      }
    }, [data, sortFunction, filterFunction, searchQuery, tableConfig.subKeyForData]);

    const countDataForViews = (
      predefinedViews: TableConfig[],
      data: any[],
      subKeyForData?: string
    ): Record<string, number> => {
      const viewCounts: Record<string, number> = {};
      predefinedViews.forEach((view) => {
        const filterFunction = createTableFilterFunction(view.filterConfig, subKeyForData, view.columns);
        const filteredData = data.filter(filterFunction);
        viewCounts[view.viewName as string] = filteredData.length;
      });
      return viewCounts;
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value);
      setPage(0); // Reset to first page when search query changes
    };

    const handleChangePage = useMemo(
      () => (event: unknown, newPage: number) => {
        setPage(newPage);
      },
      [setPage]
    );

    const handleChangeRowsPerPage = useMemo(
      () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      },
      [setRowsPerPage, setPage]
    );

    const handleSelectedFilterColumnChange = (
      event: React.SyntheticEvent<Element, Event>,
      selectedCol: ColumnConfig | string | null
    ) => {
      if (selectedCol && typeof selectedCol !== 'string') {
        setSelectedFilteringColumn(selectedCol);
      } else {
        // If cleared or a string is selected (shouldn't happen), clear filters
        setSelectedFilteringColumn(null);
        setSelectedFilteringValues([]);
      }
    };

    const handleViewClick = (view: TableConfig, e?: React.MouseEvent<HTMLElement>) => {
      setTableConfig(view);
    };

    const handleAddFilterChip = (columnName: string, displayName: string, values: Array<any>) => {
      const newFilterConfig = { ...tableConfig.filterConfig };
      newFilterConfig[columnName] = {
        allowedValues: values,
        columnName: columnName,
        displayName: displayName,
      };
      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
    };

    const handleDeleteFilterChip = (columnName: string, value: string) => {
      const newFilterConfig = { ...tableConfig.filterConfig };

      if (value === '') {
        // For date range deletion
        delete newFilterConfig[columnName];
      } else {
        const filteredValues = newFilterConfig[columnName].allowedValues.filter((val) => val !== value);

        if (filteredValues.length === 0) {
          delete newFilterConfig[columnName];
        } else {
          newFilterConfig[columnName] = {
            ...newFilterConfig[columnName],
            allowedValues: filteredValues,
          };
        }
      }

      setTableConfig({ ...tableConfig, filterConfig: newFilterConfig });
    };

    const onClickColumnSort = (columnName: string) => {
      const matchesName = (sortConfig: ColumnSortConfig) => sortConfig.columnName === columnName;
      const newTableSortConfig = [...tableConfig.sortConfig];

      const currentSort = tableConfig.sortConfig.find(matchesName);
      if (currentSort && currentSort.direction === SortDirection.asc) {
        newTableSortConfig[newTableSortConfig.findIndex(matchesName)].direction = SortDirection.desc;
      } else if (currentSort && currentSort.direction === SortDirection.desc) {
        // remove sort for this column
        newTableSortConfig.splice(newTableSortConfig.findIndex(matchesName), 1);
      } else {
        // add asc sort
        newTableSortConfig.push({
          columnName: columnName,
          displayName: tableConfig.columns.find((config: ColumnConfig) => config.name === columnName)?.displayName,
          direction: SortDirection.asc,
        });
      }

      setTableConfig({ ...tableConfig, sortConfig: newTableSortConfig });
    };

    const headerCellStyle = {
      whiteSpace: 'nowrap',
      textAlign: 'left',
      fontWeight: '600',
    };

    const renderFilterChips = () => {
      return Object.keys(tableConfig.filterConfig).map((columnName) => {
        const filterConfig = tableConfig.filterConfig[columnName];
        const defaultFilter =
          predefinedViews.find((view) => view === tableConfig)?.filterConfig[columnName]?.allowedValues || [];

        const isDateColumn =
          filterConfig.columnName &&
          tableConfig.columns.some(
            (col) => col.name === filterConfig.columnName && col.dataType === ImportalTableDataType.DATE_STRING
          );

        if (isDateColumn && filterConfig.allowedValues.length > 1) {
          // Single chip for a date range
          const startDate = moment(filterConfig.allowedValues[0]).format('MM/DD/YYYY');
          const endDate = moment(filterConfig.allowedValues[filterConfig.allowedValues.length - 1]).format(
            'MM/DD/YYYY'
          );
          return (
            <Chip
              key={`${columnName}-date-range`}
              label={`${filterConfig.displayName || filterConfig.columnName}: ${startDate} - ${endDate}`}
              onDelete={() => handleDeleteFilterChip(columnName, '')} // pass empty string to remove entire date range
              sx={{
                backgroundColor: '#77A8F6',
                color: 'white',
                borderRadius: '10px!important',
                fontWeight: '700',
                padding: '8px',
                fontSize: '12px',
                '& .MuiChip-deleteIcon': {
                  color: 'white',
                  fontSize: '18px',
                },
              }}
            />
          );
        }

        // Non-date columns or single date
        return filterConfig.allowedValues
          .filter((value) => !defaultFilter.includes(value))
          .map((value) => (
            <Chip
              key={`${columnName}-${value}`}
              label={`${filterConfig.displayName || filterConfig.columnName}: ${value}`}
              onDelete={() => handleDeleteFilterChip(columnName, value)}
              sx={{
                backgroundColor: '#77A8F6',
                color: 'white',
                borderRadius: '10px!important',
                fontWeight: '700',
                padding: '8px',
                fontSize: '12px',
                '& .MuiChip-deleteIcon': {
                  color: 'white',
                  fontSize: '18px',
                },
              }}
            />
          ));
      });
    };

    return (
      <div className="importal-table-and-search-container">
        {predefinedViews.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <ImportalButtonGroup style={{ height: '40px' }}>
                {predefinedViews.map((view, index) => (
                  <ImportalPrimaryButton
                    key={`one-${index}`}
                    style={{
                      fontSize: '12px',
                      padding: '0px 12px 0px 12px',
                      boxShadow: 'none',
                      fontWeight: tableConfig === view ? 'bold' : 'normal',
                      borderLeft: '1px solid #A9A9A9',
                    }}
                    text={`${view.viewName || ''} (${viewCounts[view.viewName as string] || 0})`}
                    onClick={(e) => handleViewClick(view, e)}
                  />
                ))}
              </ImportalButtonGroup>
            </div>
          </div>
        )}

        {/* Filter & Chips Row */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '6px', alignItems: 'end', marginTop: '8px' }}>
              {/* Column-Selector Autocomplete */}
              <ImportalAutocomplete
                options={tableConfig.columns}
                value={selectedFilteringColumn}
                onChange={handleSelectedFilterColumnChange}
                placeholder="Filter by column..."
              />

              {/* Actual Filter Control */}
              <ImportalFilter
                selectedFilteringColumn={selectedFilteringColumn}
                selectedFilteringValues={selectedFilteringValues}
                setSelectedFilteringColumn={setSelectedFilteringColumn}
                setSelectedFilteringValues={setSelectedFilteringValues}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                sortedAndFilteredData={sortedAndFilteredData}
                handleAddFilterChip={handleAddFilterChip}
              />

              {/* Display active Filter Chips */}
              <div style={{ overflowY: 'auto', alignSelf: 'center', maxHeight: '40px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'end',
                    columnGap: '4px',
                    rowGap: '2px',
                  }}
                >
                  {renderFilterChips()}
                </div>
              </div>
            </div>
          </div>

          {/* Search & Settings Section */}
          <div style={{ display: 'flex', gap: '8px', alignItems: 'end' }}>
            {useSettings && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{
                    width: '36px',
                    height: '36px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={handleSettingsClick}
                >
                  <Settings sx={{ fontSize: '20px' }} />
                </IconButton>
              </div>
            )}

            {useSearch && (
              // Reusable Search Component
              <ImportalSearch value={searchQuery} onChange={handleSearchChange} placeholder="Search..." />
            )}

            {topRightButton}
          </div>
        </div>

        {/* Main Table Section */}
        <Paper
          sx={{
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '4px',
            borderRadius: '8px',
            boxShadow: `
          0px 4px 8px 0px rgba(76, 103, 100, 0.02),
          0px -4px 8px 0px rgba(76, 103, 100, 0.02),
          4px 0px 8px 0px rgba(76, 103, 100, 0.02),
          -4px 0px 8px 0px rgba(76, 103, 100, 0.02) !important
        `,
          }}
        >
          <TableContainer className="importal-table-container">
            <div style={{ overflowX: 'scroll', maxWidth: '100%' }}>
              <Table className="importal-table">
                <TableHead className="importal-table-header">
                  <TableRow sx={{ borderRadius: '8px' }} className="importal-table-header-row">
                    {hasRowExpand && <TableCell className="importal-table-header-row-cell" id="expand-spacer" />}
                    {tableConfig.columns
                      .filter((fieldConfig) => fieldConfig.isVisible !== false)
                      .map((fieldConfig, index) => (
                        <HoverState key={`field-${index}`}>
                          {(isHovered, bindHover) => (
                            <TableCell
                              key={index}
                              sx={headerCellStyle}
                              className={`importal-table-header-row-cell ${
                                fieldConfig.sortable && 'sorting'
                              } ${fieldConfig.filterable && 'filtering'}`}
                              {...bindHover}
                            >
                              {fieldConfig.sortable ? (
                                <SortOrderBadge badgeContent={getSortOrderForColumn(fieldConfig.name)} color="primary">
                                  <TableSortLabel
                                    active={isSorted(fieldConfig.name)}
                                    direction={getColumnSortDirection(fieldConfig.name)}
                                    onClick={() => onClickColumnSort(fieldConfig.name)}
                                  >
                                    {fieldConfig.displayName || fieldConfig.name}
                                  </TableSortLabel>
                                </SortOrderBadge>
                              ) : (
                                fieldConfig.displayName || fieldConfig.name
                              )}
                            </TableCell>
                          )}
                        </HoverState>
                      ))}
                    {topRightElement && (
                      <TableCell
                        className="importal-table-header-row-cell"
                        sx={headerCellStyle}
                        style={{ textAlign: 'center' }}
                      >
                        {topRightElement}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedAndFilteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((datum, index) => (rowGenerator || DefaultRowGenerator)(datum, index, tableConfig.columns))}
                </TableBody>
              </Table>
            </div>

            <TablePagination
              className="table-pagination"
              rowsPerPageOptions={[5, 10, 20, 50]}
              count={sortedAndFilteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <ImportalTableSettingsModal
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              open={tableSettingsModalOpen}
              onClose={handleTableSettingsModalClose}
              onTableSettingsEdited={() => {}}
              tableData={sortedAndFilteredData}
              predefinedViews={predefinedViews}
            />
          </TableContainer>
        </Paper>
      </div>
    );
  }
);

export default ImportalTable;
